import * as Sentry from '@sentry/browser';

import isEnvProduction from 'lib/isEnvProduction';
import logger from 'lib/logger';

const SENTRY_DEFAULT_FINGERPRINT = '{{ default }}';

const withScopeConfig = (transactionName: string | undefined, callback: () => void) => {
  Sentry.withScope((scope) => {
    if (transactionName) {
      scope.setTransactionName(transactionName);
      scope.setFingerprint([SENTRY_DEFAULT_FINGERPRINT, transactionName]);
    }
    callback();
  });
};

// This version doesn't rethrow errors, because otherwise, in dev we can get stuck in a loop
export const onUnhandledError = (error: Error, transactionName?: string) => {
  logger.error(error);
  if (isEnvProduction()) {
    withScopeConfig(transactionName, () => Sentry.captureException(error));
  }
};

const onError = (error: Error, transactionName?: string) => {
  logger.error(error);

  if (isEnvProduction()) {
    withScopeConfig(transactionName, () => Sentry.captureException(error));
  } else {
    throw error;
  }
};

export const onWarning = (message: string, transactionName?: string) => {
  if (isEnvProduction()) {
    withScopeConfig(transactionName, () => Sentry.captureMessage(message, Sentry.Severity.Warning));
  } else {
    logger.warn(message);
  }
};

export default onError;
