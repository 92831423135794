import type { DetailedAPIError } from 'common/errors';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import EmailOTPLogin from 'components/routes/login/EmailOTPLogin';
import PasswordNeedsReset from 'components/routes/login/PasswordNeedsReset';
import RecoveryCodeLogin from 'components/routes/login/RecoveryCodeLogin';
import TotpLogin from 'components/routes/login/TotpLogin';
import UserNamePassword from 'components/routes/login/UsernamePassword';

import { useDispatch } from 'lib/hooks';
import useModal from 'lib/hooks/useModal';
import { useParamState } from 'lib/hooks/useParamState';
import { requestLogin } from 'state/user/thunks';

export type LoginParams = {
  username: string;
  password: string;
  trusted_device: boolean;
  totp?: string;
  captcha_token?: string;
  recovery_code?: string;
  email_otp?: string;
};

type Params = {
  mfaStage?: string;
  captcha?: boolean;
};

const Login = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // We keep this state here since it's a little sensitive, and we don't want to store it in redux.
  // We need to keep the password after the initial submission so we can resubmit it with the code
  const [loginInfo, setLoginInfo] = useState<LoginParams | undefined>();
  const [params, setParams] = useParamState<Params>(
    R.merge({
      mfaStage: undefined,
    }),
  );
  const [overrideErrorMessage, setOverrideErrorMessage] = useState<string | undefined>();

  const [ResetPasswordModal, { open: openResetPasswordModal }] = useModal();

  const submitLogin = async (values: LoginParams) => {
    let submitValues = values;

    if (params.captcha) {
      let token = '';
      if (!executeRecaptcha) {
        return;
      }

      token = await executeRecaptcha();
      submitValues = { ...values, captcha_token: token };
    }

    const { username, password, trusted_device } = values;

    try {
      // Clear any previous error message
      setOverrideErrorMessage(undefined);

      const result = await dispatch(requestLogin(submitValues));

      if (result === 'MFA_REQUIRED') {
        setLoginInfo({ username, password, trusted_device });
        setParams({ mfaStage: 'totp' });
      }

      if (result === 'PASSWORD_NEEDS_RESET') {
        setLoginInfo({ username, password, trusted_device });
        openResetPasswordModal();
      }

      if (result === 'EMAIL_OTP_REQUIRED') {
        setLoginInfo({ username, password, trusted_device });
        setParams({ mfaStage: 'email_otp' });
      }
    } catch (error) {
      const detailedError = R.hasPath(['data', 'detail'], error ?? {})
        ? (error as DetailedAPIError)
        : undefined;

      if (detailedError) {
        const { error_code: errorCode, detail } = detailedError.data;

        // Handle CAPTCHA-related error
        if (errorCode === 'CAPTCHA_REQUIRED') {
          setLoginInfo({ username, password, trusted_device });

          // Executes the reCAPTCHA and sends the token to the backend
          if (!params.captcha) {
            setParams({ captcha: true });
          }
        }

        // If message contains a non-empty detail, display a banner
        if (RA.isNonEmptyString(detail)) {
          setOverrideErrorMessage(detail);
        }
      }
    }
  };

  if (params.mfaStage === 'totp' && loginInfo) {
    return (
      <TotpLogin
        goToRecoveryCodeScreen={() => setParams({ mfaStage: 'recovery_code' })}
        onSubmit={({ totp }) => submitLogin({ totp, ...loginInfo })}
      />
    );
  } else if (params.mfaStage === 'recovery_code' && loginInfo) {
    return (
      <RecoveryCodeLogin
        email={loginInfo.username}
        onSubmit={({ recovery_code }) => submitLogin({ recovery_code, ...loginInfo })}
      />
    );
  } else if (params.mfaStage === 'email_otp' && loginInfo) {
    return <EmailOTPLogin onSubmit={(values) => submitLogin({ ...values, ...loginInfo })} />;
  } else {
    return (
      <>
        <ResetPasswordModal>
          <PasswordNeedsReset username={loginInfo?.username} />
        </ResetPasswordModal>
        <UserNamePassword onSubmit={submitLogin} overrideErrorMessage={overrideErrorMessage} />
      </>
    );
  }
};

export default Login;
