import { formatCurrencyNoCents } from 'common/utils/Currency';

export const getAccountsDashboardWidgetTitle = ({
  currentNetWorth,
  isLoading,
}: {
  currentNetWorth: number;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return '';
  } else {
    return `${formatCurrencyNoCents(currentNetWorth)} net worth`;
  }
};

export const getInvestmentsDashboardWidgetTitle = ({
  currentAmount,
  isLoading,
}: {
  currentAmount: number;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return '';
  } else {
    return `${formatCurrencyNoCents(currentAmount)} investments`;
  }
};
