import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { getShortcutById } from 'common/lib/dateRange/dateRangeWithTimeframes';
import { color, spacing } from 'common/lib/theme/dynamic';

const CONTAINER_MARGIN_TOP = 40;

const ShortcutContent = styled(FlexContainer).attrs({
  column: true,
  justifyCenter: true,
  alignCenter: true,
})`
  gap: ${spacing.xxsmall};
  margin-top: ${CONTAINER_MARGIN_TOP}px;
`;

const CalendarIcon = styled(Icon).attrs({
  name: 'calendar',
  size: 24,
})`
  color: ${color.textLight};
`;

const ShortcutDescription = styled(Text).attrs({
  align: 'center',
  color: 'textLight',
  weight: 'book',
  size: 'small',
})`
  max-width: 200px;
`;

const DateRangeWithTimeframesShortcutContent = ({
  selectedShortcutId,
}: {
  selectedShortcutId: string;
}) => {
  const shortcut = getShortcutById(selectedShortcutId);

  if (!shortcut) {
    return null;
  }

  return (
    <ShortcutContent>
      <CalendarIcon />
      <ShortcutDescription>{shortcut.description}</ShortcutDescription>
    </ShortcutContent>
  );
};

export default DateRangeWithTimeframesShortcutContent;
