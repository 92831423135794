import { createSelector } from 'reselect';

import type { ClientState } from 'common/state/client/reducer';

type RootStateClient = {
  client: ClientState;
};

const getClient = ({ client }: RootStateClient) => client;

export const getDeviceUUID = createSelector(getClient, ({ deviceUUID }) => deviceUUID);
