import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import FileAccountMapping from 'components/routes/accounts//importBalanceHistory/FileAccountMapping';
import ImportBalanceHistorySuccess from 'components/routes/accounts//importBalanceHistory/ImportBalanceHistorySuccess';
import UploadBalanceHistoryFiles from 'components/routes/accounts//importBalanceHistory/UploadBalanceHistoryFiles';
import DownloadMintExporter from 'components/routes/accounts/importBalanceHistory/DownloadMintExporter';

import { IMPORT_BALANCE_HISTORY } from 'constants/copy';
import routes from 'constants/routes';

const StyledPage = styled(Page)`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.white};
`;

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xxxlarge} ${({ theme }) => theme.spacing.xlarge};
`;

/* Shared Style among all steps */
export const ImportPageTitle = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.color.text};
  font-variant-numeric: lining-nums;
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 150%;
  margin: 0 auto;
`;

export const ImportPageSubtitle = styled.p`
  max-width: 560px;
  text-align: center;
  color: ${({ theme }) => theme.color.text};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  line-height: 150%;
  margin: 0 auto;
`;

export const NextButton = styled(PrimaryButton).attrs({ size: 'large' })`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  width: 440px;
`;

enum ImportMintBalanceHistorySteps {
  Download = 'Download',
  Upload = 'Upload',
  MapAccounts = 'MapAccounts',
  Success = 'Success',
}

export type BalanceHistoryFileInfo = {
  file: File;
  totalCount: number;
  header: string[];
  rows: string[][];
};

const ImportMintBalanceHistory = () => {
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState<ImportMintBalanceHistorySteps>(
    ImportMintBalanceHistorySteps.Download,
  );

  const [totalUploadedFiles, setTotalUploadedFiles] = useState<number>(0);

  const [filesMap, setFilesMap] = useState<Map<string, BalanceHistoryFileInfo>>(new Map());

  const onClickBackButton = () => {
    if (currentStep === ImportMintBalanceHistorySteps.Download) {
      history.push(routes.accounts.addAccount());
    } else if (currentStep === ImportMintBalanceHistorySteps.Upload) {
      setCurrentStep(ImportMintBalanceHistorySteps.Download);
    } else if (currentStep === ImportMintBalanceHistorySteps.MapAccounts) {
      setCurrentStep(ImportMintBalanceHistorySteps.Upload);
    } else if (currentStep === ImportMintBalanceHistorySteps.Success) {
      history.push(routes.accounts.addAccount());
    }
  };

  const onFinish = () => {
    setCurrentStep(ImportMintBalanceHistorySteps.Success);
  };

  const progress = (() => {
    switch (currentStep) {
      case ImportMintBalanceHistorySteps.Download:
        return 0.25;
      case ImportMintBalanceHistorySteps.Upload:
        return 0.5;
      case ImportMintBalanceHistorySteps.MapAccounts:
        return 0.75;
      case ImportMintBalanceHistorySteps.Success:
        return 1;
      default:
        return 1;
    }
  })();

  return (
    <StyledPage
      name={IMPORT_BALANCE_HISTORY.MAIN_PAGE_NAME}
      header={
        <QuestionnaireHeader
          progress={progress}
          showBackButton
          onClickBackButton={onClickBackButton}
        />
      }
    >
      <Container>
        <Switch>
          <Case when={currentStep === ImportMintBalanceHistorySteps.Download}>
            <DownloadMintExporter
              onClickNext={() => setCurrentStep(ImportMintBalanceHistorySteps.Upload)}
            />
          </Case>
          <Case when={currentStep === ImportMintBalanceHistorySteps.Upload}>
            <UploadBalanceHistoryFiles
              filesMap={filesMap}
              setFilesMap={setFilesMap}
              onClickNext={() => setCurrentStep(ImportMintBalanceHistorySteps.MapAccounts)}
            />
          </Case>
          <Case when={currentStep === ImportMintBalanceHistorySteps.MapAccounts}>
            <FileAccountMapping
              filesMap={filesMap}
              onFinish={onFinish}
              setTotalUploadedFiles={setTotalUploadedFiles}
            />
          </Case>
          <Case when={currentStep === ImportMintBalanceHistorySteps.Success}>
            <ImportBalanceHistorySuccess totalAccounts={totalUploadedFiles} />
          </Case>
        </Switch>
      </Container>
    </StyledPage>
  );
};

export default ImportMintBalanceHistory;
