import React from 'react';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';

import { fontSize, spacing } from 'common/lib/theme/dynamic';
import variables from 'common/lib/theme/variables';

export const HEADER_HEIGHT = '64px';

const Root = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.default};
  padding: 0 ${({ theme }) => theme.spacing.gutter};
  padding-left: calc(${spacing.gutter} + ${spacing.xsmall});
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${fontSize.large};
  min-height: ${HEADER_HEIGHT};
  background-color: ${variables.color.background.page};

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    padding: 0 ${({ theme }) => theme.spacing.xsmall};
    padding-left: ${spacing.default};
  }
`;

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;

type Props = {
  children?: React.ReactNode;
  controls?: React.ReactNode;
  showSidebarToggle?: boolean;
  className?: string;
};

const Header = ({ controls, children, showSidebarToggle = true, className }: Props) => (
  <Root className={className}>
    <StyledFlex alignCenter>{children ?? null}</StyledFlex>
    {controls ?? null}
  </Root>
);

export default Header;
