import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import DateField from 'components/lib/form/DateField';
import TextButton from 'components/lib/ui/TextButton';

import { color, fontSize, spacing } from 'common/lib/theme/dynamic';

import type { DateRangeWithTimeframes } from 'common/types/DateRange';

const StyledTextButton = styled(TextButton)`
  font-size: ${fontSize.small};
  padding: 0;
`;

const StyledDateField = styled(DateField)`
  :not(:last-child) {
    margin-bottom: ${spacing.xsmall};
  }

  .DateInput {
    &__disabled {
      background: ${color.grayBackground};
    }

    .DateInput {
      &_input {
        padding: 6px 12px;

        :disabled {
          background-color: ${color.grayBackground};
        }

        ::placeholder {
          font-style: normal;
        }
      }
    }
  }
`;

const DateRangeWithTimeframesRangeFields = () => {
  const { values, setFieldValue } = useFormContext<DateRangeWithTimeframes>();

  return (
    <>
      <StyledDateField
        label="Start date"
        name="startDate"
        placeholder="Earliest"
        labelRight={
          <StyledTextButton
            disabled={R.isNil(values.startDate)}
            onClick={() => setFieldValue('startDate', undefined)}
          >
            Clear
          </StyledTextButton>
        }
      />

      <StyledDateField
        label="End date"
        name="endDate"
        placeholder="Latest"
        labelRight={
          <StyledTextButton
            disabled={R.isNil(values.endDate)}
            onClick={() => setFieldValue('endDate', undefined)}
          >
            Clear
          </StyledTextButton>
        }
      />
    </>
  );
};

export default DateRangeWithTimeframesRangeFields;
