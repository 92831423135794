import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import AccountSummary from 'components/accounts/transferAccountData/AccountSummary';
import Select from 'components/lib/form/Select';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import { spacing } from 'common/lib/theme/dynamic';
import variables from 'common/lib/theme/variables';
import type { AccountOption } from 'lib/accounts/transferRecommendations';
import {
  getRecommendedAccountGroups,
  isUnsupportedAccount,
} from 'lib/accounts/transferRecommendations';
import useRecommendedAccounts from 'lib/hooks/accounts/useRecommendedAccounts';
import type { AccountSelectOption } from 'lib/hooks/useAccountSelectOptions';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';

const FullsizeSelect = styled(Select)`
  && .react-select__single-value {
    position: relative;
    width: 100%;
    transform: none;
    margin: 0;
  }
  && .react-select__menu-list > div:not(.react-select__group) {
    padding: 8px 4px;
  }

  && .react-select__group {
    padding: 0 0 8px 0;
  }
`;

const OptionContainer = styled(FlexContainer)`
  padding: 9px 0;
  .react-select__menu-list & {
    padding: 0;
    padding-right: 32px;
  }
  .react-select__group & {
    padding-right: 20px;
  }
`;

const SectionTitle = styled(Text)`
  margin-right: ${spacing.xxsmall};
`;

const SectionSubtitle = styled(Text)`
  color: ${variables.color.content.secondary};
`;

type Props = {
  fromAccountId: string;
  next: (params: { fromAccountId: string; toAccountId: string }) => void;
  goBack?: () => void;
};

const AccountSelection = ({ fromAccountId: initialFromAccountId, next, goBack }: Props) => {
  const [isLoadingAccounts, accountOptions] = useAccountSelectOptions({
    shouldGroupByType: false,
  });
  const [fromAccountId, setFromAccountId] = useState<string>(initialFromAccountId);
  const [toAccountId, setToAccountId] = useState<string | null>(null);
  const { data: recommendedAccountData, isLoading: loadingRecommendedAccounts } =
    useRecommendedAccounts(fromAccountId);

  useEffect(() => {
    setToAccountId(null);
  }, [fromAccountId]);

  return (
    <TransferAccountDataStep
      progress={0}
      isLoading={isLoadingAccounts}
      onClickBack={goBack}
      overrideTitle="Select accounts"
      footer={
        <PrimaryButton
          size="large"
          disabled={!toAccountId}
          onClick={() =>
            next({
              fromAccountId,
              // Assert toAccountId is non-null since button is disabled when null
              toAccountId: toAccountId!,
            })
          }
        >
          Next up: Transactions
        </PrimaryButton>
      }
    >
      <FlexContainer column marginTop="xxlarge" gap="gutter">
        <FlexContainer column gap="xxsmall">
          <Text size="large" weight="medium">
            Accounts
          </Text>
          <Text>
            Choose the accounts you want to move data from/to. Note: You&apos;ll be able to download
            CSV backups of transactions and balances before the transfer occurs.
          </Text>
        </FlexContainer>
        <FlexContainer column gap="xsmall">
          <span>
            <SectionTitle weight="medium">Old</SectionTitle>
            <SectionSubtitle size="small">(We&apos;ll move data from this account)</SectionSubtitle>
          </span>
          <FullsizeSelect
            name="from_accounts"
            options={accountOptions}
            isCreatable={false}
            isSearchable={false}
            placeholder="Select an account"
            renderOption={(option: AccountSelectOption) => (
              <OptionContainer full>
                <AccountSummary
                  icon={option.accountData?.icon}
                  logoUrl={option.accountData?.logoUrl}
                  label={option.label}
                  balance={option.accountData?.displayBalance}
                  createdAt={option.accountData?.createdAt}
                  lastUpdatedAt={option.accountData?.displayLastUpdatedAt}
                  dataProvider={option.accountData?.dataProvider}
                  institution={option.accountData?.institution}
                  syncDisabled={option.accountData?.syncDisabled}
                />
              </OptionContainer>
            )}
            value={fromAccountId}
            onChange={(value: AccountSelectOption) => {
              setFromAccountId(value.value);
            }}
          />
        </FlexContainer>
        <FlexContainer column gap="xsmall">
          <span>
            <SectionTitle weight="medium">New</SectionTitle>
            <SectionSubtitle size="small">(We&apos;ll move data to this account)</SectionSubtitle>
          </span>
          <FullsizeSelect
            name="to_accounts"
            options={getRecommendedAccountGroups(recommendedAccountData)}
            isOptionDisabled={(option: AccountOption) => isUnsupportedAccount(option.accountData)}
            isLoading={loadingRecommendedAccounts}
            isCreatable={false}
            isSearchable={false}
            placeholder="Select an account"
            renderOption={(option: AccountOption) => {
              const accountData = isUnsupportedAccount(option.accountData)
                ? option.accountData.account
                : option.accountData;
              return (
                <OptionContainer full>
                  <AccountSummary
                    icon={accountData.icon}
                    logoUrl={accountData.logoUrl}
                    label={accountData.displayName}
                    balance={accountData.displayBalance}
                    createdAt={accountData.createdAt}
                    lastUpdatedAt={accountData.displayLastUpdatedAt}
                    dataProvider={accountData.dataProvider}
                    institution={accountData.institution}
                    syncDisabled={accountData.syncDisabled}
                  />
                </OptionContainer>
              );
            }}
            value={toAccountId}
            onChange={(value: AccountOption) => {
              setToAccountId(value.value);
            }}
          />
        </FlexContainer>
      </FlexContainer>
    </TransferAccountDataStep>
  );
};

export default AccountSelection;
