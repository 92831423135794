import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';

const useDataTransferAccounts = (fromAccountId?: string | null, toAccountId?: string | null) => {
  const {
    data,
    isLoadingInitialData: loading,
    error,
  } = useQuery(GET_ACCOUNTS_FOR_TRANSFER, {
    variables: { fromAccountId: fromAccountId!, toAccountId: toAccountId! },
    skip: !fromAccountId || !toAccountId,
  });
  return { data, loading, error };
};

const GET_ACCOUNTS_FOR_TRANSFER = gql(/* GraphQL */ `
  query Web_GetAccountsForTransfer($fromAccountId: UUID!, $toAccountId: UUID!) {
    fromAccount: account(id: $fromAccountId) {
      createdAt
      dataProvider
      ...AccountsListFields
    }
    toAccount: account(id: $toAccountId) {
      createdAt
      dataProvider
      ...AccountsListFields
    }
  }
`);

export default useDataTransferAccounts;
