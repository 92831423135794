import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accounts, { accountsPersistConfig } from 'common/state/accounts/reducer';
import analytics from 'common/state/analytics/reducer';
import client from 'common/state/client/reducer';
import dashboard from 'common/state/dashboard/reducer';
import employee from 'common/state/employee/reducer';
import flags from 'common/state/flags/reducer';
import user from 'common/state/user';
import auth, { authPersistConfig } from 'state/auth/reducer';
import emptyState from 'state/emptyState/reducer';
import filters, { filterPersistConfig } from 'state/filters/reducer';
import goals, { goalsPersistConfig } from 'state/goals/reducer';
import investments, { investmentsPersistConfig } from 'state/investments/reducer';
import multiHousehold, { multiHouseholdPersistConfig } from 'state/multiHousehold/reducer';
import navigation from 'state/navigation/reducer';
import onboarding from 'state/onboarding/reducer';
import persistentUi from 'state/persistentUi/reducer';
import plan, { planPersistConfig } from 'state/plan/reducer';
import recurring, { recurringPersistConfig } from 'state/recurring/reducer';
import reportsReducer, { persistConfig as reportsPersistConfig } from 'state/reports/reducer';
import scroll from 'state/scroll/reducer';
import statemenets, { statementsPersistConfig } from 'state/statements/reducer';
import type { RootAction, RootState } from 'state/types';
import ui from 'state/ui/reducer';

const accountsPersistConfigWeb = {
  key: accountsPersistConfig.key,
  storage,
};

const reducer = combineReducers({
  ui,
  user,
  employee,
  emptyState,
  scroll,
  persistentUi,
  plan: persistReducer(planPersistConfig, plan),
  dashboard,
  investments: persistReducer(investmentsPersistConfig, investments),
  recurring: persistReducer(recurringPersistConfig, recurring),
  goals: persistReducer(goalsPersistConfig, goals),
  auth: persistReducer(authPersistConfig, auth),
  statements: persistReducer(statementsPersistConfig, statemenets),
  multiHousehold: persistReducer(multiHouseholdPersistConfig, multiHousehold),
  filters: persistReducer(filterPersistConfig, filters),
  reports: persistReducer(reportsPersistConfig, reportsReducer),
  accounts: persistReducer(accountsPersistConfigWeb, accounts),
  analytics,
  onboarding,
  navigation,
  flags,
  client,
});

const rootReducer = (state: RootState | undefined, action: RootAction): RootState =>
  reducer(state, action);

export default rootReducer;
