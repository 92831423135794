import React from 'react';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import variables from 'common/lib/theme/variables';
import { getIsDemoMode } from 'selectors';

export const BaseGlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    line-height: 150%;
    color: ${({ theme }) => theme.color.text};
    background-color: ${variables.color.background.page};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-numeric: normal;
  }

  body,
  select,
  input,
  text {
    font-family: '${variables.typography.fontFamily}', sans-serif;
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  a {
    color: ${({ theme }) => theme.color.navy};
    &:hover {
      color: ${({ theme }) => theme.color.navyDark};
      transition: all 0.1s ease-out;
    }
    text-decoration: none;
  }

  [data-sonner-toast] {
    font-family: '${variables.typography.fontFamily}', sans-serif;
  }

  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible,
  button:focus-visible {
    outline: 1px solid ${({ theme }) => theme.color.blue};
  }

  * {
    outline-color: ${({ theme }) => theme.color.blue};
  }

  .grecaptcha-badge { visibility: hidden; }
`;

const DemoModeGlobalStyle = createGlobalStyle`
  .fs-exclude, .fs-mask {
    border: 1px dashed #ffaaaa;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0.4;
    filter: blur(6px);
  }
`;

const GlobalStyle = () => {
  const isDemoMode = useSelector(getIsDemoMode);
  return (
    <>
      <BaseGlobalStyle />
      {isDemoMode && <DemoModeGlobalStyle />}
    </>
  );
};

export default GlobalStyle;
