import React from 'react';

import DeleteReportModal from 'components/reports/DeleteReportModal';
import type { SaveReportConfigurationModalProps } from 'components/reports/SaveReportConfigurationModal';
import SaveReportConfigurationModal from 'components/reports/SaveReportConfigurationModal';
import Flow from 'components/utils/Flow';

import { useModalContext } from 'lib/contexts/ModalContext';

const SaveReportConfigurationFlow = (props: SaveReportConfigurationModalProps) => {
  const { close } = useModalContext();

  return (
    <Flow
      initialProps={props}
      steps={[SaveReportConfigurationModal, DeleteReportModal]}
      onComplete={close}
    />
  );
};

export default SaveReportConfigurationFlow;
