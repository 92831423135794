import { DateTime } from 'luxon';

const isoDate = DateTime.local().toISO();

export const MOCK_ADVICE_ITEMS = [
  {
    id: '86492474823005252',
    type: 'essential',
    title: 'Track cash flow',
    description:
      'Tracking cash flow shows you how much money is coming in and how much is going out. By setting up a system to watch your cash flow automatically you can find patterns and decide if you want to change where and how you save or spend.',
    category: {
      name: 'spend',
      displayName: 'Spend',
      color: '#F0648C',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 1,
    numTasksRemaining: 5,
    numTasks: 6,
    completedAt: null,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474759042113',
    type: 'objective',
    title: 'Buy a home',
    description:
      'Buying a home is a big goal for many people. This advice can help explain how the process works and help you figure out how much you might be able to afford.',
    category: {
      name: 'save',
      displayName: 'Save',
      color: '#32AAF0',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 0,
    numTasksRemaining: 7,
    numTasks: 7,
    completedAt: null,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474616435770',
    type: 'essential',
    title: 'Pay off credit card debt',
    description:
      'Being able to pay off all credit card debt is an amazing accomplishment. These tasks will guide you through a process to start tackling them with milestones you can make progress towards paying them all off.',
    category: {
      name: 'pay_down',
      displayName: 'Pay down',
      color: '#1B51BD',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 5,
    numTasksRemaining: 0,
    numTasks: 5,
    completedAt: isoDate,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474636358715',
    type: 'essential',
    title: 'Pay off high interest loans',
    description:
      'Being able to pay off private high interest loans is an amazing accomplishment. These tasks will guide you through a process to start tackling them with milestones you can make progress on towards the goal of being loan free.',
    category: {
      name: 'pay_down',
      displayName: 'Pay down',
      color: '#1B51BD',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 4,
    numTasksRemaining: 0,
    numTasks: 4,
    completedAt: isoDate,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474654184508',
    type: 'objective',
    title: 'Pay off student loans',
    description:
      'Whether you have federal or private loans this advice can help you figure out the best way to pay, refinance, or even forgive any debt you have that paid for your higher education.',
    category: {
      name: 'pay_down',
      displayName: 'Pay down',
      color: '#1B51BD',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 0,
    numTasksRemaining: 6,
    numTasks: 6,
    completedAt: null,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474596512825',
    type: 'objective',
    title: 'Save for college',
    description:
      'Saving and paying for a higher education is one of the best investments you can make in yourself or your child. This advice outlines the different types of accounts and options you may want to consider.',
    category: {
      name: 'invest',
      displayName: 'Invest',
      color: '#19D2A5',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 0,
    numTasksRemaining: 4,
    numTasks: 4,
    completedAt: null,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474707661887',
    type: 'essential',
    title: 'Review insurance',
    description:
      'Having the right insurance is one of the best ways to protect yourself and your family if something unexpected happens.\nThis advice will help you review all the common types of insurance: health, disability, life, car, home or even umbrella insurance.',
    category: {
      name: 'protect',
      displayName: 'Protect',
      color: '#91DCEB',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 6,
    numTasksRemaining: 0,
    numTasks: 6,
    completedAt: isoDate,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474738070592',
    type: 'essential',
    title: 'Save up an emergency fund',
    description:
      "An emergency fund is your best defense against large unexpected expenses. It can help cover expenses if you lose a job,\nneed to pay a large medical bill or cover a major home repair without using credit cards or carry interest. It can also help make sure you aren't holding too much cash.\n\nWe recommend saving 3, 6, 9 or 12-months of your take home pay depending on your situation. You can optimize across savings accounts, CD's or even a bond fund but the most important thing is having dedicated money set aside.\n\nTarget 1-month of take-home pay to start which can be the first milestone towards reaching a full emergency fund later.",
    category: {
      name: 'save',
      displayName: 'Save',
      color: '#32AAF0',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 1,
    numTasksRemaining: 3,
    numTasks: 4,
    completedAt: null,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474847122501',
    type: 'essential',
    title: 'Set a budget',
    description:
      'A budget is how you expect to use your money in the future. It can be used to set targets on how much and where money is spent or saved. When a budget is combined with cash flow it can help show how you are tracking towards your plan.',
    category: {
      name: 'spend',
      displayName: 'Spend',
      color: '#F0648C',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 4,
    numTasksRemaining: 0,
    numTasks: 4,
    completedAt: isoDate,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474864948294',
    type: 'essential',
    title: 'Put everything in one place',
    description:
      'Putting all your financial information in one place is the first step to building a plan. We always recommend this step first so there is a complete picture for delivering the best advice.',
    category: {
      name: 'wellness',
      displayName: 'Wellness',
      color: '#6E87F0',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 7,
    numTasksRemaining: 0,
    numTasks: 7,
    completedAt: isoDate,
    __typename: 'AdviceItem',
  },
  {
    id: '86492474895356999',
    type: 'essential',
    title: 'Check credit score & reports',
    description:
      "Having a good credit score means you can borrow money at a lower interest rate. Reviewing your credit reports regularly can help you fix any errors that may be lowering your credit score or find fraud that you didn't know about.\n\nWe recommend checking your credit report each year with the 3 major credit bureaus which are Experian, Equifax, and TransUnion. A federal law allows everyone to get their credit report for free once a year from each of the 3 credit bureaus which you can do directly on their websites or through their officially run website at\n[https://www.annualcreditreport.com/](https://www.annualcreditreport.com/)",
    category: {
      name: 'wellness',
      displayName: 'Wellness',
      color: '#6E87F0',
      __typename: 'AdviceItemCategory',
    },
    numTasksCompleted: 5,
    numTasksRemaining: 0,
    numTasks: 5,
    completedAt: isoDate,
    __typename: 'AdviceItem',
  },
];

export const MOCK_ADVICE_CATEGORIES = [
  {
    name: 'save',
    displayName: 'Save up',
    description:
      'Increase the amount of money put towards large future purchases. This can include everything from having cash for emergencies, a wedding, car, or home down payment.',
    __typename: 'AdviceItemCategory',
  },
  {
    name: 'spend',
    displayName: 'Spend',
    description:
      'The use of payments and a system to control and be aware of where money goes. This can include everything from bills, shopping, budgets, and cash flow.',
    __typename: 'AdviceItemCategory',
  },
  {
    name: 'pay_down',
    displayName: 'Pay down',
    description:
      'Decrease the amount of debt. This can include everything from paying off credit cards, student loans, or a home mortgage.',
    __typename: 'AdviceItemCategory',
  },
  {
    name: 'protect',
    displayName: 'Protect',
    description:
      'Safety from anything unexpected that might happen. This can include things like insurance and estate documents.',
    __typename: 'AdviceItemCategory',
  },
  {
    name: 'invest',
    displayName: 'Invest',
    description:
      'Safety from anything unexpected that might happen. This can include things like insurance and estate documents.',
    __typename: 'AdviceItemCategory',
  },
  {
    name: 'wellness',
    displayName: 'Wellness',
    description: 'Broadly improve all aspects of your finance.',
    __typename: 'AdviceItemCategory',
  },
];
