import type { PaymentMethod } from '@stripe/stripe-js';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import StripeCardInputForm from 'components/lib/external/StripeCardInputForm';
import StripeProvider from 'components/lib/external/StripeProvider';
import { Label } from 'components/lib/form/FormItemContainer';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import TaxLabel from 'components/lib/ui/TaxLabel';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import PayWithWalletButton from 'components/premium/PayWithWalletButton';
import UpgradeFormPlanOption from 'components/premium/UpgradeFormPlanOption';
import PromoCodeInput from 'components/settings/billing/PromoCodeInput';
import SponsoredSubscriptionBanner from 'components/settings/billing/SponsoredSubscriptionBanner';

import {
  getDaysLeftOfTrial,
  getYearlyBannerText,
  hasSponsoredPlanAvailable,
} from 'common/lib/billing/Billing';
import usePremiumStripeOfferings from 'common/lib/hooks/premium/usePremiumStripeOfferings';
import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import { formatCurrency } from 'common/utils/Currency';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import typewriter from 'lib/analytics/typewriter';
import useCreateSubscription from 'lib/hooks/billing/useCreateSubscription';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import { getTemporaryPromoCode } from 'state/onboarding/selectors';

import * as COPY from 'common/constants/copy';

import { PaymentPeriod } from 'common/generated/graphQlTypes/globalTypes';

const Root = styled.div``;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledPlanOption = styled(UpgradeFormPlanOption)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const PromoCodeLabel = styled(StyledLabel)`
  display: inline-block;
  cursor: pointer;
`;

const StripeLabel = styled(Text)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const StyledTaxLabel = styled(TaxLabel)`
  margin-top: -${({ theme }) => theme.spacing.default};
  text-align: right;
  display: block;
`;

const LockIcon = styled(Icon).attrs({ name: 'lock', size: 16 })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const FooterText = styled(Text)`
  display: block;
  margin: 30px 0;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const PayAmountAndDate = styled.p`
  display: flex;
  margin: 0 0 ${({ theme }) => theme.spacing.default};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PayAmountAndDateTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const SubmitButton = styled(AsyncButton)`
  ${primaryButtonMixin}
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledSponsoredSubscriptionBanner = styled(SponsoredSubscriptionBanner)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: center;
`;

const AllPlansButton = styled(TextButton)``;

const CancelAnytimeText = styled.p`
  margin-top: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  color: ${({ theme }) => theme.color.text};
  text-align: center;
  line-height: 150%;
`;

type Props = {
  initialPromoCode?: string;
  onSuccess: () => void;
  className?: string;
  isAdvisor?: boolean;
};

const PremiumUpgradeForm = ({
  initialPromoCode,
  className,
  onSuccess,
  isAdvisor = false,
}: Props) => {
  const isGoalOrientedSignUpTestOn = useIsFeatureFlagOn('ab-test-goal-oriented-signup', {
    trackImpression: true,
  });

  // don't display the trialDurationDays on the UI. Use daysLeftOfTrial
  const { hasPremiumTrialAvailable, trialDurationDays } = useTrialStatusQuery();

  const [promoCode, setPromoCode] = useState(initialPromoCode ?? null);

  const {
    plans,
    trialEndsAt,
    promoCodeDescription,
    promoCodeDuration,
    promoCodeDurationInMonths,
    promoCodeError,
    isLoadingInitialData,
    isNetworkRequestInFlight,
    referralCampaign,
  } = usePremiumStripeOfferings({ promoCode });

  const [isPromoCodeOpen, setIsPromoCodeOpen] = useState(initialPromoCode ?? false);

  useEffect(() => {
    if (referralCampaign && !isPromoCodeOpen) {
      setIsPromoCodeOpen(true);
    }
  }, [referralCampaign]);

  // by default we display only the annual plan.
  // If the user clicks on "see all plans" we display all plans by change this state to false.
  const [showOnlyAnnual, setShowOnlyAnnual] = React.useState(true);

  const [walletPaymentMethod, setWalletPaymentMethod] = useState<PaymentMethod | null>(null);

  const { createSubscription, loading: isCreateSubscriptionLoading } = useCreateSubscription();

  // Get if user has temporary promo code saved from query param and load it
  const temporaryPromoCode = useSelector(getTemporaryPromoCode);

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  const selectedPlan = plans[selectedPlanIndex];

  const proceedWithPurchase = async (paymentMethod: PaymentMethod | null) => {
    const maybePromoCode = promoCodeDescription ? promoCode : null;
    await createSubscription(paymentMethod, selectedPlan.stripeId ?? '', maybePromoCode);
    onSuccess();
  };

  useEffect(() => {
    if (!promoCode && temporaryPromoCode) {
      setPromoCode(temporaryPromoCode);
    }
  }, [promoCode, temporaryPromoCode]);

  useEffect(() => {
    if (walletPaymentMethod) {
      proceedWithPurchase(walletPaymentMethod);
    }
  }, [walletPaymentMethod]);

  // Set default plan when plans are loaded from the backend.
  useEffect(() => {
    const yearlyPlanIndex = R.findIndex(R.propEq('period', PaymentPeriod.YEARLY))(plans);
    setSelectedPlanIndex(yearlyPlanIndex >= 0 ? yearlyPlanIndex : 0);
  }, [plans]);

  const daysLeftOfTrial = useMemo(() => {
    const newTrialEndsAt = selectedPlan?.newTrialEndsAt ?? trialEndsAt;
    return newTrialEndsAt ? getDaysLeftOfTrial(newTrialEndsAt) : trialDurationDays;
  }, [selectedPlan, trialEndsAt, trialDurationDays]);

  const hideCreditCardInput = selectedPlan?.requirePaymentMethod === false;

  let buttonText = hasPremiumTrialAvailable
    ? COPY.PREMIUM.TIMELINE.FREE_TRIAL_CTA(daysLeftOfTrial || 0)
    : COPY.PREMIUM.UPGRADE_CTA.TRIAL_UNAVAILABLE;

  if (hasSponsoredPlanAvailable(plans ?? [])) {
    buttonText = COPY.PREMIUM.UPGRADE_CTA.HAS_SPONSOR_CODE;
  }

  const shouldDisplayPaymentRequestButton =
    RA.isNotNil(selectedPlan) && R.isNil(walletPaymentMethod);

  const todaysTotal = useMemo(
    () =>
      daysLeftOfTrial || isAdvisor
        ? formatCurrency(0)
        : formatCurrency(
            selectedPlan?.discountedPricePerPeriodDollars ?? selectedPlan?.pricePerPeriodDollars,
          ),
    [selectedPlan, daysLeftOfTrial, isAdvisor],
  );

  const onPromoCodeInputChange = (value: string | null) => {
    if (value) {
      setPromoCode(value);
      typewriter.webAppPromoCodeApplied({
        promoCode: value,
        auto: false,
      });
    }
  };

  const trialEndDate = useMemo(() => {
    const date = new Date();
    daysLeftOfTrial && date.setDate(date.getDate() + daysLeftOfTrial);
    return isoDateToAbbreviatedMonthDayAndYear(date.toISOString());
  }, [daysLeftOfTrial]);

  const seeAllPlans = () => {
    setShowOnlyAnnual(false);
    typewriter.allPlansViewed();
  };

  const userHasReferralCampaign = !!referralCampaign;

  return isLoadingInitialData ? (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <Root className={className}>
      {!isAdvisor &&
        plans.map(
          ({ pricePerPeriodDollars, discountedPricePerPeriodDollars, period }, index) =>
            ((period === PaymentPeriod.YEARLY && showOnlyAnnual) || !showOnlyAnnual) && (
              <StyledPlanOption
                price={pricePerPeriodDollars}
                discountedPrice={discountedPricePerPeriodDollars}
                duration={promoCodeDuration}
                durationInMonths={promoCodeDurationInMonths}
                period={period}
                isSelected={index === selectedPlanIndex}
                onClick={() => setSelectedPlanIndex(index)}
                promoBadgeText={
                  // If only showing the annual plan, hide the savings banner
                  !showOnlyAnnual
                    ? getYearlyBannerText(plans, period, {
                        // If there's an active referral campaign and we're showing all plans, use the discounted price
                        // to calculate the total savings. Otherwise, use the regular price.
                        compareMonthlyWithDiscountedYearlyPrice:
                          userHasReferralCampaign && !showOnlyAnnual,
                      })
                    : undefined
                }
              />
            ),
        )}
      {shouldDisplayPaymentRequestButton && (
        <PayWithWalletButton
          selectedPlanName={selectedPlan?.name}
          selectedPlanAmount={
            selectedPlan?.discountedPricePerPeriodDollars || selectedPlan?.pricePerPeriodDollars
          }
          daysLeftOfTrial={daysLeftOfTrial}
          onPaymentMethodSelected={(method: PaymentMethod) => setWalletPaymentMethod(method)}
        />
      )}
      {!hideCreditCardInput && <StyledLabel>Payment details</StyledLabel>}
      <StripeCardInputForm
        skipPaymentMethod={hideCreditCardInput}
        onSuccess={(paymentMethod) => proceedWithPurchase(paymentMethod)}
      >
        {({ isLoading: isStripeInputLoading }) => (
          <>
            {!hideCreditCardInput && (
              <StripeLabel>
                <LockIcon />
                Powered by Stripe
              </StripeLabel>
            )}
            {!isAdvisor && (
              <>
                <PromoCodeLabel onClick={() => setIsPromoCodeOpen(!isPromoCodeOpen)}>
                  <Icon name="tag" /> Add a Promo code
                </PromoCodeLabel>
                {isPromoCodeOpen && (
                  <PromoCodeInput
                    value={promoCode}
                    onChange={onPromoCodeInputChange}
                    isLoading={isNetworkRequestInFlight}
                    description={promoCodeDescription}
                    error={promoCodeError}
                  />
                )}
                {selectedPlan?.sponsoredBy && (
                  <StyledSponsoredSubscriptionBanner
                    hideRevokeAccessButton
                    sponsorName={selectedPlan?.sponsoredBy?.name}
                    sponsorProfilePictureUrl={selectedPlan?.sponsoredBy?.profilePictureUrl}
                  />
                )}
              </>
            )}
            <FooterText>
              <PayAmountAndDate>
                <PayAmountAndDateTitle>{COPY.PREMIUM.TIMELINE.TODAYS_TOTAL}</PayAmountAndDateTitle>
                <span>{todaysTotal}</span>
              </PayAmountAndDate>
              {!isAdvisor && daysLeftOfTrial !== 0 && (
                <PayAmountAndDate>
                  <PayAmountAndDateTitle>
                    {COPY.PREMIUM.TIMELINE.AFTER_TRIAL_TOTAL(trialEndDate)}
                  </PayAmountAndDateTitle>
                  <span>
                    {formatCurrency(
                      selectedPlan?.discountedPricePerPeriodDollars ??
                        selectedPlan?.pricePerPeriodDollars,
                    )}
                  </span>
                </PayAmountAndDate>
              )}
              <StyledTaxLabel />
            </FooterText>
            <SubmitButton
              size="large"
              type="submit"
              pending={isStripeInputLoading || isCreateSubscriptionLoading}
            >
              {isAdvisor ? COPY.ADVISOR_ONBOARDING.SELECT_PLAN.SUBMIT_BUTTON : buttonText}
            </SubmitButton>
            {!isAdvisor && (
              <CancelAnytimeText>
                {isGoalOrientedSignUpTestOn ? (
                  <>
                    We’ll email you before you are ever charged. <br />
                    You can cancel anytime during the trial.
                  </>
                ) : (
                  COPY.PREMIUM.TIMELINE.CANCEL_ANYTIME_TEXT
                )}
              </CancelAnytimeText>
            )}
          </>
        )}
      </StripeCardInputForm>
      {showOnlyAnnual && !isAdvisor && (
        <FooterButtons>
          <AllPlansButton onClick={seeAllPlans}>See all plans</AllPlansButton>
        </FooterButtons>
      )}
    </Root>
  );
};

const ProviderWrapper = (props: Props) => (
  <StripeProvider>
    <PremiumUpgradeForm {...props} />
  </StripeProvider>
);

export default ProviderWrapper;
