import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';

const usePreviewAccountDataTransferTransactions = (
  fromAccountId?: string | null,
  toAccountId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  previewDays: number | null = 3,
) => {
  const {
    data,
    isNetworkRequestInFlight: loading,
    error,
  } = useQuery(PREVIEW_ACCOUNT_DATA_TRANSFER_TRANSACTIONS_QUERY, {
    skip: !fromAccountId || !toAccountId || !startDate || !endDate,
    variables: {
      input: {
        fromAccountId: fromAccountId!,
        toAccountId: toAccountId!,
        startDate: startDate!,
        endDate: endDate!,
        previewDays,
      },
    },
  });
  return { data: data?.previewAccountDataTransferTransactions, loading, error };
};

const PREVIEW_ACCOUNT_DATA_TRANSFER_TRANSACTIONS_QUERY = gql(/* GraphQL */ `
  query Web_PreviewAccountDataTransferTransactions(
    $input: PreviewAccountDataTransferTransactionsInput!
  ) {
    previewAccountDataTransferTransactions(input: $input) {
      fromAccountSummary {
        transactionsInWindow {
          ...TransactionsListFields
        }
        numTransactionsOlderThanWindow
        numTransactionsNewerThanWindow
        numTransactions
        numOlderTransactions
        numNewerTransactions
      }
      toAccountSummary {
        transactionsInWindow {
          ...TransactionsListFields
        }
        numTransactionsOlderThanWindow
        numTransactionsNewerThanWindow
        numTransactions
        numOlderTransactions
        numNewerTransactions
      }
    }
  }
`);

export default usePreviewAccountDataTransferTransactions;
