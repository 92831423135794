import type { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import { useMemo } from 'react';

import { getSafeToSpendAmount, rolloverDisplayBudgetAdapter } from 'common/lib/budget/Adapters';
import { budgetSummaryAdapter } from 'common/lib/budget/Summary';
import { BUDGET_DATA_QUERY } from 'common/lib/graphQl/budgets';
import useQuery from 'common/lib/hooks/useQuery';

import { BudgetSystem } from 'common/generated/graphql';

type Options = {
  skip?: boolean;
  /** If true, groups items by variability, even if not using Flex budgeting. */
  forceGroupByVariability?: boolean;
};

const useBudgetData = (
  date: DateTime,
  { skip = false, forceGroupByVariability }: Options | undefined = {},
) => {
  const startDate = date.toISODate();
  const endDate = date.endOf('month').toISODate();

  const { data, ...queryInfo } = useQuery(BUDGET_DATA_QUERY, {
    variables: { startDate, endDate },
    skip,
  });

  // Account for the case where the budget system is not yet loaded
  const isFlexBudgetSystem = data && data.budgetSystem === BudgetSystem.FIXED_AND_FLEX;
  const shouldGroupByVariability = RA.isNotNil(forceGroupByVariability)
    ? forceGroupByVariability
    : isFlexBudgetSystem;
  const budgetStatusData = data?.budgetStatus;

  const budgetSummaryData = useMemo(
    () => (data ? budgetSummaryAdapter(data, date) : []),
    [data, date],
  );

  const safeToSpend = useMemo(() => getSafeToSpendAmount(budgetSummaryData), [budgetSummaryData]);

  const budgetListData = useMemo(
    () =>
      data
        ? rolloverDisplayBudgetAdapter(data, date, shouldGroupByVariability, budgetSummaryData)
        : undefined,
    [date, data, shouldGroupByVariability, budgetSummaryData],
  );

  return {
    ...queryInfo,
    budgetListData,
    budgetStatusData,
    budgetSummaryData,
    isFlexBudgetSystem,
    safeToSpend,
    shouldShowSafeToSpend: !!isFlexBudgetSystem,
  };
};

export default useBudgetData;
