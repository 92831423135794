import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { FiLock } from 'react-icons/fi';
import styled from 'styled-components';

import { OwnerRestrictedAccessMessageVariant } from 'common/components/settings/OwnerRestrictedAccessMessage';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import Avatar from 'components/lib/ui/Avatar';
import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import CloseButton from 'components/lib/ui/button/CloseButton';
import DeleteMemberModal from 'components/settings/members/DeleteMemberModal';
import GrantAccessModal from 'components/settings/members/GrantAccessModal';
import InviteMemberModal from 'components/settings/members/InviteMemberModal';
import OnwerRestrictedActionModal from 'components/settings/members/OwnerRestrictedActionModal';
import PendingInvitationListItem from 'components/settings/members/PendingInvitationListItem';

import useEventCallback from 'common/lib/hooks/useEventCallback';
import useToggle from 'common/lib/hooks/useToggle';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import {
  getHouseholdInvites,
  getHouseholdMembers,
  getHouseholdRole,
} from 'selectors/settingsSelectors';

import { HOUSEHOLD_ACCESS_GRANTS } from 'common/constants/copy';

import { gql } from 'common/generated/gql';
import { UserHouseholdRole } from 'common/generated/graphql';

const StyledSettingsCard = styled(SettingsCard)`
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const DeleteMemberButton = styled(CloseButton)`
  margin-left: auto;
  color: ${({ theme }) => theme.color.textLight};
`;

const StyledBanner = styled.div`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.large};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const MfaText = styled(Text)`
  margin-left: ${({ theme }) => theme.spacing.default};
  color: ${({ theme }) => theme.color.greenText};
  padding-top: 2px;
`;

type MemberInfoToDelete = { name: string; id: string };

const MemberSettings = () => {
  const { isDemoHousehold, blockActionForDemoHousehold } = useDemoHousehold();

  const { data, refetch } = useQuery(QUERY);
  const [revokeInvite] = useMutation(REVOKE_INVITE_MUTATION);
  const [revokeAccessGrant] = useMutation(REVOKE_ACCESS_GRANT_MUTATION);
  const [resendInvite] = useMutation(RESEND_INVITE_MUTATION);

  const [inviteMemberModalShown, { setOn: showInviteMemberModal, setOff: closeInviteMemberModal }] =
    useToggle(false);
  const [memberInfoToDelete, setMemberInfoToDelete] = useState<MemberInfoToDelete | null>(null);
  const [grantAccessModalShown, { setOn: showGrantAccessModal, setOff: closeGrantAccessModal }] =
    useToggle(false);
  const [ownerRestrictedModalVariant, setOwnerRestrictedModalVariant] =
    useState<OwnerRestrictedAccessMessageVariant | null>(null);

  const invites = getHouseholdInvites(data);
  const members = getHouseholdMembers(data);
  const myRole = getHouseholdRole(data);
  const accessGrants = data?.householdAccessGrants;
  const isOwner = myRole === UserHouseholdRole.OWNER;

  const blockActionForNonOwner = useEventCallback(
    (variant: OwnerRestrictedAccessMessageVariant, ownerCallback: () => void | Promise<void>) => {
      if (!isOwner) {
        setOwnerRestrictedModalVariant(variant);
        return;
      }

      ownerCallback();
    },
  );

  const onInviteMember = useEventCallback(() => {
    const ownerAction = () => blockActionForDemoHousehold(showInviteMemberModal, false);
    blockActionForNonOwner(OwnerRestrictedAccessMessageVariant.AddMember, ownerAction);
  });

  const onDeleteMember = useEventCallback(({ id, name }: MemberInfoToDelete) => {
    const ownerAction = () => setMemberInfoToDelete({ id, name });
    blockActionForNonOwner(OwnerRestrictedAccessMessageVariant.RemoveMember, ownerAction);
  });

  const onRevokeInvite = useEventCallback((id: string) => {
    const ownerAction = async () => {
      await revokeInvite({ variables: { input: { inviteId: id } } });
      refetch();
    };
    blockActionForNonOwner(OwnerRestrictedAccessMessageVariant.RemoveInvite, ownerAction);
  });

  const owner = useMemo(
    () => members?.find((owner) => owner.householdRole === UserHouseholdRole.OWNER),
    [members],
  );

  return (
    <Column md={9}>
      <Helmet>
        <title>Member Settings</title>
      </Helmet>
      {!R.isEmpty(invites) && (
        <StyledSettingsCard title="Invitations" isDemo={isDemoHousehold}>
          {invites.map(({ invitedEmail, createdAt, id }) => (
            <PendingInvitationListItem
              key={id}
              id={id}
              createdAt={createdAt}
              invitedEmail={invitedEmail}
              revokeInvite={() => onRevokeInvite(id)}
              resendInvite={async (id) => {
                await resendInvite({ variables: { input: { inviteId: id } } });
              }}
            />
          ))}
        </StyledSettingsCard>
      )}
      <StyledSettingsCard
        title="Household Members"
        controls={<TextButton onClick={onInviteMember}>Add Household Member</TextButton>}
        isDemo={isDemoHousehold}
      >
        <StyledBanner>
          <Text weight="medium">
            Use this for family members, partners, or someone else you manage your household with.
          </Text>{' '}
          Members you invite will be able to login to your household with their own
          username/password.
        </StyledBanner>
        {members &&
          members.map(({ name, householdRole, displayRole, id, hasMfaOn, profilePictureUrl }) => (
            <FlexContainer key={name} padding="large">
              <Avatar $url={profilePictureUrl} $size={48} />
              <FlexContainer column marginHorizontal="default">
                <Text size="large" weight="medium">
                  {name}
                </Text>
                <FlexContainer alignCenter>
                  <Text>{displayRole}</Text>
                  <MfaText size="small" weight="medium">
                    {hasMfaOn && (
                      <>
                        <FiLock size={12} /> Multi-factor authentication enabled
                      </>
                    )}
                  </MfaText>
                </FlexContainer>
              </FlexContainer>
              {householdRole !== UserHouseholdRole.OWNER && (
                <DeleteMemberButton onClick={() => onDeleteMember({ id, name })} />
              )}
            </FlexContainer>
          ))}
      </StyledSettingsCard>
      <StyledSettingsCard
        title="Financial Professionals"
        controls={
          <TextButton onClick={() => blockActionForDemoHousehold(showGrantAccessModal, false)}>
            Give Access to Financial Professional
          </TextButton>
        }
        isDemo={isDemoHousehold}
      >
        <StyledBanner>
          <Text weight="medium">Use this for financial professionals you work with.</Text> They will
          have access to your account through the Monarch for Professionals Program and you can
          revoke access at any time. Access will expire after{' '}
          {HOUSEHOLD_ACCESS_GRANTS.ACCESS_GRANT_DURATION_DAYS} days.
        </StyledBanner>
        {accessGrants &&
          accessGrants.map(({ toEmail, toName, id, expiresAt }) => (
            <FlexContainer key={id} padding="large">
              <Avatar $size={48} />
              <FlexContainer column marginHorizontal="default">
                <Text size="large" weight="medium">
                  {toName || toEmail}
                </Text>
                <Text>
                  {expiresAt && <>Expires {isoDateToAbbreviatedMonthDayAndYear(expiresAt)}</>}
                </Text>
              </FlexContainer>
              <DeleteMemberButton
                onClick={async () => {
                  await revokeAccessGrant({
                    variables: { input: { id } },
                  });
                  refetch();
                }}
              />
            </FlexContainer>
          ))}
      </StyledSettingsCard>
      {inviteMemberModalShown && (
        <InviteMemberModal onClose={closeInviteMemberModal} onInviteSuccess={refetch} />
      )}
      {grantAccessModalShown && (
        <GrantAccessModal onClose={closeGrantAccessModal} onGrantSuccess={refetch} />
      )}
      {ownerRestrictedModalVariant && (
        <OnwerRestrictedActionModal
          onClose={() => setOwnerRestrictedModalVariant(null)}
          ownerName={owner?.name ?? 'your household owner'}
          variant={ownerRestrictedModalVariant}
        />
      )}
      {memberInfoToDelete && (
        <DeleteMemberModal
          memberInfo={memberInfoToDelete}
          onClose={() => setMemberInfoToDelete(null)}
          onDeleteSuccess={() => {
            refetch();
            setMemberInfoToDelete(null);
          }}
        />
      )}
    </Column>
  );
};

const QUERY = gql(`
  query GetHouseHoldMemberSettings {
    householdInvites {
      id
      invitedEmail
      createdAt
      isRevoked
      usedAt
    }
    me {
      id
      householdRole
    }
    myHousehold {
      id
      users {
        id
        name
        householdRole
        hasMfaOn
        profilePictureUrl
      }
    }
    householdAccessGrants {
      id
      toEmail
      toName
      expiresAt
    }
  }
`);

const REVOKE_INVITE_MUTATION = gql(`
  mutation Web_RevokeInviteToHousehold($input: RevokeInviteToHouseholdMutationInput!) {
    revokeInviteToHousehold(input: $input) {
      invite {
        id
        isRevoked
      }
    }
  }
`);

const RESEND_INVITE_MUTATION = gql(`
  mutation Web_ResendInviteToHousehold($input: ResendInviteToHouseholdMutationInput!) {
    resendInviteToHousehold(input: $input) {
      invite {
        id
      }
    }
  }
`);

const REVOKE_ACCESS_GRANT_MUTATION = gql(`
  mutation Web_RevokeHouseholdAccessGrant($input: RevokeHouseholdAccessGrantInput!) {
    revokeHouseholdAccessGrant(input: $input) {
      revoked
    }
  }
`);

export default MemberSettings;
