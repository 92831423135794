import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import DateRangeWithTimeframesPickerButton from 'components/lib/dates/DateRangeWithTimeframesPickerButton';
import DividerLine from 'components/lib/ui/DividerLine';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';
import ReportsConfigurationsMenuButton from 'components/reports/ReportsConfigurationsMenuButton';
import ReportsFilterMenuButton from 'components/reports/ReportsFilterMenuButton';
import TransactionDateRangeButton from 'components/transactions/header/TransactionDateRangeButton';

import { patchReportsFilters } from 'actions';
import { useDispatch } from 'lib/hooks';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import { INITIAL_STATE as REPORTS_INITIAL_STATE } from 'state/reports/reducer';
import { selectReportsFilters } from 'state/reports/selectors';

import type { Timeframe, ReportConfiguration } from 'common/generated/graphql';
import type { DateRange, DateRangeWithTimeframes } from 'common/types/DateRange';

const REPORTS_DEFAULT_DATE_RANGE = R.pick(
  ['startDate', 'endDate', 'timeframePeriod'],
  REPORTS_INITIAL_STATE.filters,
);

type Props = {
  reportConfigurations: ReportConfiguration[];
  selectedReportConfigurationId?: string;
  onSelectReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
  onSaveReportConfiguration?: () => void;
  onEditReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
  onResetToDefaultReportConfiguration?: () => void;
};

const ReportsHeaderControls = ({
  reportConfigurations,
  selectedReportConfigurationId,
  onSelectReportConfiguration,
  onSaveReportConfiguration,
  onEditReportConfiguration,
  onResetToDefaultReportConfiguration,
}: Props) => {
  const dispatch = useDispatch();

  const showReportsSavedViews = useIsFeatureFlagOn('reports-saved-views');

  const filters = useSelector(selectReportsFilters);
  const { startDate, endDate, timeframePeriod } = filters;

  const hasActiveFilters = Object.entries(filters).some(([_, value]) => RA.isNotNil(value));

  const handleDateRangeChange = useCallback(
    ({ startDate, endDate }: DateRange) =>
      dispatch(patchReportsFilters(getReportDateFilters({ startDate, endDate }))),
    [dispatch],
  );

  const handleDateRangeWithTimeframesChange = useCallback(
    (dateRangeWithTimeframes: DateRangeWithTimeframes) =>
      dispatch(patchReportsFilters(getReportDateFilters(dateRangeWithTimeframes))),
    [dispatch],
  );

  return (
    <FlexContainer gap="small" alignCenter justifyEnd>
      {showReportsSavedViews ? (
        <>
          {hasActiveFilters && (
            <TextButton onClick={onResetToDefaultReportConfiguration}>Clear</TextButton>
          )}
          <DateRangeWithTimeframesPickerButton
            dateRange={{
              startDate,
              endDate,
              timeframeUnit: timeframePeriod?.unit,
              timeframeValue: timeframePeriod?.value,
              includeCurrentPeriod: timeframePeriod?.includeCurrent,
            }}
            defaultRange={REPORTS_DEFAULT_DATE_RANGE}
            onChangeDateRange={handleDateRangeWithTimeframesChange}
          />
        </>
      ) : (
        <TransactionDateRangeButton
          dateRange={{ startDate, endDate }}
          defaultRange={REPORTS_DEFAULT_DATE_RANGE}
          onChangeDateRange={handleDateRangeChange}
        />
      )}
      <ReportsFilterMenuButton />
      {showReportsSavedViews && (
        <>
          <DividerLine />
          <ReportsConfigurationsMenuButton
            hasActiveFilters={hasActiveFilters}
            reportConfigurations={reportConfigurations}
            selectedReportConfigurationId={selectedReportConfigurationId}
            onSelectReportConfiguration={onSelectReportConfiguration}
            onSaveReportConfiguration={onSaveReportConfiguration}
            onEditReportConfiguration={onEditReportConfiguration}
            onResetToDefaultReportConfiguration={onResetToDefaultReportConfiguration}
          />
        </>
      )}
    </FlexContainer>
  );
};

const getReportDateFilters = ({
  startDate,
  endDate,
  timeframeUnit,
  timeframeValue,
  includeCurrentPeriod,
}: DateRangeWithTimeframes) => {
  const dateFilters = {
    startDate,
    endDate,
  };

  if (R.isNil(timeframeUnit) || R.isNil(timeframeValue) || R.isNil(includeCurrentPeriod)) {
    return {
      ...dateFilters,
      timeframePeriod: undefined,
    };
  }

  return {
    ...dateFilters,
    timeframePeriod: {
      unit: timeframeUnit as Timeframe,
      value: timeframeValue,
      includeCurrent: includeCurrentPeriod,
    },
  };
};

export default ReportsHeaderControls;
