import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import type { Props as TooltipProps } from 'components/lib/ui/Tooltip';
import Tooltip from 'components/lib/ui/Tooltip';

import useToggle from 'common/lib/hooks/useToggle';
import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';

const Root = styled.div`
  width: 100%;
`;

export const OptionRow = styled(FlexContainer)<{ isSelected?: boolean }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  transition: ${({ theme }) => theme.transition.default};
  background: ${({ theme }) => theme.color.grayBackground};
  border-radius: 6px;
  cursor: pointer;
  user-select: none;

  :not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.xsmall};
  }

  :hover {
    background: ${({ theme }) => theme.color.grayFocus};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      && {
        background: ${({ theme }) => theme.color.orangeBackground};
        color: ${({ theme }) => theme.color.orange};
      }
    `}
`;

const SelectIndicator = styled(FlexContainer).attrs({ center: true })<{ isSelected: boolean }>`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray};
  width: ${({ theme }) => theme.spacing.large};
  height: ${({ theme }) => theme.spacing.large};
  margin-right: ${({ theme }) => theme.spacing.default};
  margin-top: 2px;
  flex-shrink: 0;
  transition: ${({ theme }) => theme.transition.default};

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${({ theme }) => theme.color.orange};
      border-color: ${({ theme }) => theme.color.orange};
    `}
`;

const Checkbox = styled(SelectIndicator)`
  border-radius: ${({ theme }) => theme.radius.small};
`;

export const CircleIndicator = styled(SelectIndicator)`
  border-radius: ${({ theme }) => theme.radius.round};
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${({ theme }) => theme.radius.round};
  background: ${({ theme }) => theme.color.white};
`;

const CheckIcon = styled(Icon).attrs({ name: 'check' })`
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.color.textWhite};
`;

const TooltipWrapper = styled.div`
  width: max-content;
`;

const OptionRowContainer = ({
  isSelected,
  allowMultiple,
  label,
  onClick,
  RowComponent = OptionRow,
}: {
  isSelected: boolean;
  allowMultiple: boolean;
  label: string;
  onClick: () => void;
  RowComponent?: typeof OptionRow;
}) => (
  <RowComponent isSelected={isSelected} onClick={onClick}>
    {allowMultiple ? (
      <Checkbox isSelected={isSelected}>{isSelected && <CheckIcon />}</Checkbox>
    ) : (
      <CircleIndicator isSelected={isSelected}>{isSelected && <Dot />}</CircleIndicator>
    )}

    <Text>{label}</Text>
  </RowComponent>
);

export type OptionType<ValueT> = {
  value: ValueT;
  label: string;
  accessory?: React.ReactNode;
  tooltipProps?: TooltipProps;
};

export type Props<ValueT> = {
  options: OptionType<ValueT>[];
  value?: { value: ValueT } | { value: ValueT }[];
  onChange?: (value: { value: ValueT } | { value: ValueT }[]) => void;
  allowMultiple?: boolean;
  required?: boolean;
  isNoneSelectedInitial?: boolean;
  RowComponent?: typeof OptionRow;
};

const Radio = <ValueT = any,>({
  options,
  value,
  onChange,
  allowMultiple = false,
  required,
  isNoneSelectedInitial = false,
  RowComponent = OptionRow,
}: Props<ValueT>) => {
  const isOptionSelected = (optionValue: ValueT) =>
    value && RA.isArray(value)
      ? value?.some(({ value }) => value === optionValue)
      : value?.value === optionValue;

  const toggleOption = (optionValue: ValueT) =>
    onChange?.(
      value && RA.isArray(value)
        ? R.symmetricDifferenceWith(({ value: a }, { value: b }) => a === b, value ?? [], [
            { value: optionValue },
          ])
        : { value: optionValue },
    );

  const [isNoneSelected, { toggle: toggleNoneSelected, setOff: disableNoneSelected }] =
    useToggle(isNoneSelectedInitial);

  useUpdateEffect(() => {
    if (isNoneSelected) {
      onChange?.([]);
    }
  }, [isNoneSelected]);

  useUpdateEffect(() => {
    if (value && RA.isArray(value) && value.length > 0) {
      disableNoneSelected();
    }
  }, [value]);

  const renderOptionRow = (option: OptionType<ValueT>) => {
    const { value: optionValue, label, tooltipProps } = option;

    const optionRow = (
      <OptionRowContainer
        key={label}
        isSelected={isOptionSelected(optionValue)}
        allowMultiple={allowMultiple}
        onClick={() => toggleOption(optionValue)}
        label={label}
        RowComponent={RowComponent}
      />
    );

    return tooltipProps ? (
      <Tooltip {...tooltipProps}>
        <TooltipWrapper>{optionRow}</TooltipWrapper>
      </Tooltip>
    ) : (
      optionRow
    );
  };

  return (
    <Root>
      {options.map((option, index) => (
        <React.Fragment key={`${index}-${option.label}`}>
          {renderOptionRow(option)}
          {option.accessory}
        </React.Fragment>
      ))}

      {!required && allowMultiple && (
        <OptionRowContainer
          isSelected={isNoneSelected}
          allowMultiple={allowMultiple}
          onClick={toggleNoneSelected}
          label="None of the above"
          RowComponent={RowComponent}
        />
      )}
    </Root>
  );
};

export default Radio;
