import React from 'react';
import styled from 'styled-components';

import Menu, { MenuItem } from 'components/lib/ui/menu/Menu';

import { DEFAULT_SHORTCUTS } from 'common/lib/dateRange';
import type { Shortcut } from 'common/lib/dateRange/dateRangeWithTimeframes';

import type { DateRangeWithTimeframes } from 'common/types/DateRange';

export const SHORTCUTS_WIDTH_PX = 140;

type ShortcutProps = {
  className?: string;
  shortcuts?: Shortcut[];
  selectedShortcutId?: string;
  onSelectDateRange: (range: DateRangeWithTimeframes) => void;
};

const ShortcutMenu = styled(Menu)`
  height: 100%;
  min-width: ${SHORTCUTS_WIDTH_PX}px;
  border-right: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.color.text};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  padding: 5.5px ${({ theme }) => theme.spacing.xsmall};
  margin: ${({ theme }) => theme.spacing.xxxsmall} 0;
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const DateRangePickerShortcuts = ({
  className,
  shortcuts = DEFAULT_SHORTCUTS,
  selectedShortcutId,
  onSelectDateRange,
}: ShortcutProps) => (
  <ShortcutMenu className={className}>
    {shortcuts.map(({ title, id, ...dateRange }) => (
      <StyledMenuItem
        key={id ?? title}
        onClick={() => onSelectDateRange(dateRange)}
        selected={id && selectedShortcutId === id}
      >
        {title}
      </StyledMenuItem>
    ))}
  </ShortcutMenu>
);

export default DateRangePickerShortcuts;
