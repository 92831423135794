import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setReportsFilters } from 'actions';
import { track } from 'lib/analytics/segment';
import { getQueryParamsFromFilters } from 'lib/hooks/transactions/useFilters';
import useQueryParamFiltersWithFalsyValues from 'lib/hooks/transactions/useQueryParamFiltersWithFalsyValues';
import useDispatch from 'lib/hooks/useDispatch';
import useSelectors from 'lib/hooks/useSelectors';
import { correctDatesForFilters } from 'lib/transactions/Filters';
import {
  selectReportsFilters,
  selectReportsGroupBy,
  selectReportsGroupByTimeframe,
  selectReportsSortBy,
  selectDisplayPropertiesForTab,
} from 'state/reports/selectors';
import type { ReportsTab } from 'state/reports/types';
import { ReportsChart } from 'state/reports/types';
import type { RootState } from 'state/types';

import { ReportsEventNames } from 'common/constants/analytics';
import routes from 'constants/routes';

import type { TransactionFilters } from 'types/filters';

export type Props = {
  currentTab: ReportsTab;
};

export const useReportsParameters = ({ currentTab }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showAllEntities, setShowAllEntities] = useState(false);

  const queryParamFilters = useQueryParamFiltersWithFalsyValues();

  const [filters, groupBy, groupByTimeframe, sortTransactionsBy] = useSelectors([
    selectReportsFilters,
    selectReportsGroupBy,
    selectReportsGroupByTimeframe,
    selectReportsSortBy,
  ]);
  const { chartType, groupMode, viewMode } = useSelector((state: RootState) =>
    selectDisplayPropertiesForTab(state, currentTab),
  );

  const [quickViewFilters, setQuickViewFilters] = useState<Partial<TransactionFilters>>({});
  const [selectedDatumId, setSelectedDatumId] = useState<Maybe<string>>(null);
  const [selectedDateRange, setSelectedDateRange] =
    useState<Maybe<{ startDate: string; endDate: string }>>(null);

  const allFilters = useMemo(() => {
    const mergedFilters = R.mergeRight(filters, quickViewFilters);
    return correctDatesForFilters(mergedFilters);
  }, [filters, quickViewFilters]);

  const hasQuickViewFilters = useMemo(
    () => Object.keys(quickViewFilters).length > 0,
    [quickViewFilters],
  );

  const analyticsMetadata = useMemo(
    () => ({
      chartType,
      viewMode: ['spending', 'income'].includes(currentTab) ? viewMode : undefined,
      grouping: groupMode ?? groupBy,
      timeframe: [
        ReportsChart.CashFlowChart,
        ReportsChart.StackedCashFlowChart,
        ReportsChart.BarChart,
        ReportsChart.StackedBarChart,
      ].includes(chartType)
        ? groupByTimeframe
        : undefined,
    }),
    [chartType, currentTab, viewMode, groupMode, groupBy, groupByTimeframe],
  );

  const removeQuickViewFilters = useCallback(
    (filtersToRemove?: Partial<TransactionFilters>, userInitiatedAction = true) => {
      if (filtersToRemove) {
        // This logic currently clobbers the entire filter provided; i.e., if a single category is
        // requested for removal (and is passed as ['sample-id'] to the categories sub-field), all
        // categories are actually removed, not just the one requested. This is acceptable for now
        // because, technically, only a single quick view filter should ever be applied at a time,
        // even though there is support for multiple. If a use case arises where multiple quick
        // view filters can actually be applied at once, this logic will need to be revisited.
        setQuickViewFilters(R.omit(Object.keys(filtersToRemove), quickViewFilters));
      } else {
        setQuickViewFilters({});
      }

      if (userInitiatedAction) {
        track(ReportsEventNames.ReportsChartDataSelectionReset, analyticsMetadata);
      }
    },
    [analyticsMetadata, quickViewFilters],
  );

  useEffect(() => {
    // Reset every time the tab or filters change
    setShowAllEntities(false);
  }, [currentTab, filters]);

  useEffect(() => {
    // Only track this event for changes to the reports configuration (except the tab). Tab changes
    // are already tracked as a page level event, and filters changes are captured elsewhere.
    track(ReportsEventNames.ReportsConfigurationChanged, analyticsMetadata);
  }, [chartType, viewMode, groupMode, groupBy, groupByTimeframe, analyticsMetadata]);

  useEffect(() => {
    // Reset the quick-view filters every time top-level changes occur. Changes to the quick-view
    // filters themselves should not trigger this, as it would mean *any change is destructive.
    removeQuickViewFilters(undefined, false);
  }, [currentTab, filters, chartType, viewMode, groupMode, groupBy, groupByTimeframe]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hasQuickViewFilters) {
      setSelectedDatumId(null);
      setSelectedDateRange(null);
    }
  }, [hasQuickViewFilters]);

  // First time loading the page we use the query params provided by the URL
  // and if the history location changes then we check if filters need to be updated
  useEffect(() => {
    const queryParamsFromQueryParamFilters = getQueryParamsFromFilters(queryParamFilters);
    const filtersQueryParams = getQueryParamsFromFilters(filters);

    if (!R.equals(filtersQueryParams, queryParamsFromQueryParamFilters)) {
      if (R.isEmpty(queryParamsFromQueryParamFilters)) {
        // If the query params are empty we should reuse what is saved
        // in the store instead of just cleaning the filters
        dispatch(setReportsFilters(filters));
      } else {
        // filter query params to remove empty arrays and empty strings
        // as the reports filters do not need to store it (e.g empty categories, accounts, search)
        const filteredQueryParams = R.filter(
          (value) => !R.isEmpty(value) && !R.isNil(value),
          queryParamFilters,
        );
        dispatch(setReportsFilters(filteredQueryParams));
      }
    }
  }, [history.location]);

  // This will update the query params to match the filters state
  useEffect(() => {
    const filtersQueryParams = getQueryParamsFromFilters(filters);
    const routeParams: Record<string, unknown> = {};

    if (!R.isEmpty(filtersQueryParams)) {
      routeParams.queryParams = filtersQueryParams;
    }

    const url = routes.reports[currentTab](routeParams);
    // pushState only updates the URL without actually reload
    // this is needed to prevent infinite loop from syncing filters/query params
    window.history.pushState(
      {
        path: url,
      },
      '',
      url,
    );
  }, [filters]);

  return {
    showAllEntities,
    setShowAllEntities,
    filters,
    allFilters,
    groupBy,
    groupByTimeframe,
    sortTransactionsBy,
    chartType,
    groupMode,
    viewMode,
    quickViewFilters,
    setQuickViewFilters,
    selectedDatumId,
    setSelectedDatumId,
    selectedDateRange,
    setSelectedDateRange,
    hasQuickViewFilters,
    removeQuickViewFilters,
    analyticsMetadata,
  };
};
