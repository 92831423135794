import { upperFirst } from 'lodash';
import type { Interval, Duration } from 'luxon';
import { DateTime } from 'luxon';
import { isHoliday } from 'nyse-holidays';
import pluralize from 'pluralize';

import type { Timeframe } from 'common/constants/timeframes';

type ISODate = string;

/**
 * Native JS date parsing assumes ISO strings (e.g. "2020-03-05") are UTC. Depending
 * on the local timezone, the date (Date) can be interpreted as the
 * previous day. This function considers date strings to be timezone-free, so
 * we use luxon's DateTime parsing to create the Date without tweaking the
 * hour.
 */
export const parseISODate = (isoDateString: ISODate) => DateTime.fromISO(isoDateString).toJSDate();

export const formatISODate = (date: Date) => DateTime.fromJSDate(date).toISODate();

export const formatFullDate = (date: Date) =>
  DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL);

export const isoDateToMonthAbbreviation = (isoDateString: ISODate) =>
  DateTime.fromISO(isoDateString).toFormat('MMM');

export const isoDateToMonthAndDay = (isoDateString: ISODate) =>
  DateTime.fromISO(isoDateString).toFormat('MMMM d');

export const isoDateToMonthAbbreviationAndDay = (isoDateString: ISODate) =>
  DateTime.fromISO(isoDateString).toFormat('MMM d');

export const isoDateToMonthAndYear = (isoDateString: ISODate) =>
  DateTime.fromISO(isoDateString).toFormat('MMMM yyyy');

export const isoDateToAbbreviatedMonthAndYear = (isoDateString: ISODate) =>
  `${DateTime.fromISO(isoDateString).toFormat(`MMM`)} '${DateTime.fromISO(isoDateString).toFormat(
    'yy',
  )}`;

export const isoDateToMonthAndAbbreviatedYear = (isoDateString: ISODate) =>
  `${DateTime.fromISO(isoDateString).toFormat(`MMMM`)} '${DateTime.fromISO(isoDateString).toFormat(
    'yy',
  )}`;

export const isoDateToAbbreviatedMonthDayAndYear = (isoDateString: string) =>
  DateTime.fromISO(isoDateString).toFormat('MMM d, yyyy');

export const hasDatePassed = (date: DateTime) => date < DateTime.local();

export const getNumberOfMonthsToDate = (isoDateString: ISODate) =>
  DateTime.local().until(DateTime.fromISO(isoDateString)).count('months') - 1;

// Luxon's built-in includes is end-exlusive [start, end), this makes it inclusive, [start, end]
export const luxonIntervalContainsInclusive = (interval: Interval, date: DateTime): boolean =>
  interval.contains(date) || interval.end.equals(date);

export const isNYSEOpen = (date: DateTime): boolean =>
  !isWeekend(date) && !isHoliday(date.toJSDate());

export const isWeekend = (date: DateTime) => [0, 6].includes(date.toJSDate().getDay());

export const getQuarterForDate = (date: DateTime) => date.quarter;

export const isSameDay = (date1: DateTime, date2: DateTime) =>
  date1.toISODate() === date2.toISODate();

export const isSameMonth = (date1: DateTime, date2: DateTime) => date1.month === date2.month;

export const isoDateToMonthAbbreviationAndDayWithToday = (isoDateString: ISODate) =>
  isSameDay(DateTime.fromISO(isoDateString), DateTime.local())
    ? 'Today'
    : isoDateToMonthAbbreviationAndDay(isoDateString);

export const getStartOfCurrentMonth = () => DateTime.local().startOf('month');

export const getStartOfCurrentMonthISO = () => getStartOfCurrentMonth().toISODate();

export const getStartOfPreviousMonth = () => DateTime.local().minus({ months: 1 }).startOf('month');

export const isTimestampPastExpiration = (timestamp: string, expiration: Duration) => {
  const dt = DateTime.fromISO(timestamp);
  const diff = dt.diffNow().milliseconds;

  return -diff > expiration.as('milliseconds');
};

// Removes the time of the date ignoring the second half of the ISO date
export const getISODateWithoutTimezone = (date: ISODate) => date?.split('T')[0];

/**
 * Clamps a given date to an "earliest" or "latest" date. As an example, if the earliest date is
 * January 10, 2024, and the provided date is January 1, 2024, the date will be clamped to the 10th.
 * Otherwise, the date will be returned as is. Similar to `Math.min` / `Math.max`, but for dates.
 */
export const clampDate = (
  initialDate: DateTime | string,
  clampDate?: DateTime | string | null,
  clampMode: 'earliest' | 'latest' = 'earliest',
): DateTime => {
  const dt = typeof initialDate === 'string' ? DateTime.fromISO(initialDate) : initialDate;

  if (!clampDate) {
    return dt;
  }

  const clampDt = typeof clampDate === 'string' ? DateTime.fromISO(clampDate) : clampDate;
  if ((clampMode === 'earliest' && dt < clampDt) || (clampMode === 'latest' && clampDt < dt)) {
    return clampDt;
  }

  return dt;
};

export const formatMonthWithYear = (date: DateTime) =>
  date.toLocaleString({
    month: 'long',
    year: 'numeric',
  });

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const YEAR = DAY * 365;

/** i.e. '3m', '4h', '5d', '2y' */
export const formatRelativeTimeShort = (date: DateTime) => {
  const secondsAgo = Math.abs(date.diffNow('seconds').seconds);

  if (secondsAgo < MINUTE) {
    return `${Math.floor(secondsAgo)}s`;
  } else if (secondsAgo < HOUR) {
    return `${Math.floor(secondsAgo / MINUTE)}m`;
  } else if (secondsAgo < DAY) {
    return `${Math.floor(secondsAgo / HOUR)}h`;
  } else if (secondsAgo < YEAR) {
    return `${Math.floor(secondsAgo / DAY)}d`;
  } else {
    return `${Math.floor(secondsAgo / YEAR)}y`;
  }
};

export const formatRelativeTime = (date: DateTime) => {
  const secondsAgo = Math.abs(date.diffNow('seconds').seconds);
  let time: number | null = null;

  if (secondsAgo < MINUTE) {
    return 'less than a minute';
  } else if (secondsAgo < HOUR) {
    time = Math.floor(secondsAgo / MINUTE);
    return `${time} ${pluralize('minute', time)}`;
  } else if (secondsAgo < DAY) {
    time = Math.floor(secondsAgo / HOUR);
    return `${time} ${pluralize('hour', time)}`;
  } else if (secondsAgo < YEAR) {
    time = Math.floor(secondsAgo / DAY);
    return `${time} ${pluralize('day', time)}`;
  } else {
    time = Math.floor(secondsAgo / YEAR);
    return `${time} ${pluralize('year', time)}`;
  }
};

export const formatDateRange = (startDate?: ISODate | null, endDate?: ISODate | null) => {
  if (startDate && endDate) {
    if (startDate === endDate) {
      return isoDateToAbbreviatedMonthDayAndYear(startDate);
    }

    return `${isoDateToAbbreviatedMonthDayAndYear(startDate)} – ${isoDateToAbbreviatedMonthDayAndYear(endDate)}`;
  }

  if (startDate && !endDate) {
    return `After ${isoDateToAbbreviatedMonthDayAndYear(startDate)}`;
  }

  if (!startDate && endDate) {
    return `Before ${isoDateToAbbreviatedMonthDayAndYear(endDate)}`;
  }

  return 'All time';
};

export const formatDateRangeShortcut = (startDate: ISODate, endDate: ISODate) => {
  if (startDate === endDate) {
    return isoDateToAbbreviatedMonthDayAndYear(startDate);
  }

  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);

  // Check for full year
  if (
    startDate === start.startOf('year').toISODate() &&
    endDate === end.endOf('year').toISODate()
  ) {
    if (start.year === end.year) {
      return start.year.toString();
    }
    return `${start.year} – ${end.year}`;
  }

  // Check for full quarters
  if (
    startDate === start.startOf('quarter').toISODate() &&
    endDate === end.endOf('quarter').toISODate()
  ) {
    const startQuarter = getQuarterForDate(start);
    const endQuarter = getQuarterForDate(end);

    if (start.year === end.year && startQuarter === endQuarter) {
      return `Q${startQuarter} ${start.year}`;
    }

    if (start.year === end.year) {
      return `Q${startQuarter} – Q${endQuarter} ${start.year}`;
    }

    return `Q${startQuarter} ${start.year} – Q${endQuarter} ${end.year}`;
  }

  // Check for full months
  if (
    startDate === start.startOf('month').toISODate() &&
    endDate === end.endOf('month').toISODate()
  ) {
    if (start.year === end.year) {
      if (start.month === end.month) {
        return isoDateToMonthAndYear(start.toISODate());
      }
      return `${start.toFormat('MMMM')} – ${isoDateToMonthAndYear(end.toISODate())}`;
    }
    return `${isoDateToMonthAndYear(start.toISODate())} – ${isoDateToMonthAndYear(end.toISODate())}`;
  }

  // Default to complete date range (for same year)
  if (start.year === end.year) {
    if (start.month === end.month) {
      return `${start.toFormat('MMM d')} – ${end.toFormat('d')}, ${end.year}`;
    }
    return `${start.toFormat('MMM d')} – ${end.toFormat('MMM d')}, ${end.year}`;
  }

  // Different years, show full dates
  return `${isoDateToAbbreviatedMonthDayAndYear(startDate)} – ${isoDateToAbbreviatedMonthDayAndYear(endDate)}`;
};

/**
 * Formats a date range with a relative timeframe.
 *
 * This function contains complex logic, with some opinionated choices.
 * For example, days as timeframes have many special cases and are assumed to
 * be inclusive of the anchor day. All other timeframes are considered exclusive
 * of the current period by default, unless explicitly told to include it.
 *
 * It's easier to interpret the expected outputs by looking at the tests.
 */
export const formatDateRangeWithRelativeTimeframe = (
  startDate?: ISODate | null,
  endDate?: ISODate | null,
  timeframeUnit?: Timeframe | null,
  timeframeValue?: number | null,
  includeCurrentPeriod?: boolean | null,
) => {
  if (startDate && endDate) {
    return formatDateRangeShortcut(startDate, endDate);
  }

  let inclusiveSuffix = '';
  if (includeCurrentPeriod) {
    if (timeframeUnit !== 'day') {
      inclusiveSuffix = ' (inclusive)';
    }
  } else if (timeframeUnit === 'day') {
    inclusiveSuffix = ` (${startDate || endDate ? 'exclusive' : 'excluding today'})`;
  }

  const pluralizedUnit = `${pluralize(timeframeUnit ?? '', timeframeValue ?? 0)}`;
  const unitPhrase = `${timeframeValue === 1 ? '' : `${timeframeValue} `}${pluralizedUnit}`;
  const unitPhraseCapitalized = upperFirst(unitPhrase);

  if (startDate && !endDate) {
    if (timeframeUnit && timeframeValue) {
      return `${unitPhraseCapitalized} after ${isoDateToAbbreviatedMonthDayAndYear(startDate)}${inclusiveSuffix}`;
    }

    return `After ${isoDateToAbbreviatedMonthDayAndYear(startDate)}`;
  }

  if (!startDate && endDate) {
    if (timeframeUnit && timeframeValue) {
      return `${unitPhraseCapitalized} before ${isoDateToAbbreviatedMonthDayAndYear(endDate)}${inclusiveSuffix}`;
    }

    return `Before ${isoDateToAbbreviatedMonthDayAndYear(endDate)}`;
  }

  if (timeframeUnit && timeframeValue) {
    if (timeframeValue === 1) {
      if (includeCurrentPeriod) {
        if (timeframeUnit === 'day') {
          return `Today`;
        }
        return `This ${unitPhrase}`;
      }

      if (timeframeUnit === 'day') {
        return `Yesterday`;
      }
    }
    return `Last ${unitPhrase}${inclusiveSuffix}`;
  }

  return 'All time';
};

/**
 * Given an optional start date, an optional end date, and an optional timeframe period (comprised
 * of a unit, value, and flag for whether to include the current period), resolve the actual start
 * and end dates based on the timeframe.
 *
 * NOTE: CHANGES TO THIS FUNCTION MUST BE REFLECTED IN THE API REPO AS WELL (`monarch/lib/dates.py`)
 *
 * When a start date is provided:
 * - If including the current period is not allowed, this resolves to the next X complete timeframe
 *   periods.
 * - If including the current period is allowed, this anchors to the start date (in the current
 *   partial period) and includes the next X-1 complete timeframe periods.
 *
 * When an end date is provided (or defaulted to today):
 * - If including the current period is not allowed, this resolves to the previous X complete
 *   timeframe periods.
 * - If including the current period is allowed, this anchors to the end date (in the current
 *   partial period) and includes the previous X-1 complete timeframe periods.
 */
export const resolveDateRangeWithRelativeTimeframe = (
  startDate?: ISODate | null,
  endDate?: ISODate | null,
  timeframeUnit?: Timeframe | null,
  timeframeValue?: number | null,
  includeCurrentPeriod?: boolean | null,
): { startDate?: ISODate | null; endDate?: ISODate | null } => {
  // If both dates provided, return as is
  if (startDate && endDate) {
    if (timeframeUnit && timeframeValue) {
      throw new Error('Cannot provide both start and end dates, and also a timeframe period.');
    }
    return { startDate, endDate };
  }

  // If missing timeframe info, return dates as is
  if (!timeframeUnit || !timeframeValue) {
    return { startDate, endDate };
  }

  const anchorStartDate = startDate ? DateTime.fromISO(startDate) : null;
  const anchorEndDate = endDate ? DateTime.fromISO(endDate) : DateTime.local();

  let resolvedStartDate: DateTime | undefined;
  let resolvedEndDate: DateTime | undefined;

  // Handle when an end date is provided, implicitly or explicitly (i.e., look backward)
  if (!anchorStartDate) {
    let periodsToLookBack = timeframeValue;

    const currentPeriodStart = anchorEndDate.startOf(timeframeUnit);

    const previousPeriodStart = currentPeriodStart.minus({ [timeframeUnit]: 1 });
    const previousPeriodEnd = previousPeriodStart.endOf(timeframeUnit);

    // If including current period, look back one less period
    if (includeCurrentPeriod) {
      periodsToLookBack -= 1;
    }

    // Get start of the very first period after looking backwards
    const firstPeriodStart = previousPeriodStart.minus({
      [timeframeUnit]: periodsToLookBack - 1,
    });

    resolvedStartDate = firstPeriodStart;
    resolvedEndDate = previousPeriodEnd;

    // If including current period, anchor to provided end date
    if (includeCurrentPeriod) {
      resolvedEndDate = anchorEndDate;
    }
  }
  // Handle when a start date is provided (i.e., look forward)
  else {
    let periodsToLookForward = timeframeValue;

    const currentPeriodStart = anchorStartDate.startOf(timeframeUnit);

    const nextPeriodStart = currentPeriodStart.plus({ [timeframeUnit]: 1 });

    // If including current period, look forward one less period
    if (includeCurrentPeriod) {
      periodsToLookForward -= 1;
    }

    // Get end of last period after looking forwards
    const lastPeriodStart = nextPeriodStart.plus({
      [timeframeUnit]: periodsToLookForward - 1,
    });
    const lastPeriodEnd = lastPeriodStart.endOf(timeframeUnit);

    resolvedStartDate = nextPeriodStart;
    resolvedEndDate = lastPeriodEnd;

    // If including current period, anchor to provided start date
    if (includeCurrentPeriod) {
      resolvedStartDate = anchorStartDate;
    }
  }

  return {
    startDate: resolvedStartDate?.toISODate(),
    endDate: resolvedEndDate?.toISODate(),
  };
};
