import React, { useMemo } from 'react';
import styled from 'styled-components';

import OwnerRestrictedAccessMessage, {
  OwnerRestrictedAccessMessageVariant,
} from 'common/components/settings/OwnerRestrictedAccessMessage';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import Modal from 'components/lib/ui/Modal';
import type { Props as ModalProps } from 'components/lib/ui/Modal';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { color } from 'common/lib/theme/dynamic';

type Props = {
  onClose: ModalProps['onClose'];
  ownerName: string;
  variant: OwnerRestrictedAccessMessageVariant;
};

const titleByVariant = {
  [OwnerRestrictedAccessMessageVariant.AddMember]: 'Add member to household',
  [OwnerRestrictedAccessMessageVariant.RemoveMember]: 'Remove member from household',
  [OwnerRestrictedAccessMessageVariant.RemoveInvite]: 'Remove invitation to household',
};

const StyledCardBody = styled(CardBody)`
  color: ${color.textLight};
`;

const StyledStrong = styled.strong`
  color: ${color.text};
`;

const OnwerRestrictedActionModal = ({ onClose, ownerName, variant }: Props) => {
  const ownerNameEl = useMemo(() => <StyledStrong>{ownerName}</StyledStrong>, [ownerName]);

  return (
    <Modal onClose={onClose}>
      <ModalCard title={titleByVariant[variant]}>
        <StyledCardBody>
          <OwnerRestrictedAccessMessage
            ownerNameEl={ownerNameEl}
            TextComponent={({ children }) => <div>{children}</div>}
            variant={variant}
          />
        </StyledCardBody>

        <CardFooter>
          <PrimaryButton onClick={onClose}>Okay</PrimaryButton>
        </CardFooter>
      </ModalCard>
    </Modal>
  );
};

export default OnwerRestrictedActionModal;
