import { gql } from '@apollo/client';
import * as React from 'react';
import styled from 'styled-components';

import InvestmentDashboardWidgetTopMoverRow from 'components/investments/InvestmentsDashboardWidgetTopMoverRow';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import Empty from 'components/lib/ui/Empty';
import SectionHeader from 'components/lib/ui/SectionHeader';
import TimeframeTrendIndicator from 'components/lib/ui/TimeframeTrendIndicator';

import { getInvestmentsDashboardWidgetTitle } from 'common/lib/dashboard';
import { fontSize } from 'common/lib/theme/dynamic';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';

import routes from 'constants/routes';

import type { Web_GetInvestmentsDashboardCard } from 'common/generated/graphQlTypes/Web_GetInvestmentsDashboardCard';

const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  font-size: ${fontSize.base};

  span {
    text-transform: initial;
    display: inline-block;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

const InvestmentsDashboardWidget = () => {
  const { data, isLoadingInitialData } =
    useMockDataWhenNoAccountsQuery<Web_GetInvestmentsDashboardCard>(GET_TOP_MOVERS_QUERY, {});

  const topMovers = data?.portfolio.performance.topMovers ?? [];
  const value = data?.portfolio.performance.totalValue;
  const oneDayChange = data?.portfolio.performance.oneDayChangeDollars;

  const startAmount = (value ?? 0) - (oneDayChange ?? 0);
  const currentAmount = value ?? 0;
  const timeframeText = 'Today';

  return (
    <DashboardWidget
      title={getInvestmentsDashboardWidgetTitle({
        currentAmount,
        isLoading: isLoadingInitialData,
      })}
      headerLink={routes.investments()}
      description={
        !isLoadingInitialData && (
          <StyledTimeframeTrendIndicator
            startAmount={startAmount}
            endAmount={currentAmount}
            timeframeDisplay={timeframeText}
          />
        )
      }
      showHeaderBorder
      loading={isLoadingInitialData}
    >
      <SectionHeader>Top movers today</SectionHeader>
      {topMovers.length !== 0 ? (
        topMovers.map(({ ticker, name, currentPrice, oneDayChangePercent }) => (
          <InvestmentDashboardWidgetTopMoverRow
            key={ticker}
            ticker={ticker ?? ''}
            securityName={name}
            currentPriceDollars={currentPrice ?? 0}
            returnPercent={oneDayChangePercent}
          />
        ))
      ) : (
        <Empty
          title="No investment holdings with known securities"
          subtitle="Please sync another investment account to see top movers"
        />
      )}
    </DashboardWidget>
  );
};

const GET_TOP_MOVERS_QUERY = gql`
  query Web_GetInvestmentsDashboardCard {
    portfolio {
      performance {
        totalValue
        oneDayChangeDollars
        topMovers {
          id
          name
          ticker
          oneDayChangePercent
          currentPrice
        }
      }
    }
  }
`;

export default InvestmentsDashboardWidget;
