import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import AccountSummary from 'components/accounts/transferAccountData/AccountSummary';
import RadioGroup from 'components/lib/form/RadioGroup';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import SingleDatePicker from 'components/lib/ui/SingleDatePicker';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import { formatCurrency } from 'common/utils/Currency';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import useDataTransferAccounts from 'lib/hooks/accounts/useDataTransferAccounts';
import usePreviewAccountDataTransferTransactions from 'lib/hooks/accounts/usePreviewAccountDataTransferTransactions';
import useRecommendedDataTransferDates from 'lib/hooks/accounts/useRecommendedDataTransferDates';

import type {
  Web_GetAccountsForTransferQuery,
  Web_PreviewAccountDataTransferTransactionsQuery,
} from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

type Transaction = ElementOf<
  Web_PreviewAccountDataTransferTransactionsQuery['previewAccountDataTransferTransactions']['fromAccountSummary'],
  'transactionsInWindow'
>;

type TransactionsByDate = {
  [date: string]: Transaction[];
};

const transactionsByDate = (transactions: Transaction[]): TransactionsByDate =>
  transactions.reduce((acc: TransactionsByDate, transaction) => {
    const { date } = transaction;
    return {
      ...acc,
      [date]: [...(acc[date] || []), transaction],
    };
  }, {});

type StepProps = {
  fromAccountId: string;
  toAccountId: string;
};

type NextStepProps = StepProps & {
  transferTransactions: boolean;
  startDateTransactions: string | null;
  endDateTransactions: string | null;
  fromAccountExpectedAffectedTransactionCount: number;
  toAccountExpectedAffectedTransactionCount: number;
  toAccountNewerTransactionCount: number;
};

type Props = {
  next: (params: NextStepProps) => void;
  goBack?: () => void;
} & StepProps;

const Transactions = ({ fromAccountId, toAccountId, next, goBack }: Props) => {
  const [transferTransactions, setTransferTransactions] = useState(true);
  const [endDate, setEndDate] = useState<string | null>(null);
  const { data: recommendedDataTransferDates, loading: loadingRecommendedDataTransferDates } =
    useRecommendedDataTransferDates(fromAccountId, toAccountId);
  const { data: accountsForTransfer, loading: loadingAccountsForTransfer } =
    useDataTransferAccounts(fromAccountId, toAccountId);

  useEffect(() => {
    if (recommendedDataTransferDates?.transactionsEndDate) {
      setEndDate(recommendedDataTransferDates?.transactionsEndDate);
    }
  }, [recommendedDataTransferDates?.transactionsEndDate]);

  const {
    data: previewAccountDataTransferTransactions,
    loading: loadingPreviewAccountDataTransferTransactions,
  } = usePreviewAccountDataTransferTransactions(
    fromAccountId,
    toAccountId,
    recommendedDataTransferDates?.transactionsStartDate,
    endDate,
  );

  return (
    <TransferAccountDataStep
      progress={1}
      onClickBack={goBack}
      overrideTitle="Transfer transactions"
      isLoading={loadingRecommendedDataTransferDates}
      footer={
        <PrimaryButton
          size="large"
          onClick={() =>
            next({
              fromAccountId,
              toAccountId,
              transferTransactions,
              startDateTransactions: recommendedDataTransferDates?.transactionsStartDate ?? null,
              endDateTransactions: endDate,
              fromAccountExpectedAffectedTransactionCount:
                previewAccountDataTransferTransactions?.fromAccountSummary.numTransactions ?? 0,
              toAccountExpectedAffectedTransactionCount:
                previewAccountDataTransferTransactions?.toAccountSummary.numTransactions ?? 0,
              toAccountNewerTransactionCount:
                previewAccountDataTransferTransactions?.toAccountSummary.numNewerTransactions ?? 0,
            })
          }
        >
          Next up: Balances
        </PrimaryButton>
      }
    >
      <FlexContainer center column marginTop="xxlarge" gap="large">
        <RadioGroup
          value={transferTransactions ? 'transferTransactions' : 'skipTransactions'}
          onChange={(value) => setTransferTransactions(value === 'transferTransactions')}
          options={[
            {
              value: 'transferTransactions',
              label: 'Move transactions to your new account',
              description:
                'You can choose which day you want transactions (including updated categories, notes, attachments, and splits) to move from your old account to your new account.',
            },
            {
              value: 'skipTransactions',
              label: 'Don’t move transactions',
              description:
                'If you prefer not to transfer transactions from your old account to your new one, you can move to the next step for transferring balances.',
            },
          ]}
        />
        {transferTransactions && (
          <>
            <TransactionsDatePicker
              recommendedDate={recommendedDataTransferDates?.transactionsEndDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <TransactionsPreview
              isLoading={
                loadingPreviewAccountDataTransferTransactions || loadingAccountsForTransfer
              }
              fromAccount={accountsForTransfer?.fromAccount}
              toAccount={accountsForTransfer?.toAccount}
              fromAccountTransactions={
                previewAccountDataTransferTransactions?.fromAccountSummary.transactionsInWindow ??
                []
              }
              toAccountTransactions={
                previewAccountDataTransferTransactions?.toAccountSummary.transactionsInWindow ?? []
              }
            />
          </>
        )}
      </FlexContainer>
    </TransferAccountDataStep>
  );
};

const TransactionsDatePicker = ({
  recommendedDate,
  endDate,
  setEndDate,
}: {
  recommendedDate: string | null | undefined;
  endDate: string | null;
  setEndDate: (value: string | null) => void;
}) => {
  if (!recommendedDate) {
    return null;
  }

  return (
    <>
      <Text>Transition date</Text>
      <SingleDatePicker
        date={endDate}
        onDateChange={(value) => {
          setEndDate(value);
        }}
      />
      <Text>
        We recommend the date of the last transaction on your old account:{' '}
        {isoDateToAbbreviatedMonthDayAndYear(recommendedDate)}
      </Text>
    </>
  );
};

const AccountColumn = styled(FlexContainer).attrs({ column: true })`
  width: 50%;
`;

const TransactionsPreview = ({
  isLoading,
  fromAccountTransactions,
  toAccountTransactions,
  fromAccount,
  toAccount,
}: {
  isLoading: boolean;
  fromAccountTransactions: Transaction[];
  toAccountTransactions: Transaction[];
  fromAccount: Web_GetAccountsForTransferQuery['fromAccount'];
  toAccount: Web_GetAccountsForTransferQuery['toAccount'];
}) => {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  const fromAccountTransactionsByDate = transactionsByDate(fromAccountTransactions);
  const toAccountTransactionsByDate = transactionsByDate(toAccountTransactions);

  return (
    <FlexContainer full gap="gutter">
      <AccountColumn>
        <FlexContainer>
          <AccountSummary
            icon={fromAccount?.logoUrl}
            logoUrl={fromAccount?.logoUrl}
            label={fromAccount?.displayName}
            lastUpdatedAt={fromAccount?.createdAt}
            dataProvider={fromAccount?.dataProvider}
            institution={fromAccount?.institution}
            syncDisabled={fromAccount?.syncDisabled}
          />
        </FlexContainer>
        {Object.entries(fromAccountTransactionsByDate).map(([date, transactions]) => (
          <div key={date} style={{ marginBottom: '1rem' }}>
            <Text weight="medium">{date}</Text>
            {transactions.map((transaction) => (
              <div key={transaction.id}>
                <Text>
                  {transaction.merchant?.name} {formatCurrency(transaction.amount)}
                </Text>
              </div>
            ))}
          </div>
        ))}
      </AccountColumn>
      <AccountColumn>
        <FlexContainer>
          <AccountSummary
            icon={toAccount?.logoUrl}
            logoUrl={toAccount?.logoUrl}
            label={toAccount?.displayName}
            lastUpdatedAt={toAccount?.createdAt}
            dataProvider={toAccount?.dataProvider}
            institution={toAccount?.institution}
            syncDisabled={toAccount?.syncDisabled}
          />
        </FlexContainer>
        {Object.entries(toAccountTransactionsByDate).map(([date, transactions]) => (
          <div key={date} style={{ marginBottom: '1rem' }}>
            <Text weight="medium">{date}</Text>
            {transactions.map((transaction) => (
              <div key={transaction.id}>
                <Text>
                  {transaction.merchant?.name} {formatCurrency(transaction.amount)}
                </Text>
              </div>
            ))}
          </div>
        ))}
      </AccountColumn>
    </FlexContainer>
  );
};

export default Transactions;
