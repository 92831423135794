import React from 'react';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import LoggedOutCardPage from 'components/lib/layouts/LoggedOutCardPage';

import { LOGIN } from 'common/constants/copy';

type Props = {
  onSubmit: ({ email_otp }: { email_otp: string }) => void;
};

const EmailOTPLogin = ({ onSubmit }: Props) => (
  <LoggedOutCardPage
    displayTitle={LOGIN.EMAIL_OTP.TITLE}
    description={LOGIN.EMAIL_OTP.SUBTITLE}
    name="Email OTP Code"
  >
    <Form initialValues={{ email_otp: '' }} onSubmit={onSubmit}>
      <TextField
        name="email_otp"
        autoComplete="off"
        placeholder="6-digit code"
        label="Code"
        required
        autoFocus
        maxLength={6}
        minLength={6}
      />
      <FormSubmitButton>Verify</FormSubmitButton>
    </Form>
  </LoggedOutCardPage>
);
export default EmailOTPLogin;
