import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';

const useRecommendedAccounts = (accountId?: string | null) => {
  const { data, isLoadingInitialData, error } = useQuery(GET_RECOMMENDED_ACCOUNTS_QUERY, {
    skip: !accountId,
    variables: { accountId },
  });
  return {
    data: data?.recommendedAccountDataTransferAccounts,
    isLoading: isLoadingInitialData,
    error,
  };
};

const GET_RECOMMENDED_ACCOUNTS_QUERY = gql(/* GraphQL */ `
  query Web_RecommendedAccounts($accountId: ID) {
    recommendedAccountDataTransferAccounts(accountId: $accountId) {
      recommendedAccounts {
        createdAt
        dataProvider
        ...AccountsListFields
      }
      otherAccounts {
        createdAt
        dataProvider
        ...AccountsListFields
      }
      sameSubtypeAccounts {
        createdAt
        dataProvider
        ...AccountsListFields
      }
      unsupportedAccounts {
        account {
          createdAt
          dataProvider
          ...AccountsListFields
        }
        reason
      }
    }
  }
`);

export default useRecommendedAccounts;
