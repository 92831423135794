import React from 'react';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

type StepProps = {
  fromAccountId: string;
  toAccountId: string;
  transferTransactions: boolean;
  startDateTransactions: string | null;
  endDateTransactions: string | null;
  fromAccountExpectedAffectedTransactionCount: number;
  toAccountExpectedAffectedTransactionCount: number;
  toAccountNewerTransactionCount: number;
  transferBalanceSnapshots: boolean;
  startDateBalanceSnapshots: string | null;
  endDateBalanceSnapshots: string | null;
  expectedAffectedBalanceSnapshotCount: number;
};

type Props = {
  next: (params: StepProps) => void;
  goBack?: () => void;
} & StepProps;

const Backup = ({ next, goBack, ...nextFlowStepProps }: Props) => (
  <TransferAccountDataStep
    overrideTitle="Backup"
    progress={3}
    onClickBack={goBack}
    footer={
      <PrimaryButton size="large" onClick={() => next(nextFlowStepProps)}>
        Next up: Review
      </PrimaryButton>
    }
  >
    <FlexContainer center column marginTop="xxlarge" gap="large">
      <Text size="xlarge" weight="bold">
        Data backup
      </Text>
    </FlexContainer>
  </TransferAccountDataStep>
);

export default Backup;
