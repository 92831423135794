import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import Banner from 'components/lib/ui/Banner';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DangerButton from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import TransactionFiltersOverview from 'components/transactions/TransactionFiltersOverview';

import { spacing } from 'common/lib/theme/dynamic';
import { useFlowContext } from 'lib/contexts/FlowContext';
import { areFiltersEquivalent } from 'lib/filters/utils';
import useTrack from 'lib/hooks/useTrack';
import { getReportFiltersForTransactionFilterSet } from 'lib/reports';
import { convertFiltersToInput } from 'lib/transactions/Filters';

import { ReportsEventNames } from 'common/constants/analytics';

import type { ReportConfiguration } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

const StyledCardBody = styled(CardBody)`
  gap: ${spacing.xlarge};
`;

const StyledTextField = styled(TextField)`
  :not(:last-child) {
    margin-bottom: 0;
  }
`;

const BannerText = styled(Text)`
  display: block;

  :not(:last-child) {
    margin-bottom: ${spacing.xsmall};
  }
`;

const SimilarReportConfigurationBanner = ({ reportName }: { reportName: string }) => (
  <Banner type="error">
    <BannerText>
      Your report <strong>{reportName}</strong> has the same configuration as the report you&apos;re
      trying to create.
    </BannerText>
    <BannerText>Change the date range or selected filters to save a new report.</BannerText>
  </Banner>
);

const SameReportNameBanner = () => (
  <Banner type="error">
    <BannerText>
      This report uses the same name as another report that already exists. Change this
      report&apos;s name to a different name to save this report.
    </BannerText>
  </Banner>
);

type BaseProps = {
  filters: Partial<TransactionFilters>;
  reportConfigurations: ReportConfiguration[];
  onDeleteReportConfiguration: (reportConfiguration: ReportConfiguration) => void;
};

type SaveReportConfigurationInnerFormProps = BaseProps & {
  editingReportConfiguration: ReportConfiguration | undefined;
};

const SaveReportConfigurationInnerForm = ({
  filters,
  reportConfigurations,
  editingReportConfiguration,
  onDeleteReportConfiguration,
}: SaveReportConfigurationInnerFormProps) => {
  const { next, skipToComplete } = useFlowContext();
  const { values, isSubmitting } = useFormContext<{ displayName: string }>();

  // We only care about "similar" report configurations when creating a new one
  const similarReportConfiguration = useMemo(
    () =>
      !editingReportConfiguration
        ? reportConfigurations.find((reportConfiguration: ReportConfiguration) =>
            areFiltersEquivalent(
              convertFiltersToInput(filters),
              reportConfiguration.transactionFilterSet,
            ),
          )
        : undefined,
    [editingReportConfiguration, reportConfigurations, filters],
  );

  // We only care about the same report name when creating a new one, not when editing
  const hasSameReportName =
    !editingReportConfiguration &&
    reportConfigurations.some(
      (reportConfiguration: ReportConfiguration) =>
        reportConfiguration.displayName === (values.displayName?.trim() ?? ''),
    );

  const displayedFilters = useMemo(
    () =>
      editingReportConfiguration
        ? getReportFiltersForTransactionFilterSet(editingReportConfiguration.transactionFilterSet)
        : filters,
    [editingReportConfiguration, filters],
  );

  const handleDelete = () => {
    next({
      reportName: editingReportConfiguration?.displayName,
      onDelete: () => {
        if (editingReportConfiguration) {
          onDeleteReportConfiguration(editingReportConfiguration);
        }
      },
    });
  };

  return (
    <>
      <StyledCardBody>
        {!!similarReportConfiguration && (
          <SimilarReportConfigurationBanner reportName={similarReportConfiguration.displayName} />
        )}
        {hasSameReportName && <SameReportNameBanner />}
        <StyledTextField name="displayName" label="Report name" placeholder="Enter report name" />
        <TransactionFiltersOverview filters={displayedFilters} />
      </StyledCardBody>
      <CardFooter justifyEnd>
        {editingReportConfiguration && <DangerButton onClick={handleDelete}>Delete</DangerButton>}
        <DefaultButton onClick={skipToComplete}>Cancel</DefaultButton>
        <FormSubmitButton
          size="small"
          pending={isSubmitting}
          disabled={!!similarReportConfiguration}
        >
          Save
        </FormSubmitButton>
      </CardFooter>
    </>
  );
};

export type SaveReportConfigurationModalProps = BaseProps & {
  editingReportConfigurationId?: Maybe<string>;
  reportConfigurations: ReportConfiguration[];
  onSubmit: (values: { displayName: string }) => void;
};

const SaveReportConfigurationModal = ({
  filters,
  editingReportConfigurationId,
  reportConfigurations,
  onDeleteReportConfiguration,
  onSubmit,
}: SaveReportConfigurationModalProps) => {
  useTrack(ReportsEventNames.ReportConfigurationCreationStarted);

  const { skipToComplete } = useFlowContext();

  const editingReportConfiguration = useMemo(
    () =>
      reportConfigurations.find(
        (reportConfiguration: ReportConfiguration) =>
          reportConfiguration.id === editingReportConfigurationId,
      ),
    [reportConfigurations, editingReportConfigurationId],
  );

  return (
    <ModalCard title={`${editingReportConfigurationId ? 'Edit' : 'Create'} saved report`}>
      <Form
        initialValues={{ displayName: editingReportConfiguration?.displayName }}
        onSubmit={onSubmit}
        onSubmitSuccess={skipToComplete}
      >
        <SaveReportConfigurationInnerForm
          filters={filters}
          reportConfigurations={reportConfigurations}
          editingReportConfiguration={editingReportConfiguration}
          onDeleteReportConfiguration={onDeleteReportConfiguration}
        />
      </Form>
    </ModalCard>
  );
};

export default SaveReportConfigurationModal;
