import { useMutation } from '@apollo/client';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SettingsCard from 'components/lib/layouts/SettingsCard';
import RouteModal from 'components/lib/routing/RouteModal';
import Column from 'components/lib/ui/Column';
import Confirmation from 'components/lib/ui/Confirmation';
import DragDropList from 'components/lib/ui/DragDropList';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import Empty, { Theme as EmptyTheme } from 'components/lib/ui/Empty';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import SearchBar from 'components/lib/ui/SearchBar';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import { OverlayTrigger } from 'components/lib/ui/popover';
import PremiumFeatureLimitOverlayTrigger from 'components/premium/PremiumFeatureLimitOverlayTrigger';
import TransactionRule from 'components/transactions/TransactionRule';
import TransactionRuleModal from 'components/transactions/TransactionRuleModal';

import { getOptimisticItemOrderForMove } from 'common/lib/dnd/DragAndDrop';
import useFeatureEntitlementLimit from 'common/lib/hooks/premium/useFeatureEntitlementLimit';
import useQuery from 'common/lib/hooks/useQuery';
import { getFilteredRulesForSearch, getSortedRules } from 'common/lib/transactions/Rules';
import useModal from 'lib/hooks/useModal';
import { useQueryParam } from 'lib/hooks/useQueryParams';
import useTheme from 'lib/hooks/useTheme';
import useToast from 'lib/hooks/useToast';

import { ProductFeatureLimit } from 'common/constants/premium';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const Description = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.default};
`;

const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const RulesSettings = () => {
  const history = useHistory();
  const editingRuleId = useQueryParam('id');
  const theme = useTheme();
  const { openErrorToast } = useToast();

  const [search, setSearch] = useState('');

  const { data, isLoadingInitialData, refetch } = useQuery(GET_RULES);
  const { transactionRules = [] } = data ?? {};

  const rules = useMemo(() => {
    const sorted = getSortedRules(transactionRules);
    if (search) {
      return getFilteredRulesForSearch(search)(sorted);
    } else {
      return sorted;
    }
  }, [transactionRules, search]);

  const ruleCount = rules.length;
  const hasRules = ruleCount > 0;

  const { limit } = useFeatureEntitlementLimit(ProductFeatureLimit.TransactionRuleLimit, ruleCount);

  const [updateOrder] = useMutation(UPDATE_ORDER_MUTATION);
  const [deleteAllTransactionRules, { loading: deleteAllRulesLoading }] = useMutation(
    DELETE_ALL_TRANSACTION_RULES,
  );

  const [ConfirmDeleteAllModal, { open: openConfirmDeleteAll, close: closeConfirmDeleteAll }] =
    useModal();

  const openModal = useCallback(
    (id = '') =>
      history.push(
        routes.settings.rules.transactionRule({
          queryParams: { id },
        }),
      ),
    [history],
  );

  return (
    <Column md={9}>
      <SettingsCard
        title="Rules"
        controls={
          <OverlayTrigger
            placement="bottom-end"
            overlay={
              <DropdownMenu>
                <DropdownMenuItem type="danger" disabled={!hasRules} onClick={openConfirmDeleteAll}>
                  Delete all rules
                </DropdownMenuItem>
              </DropdownMenu>
            }
          >
            {({ toggleOpen, isOpen }) => (
              <DefaultButton onClick={toggleOpen} active={isOpen}>
                <span>Options</span>
                <ButtonIcon name="chevron-down" />
              </DefaultButton>
            )}
          </OverlayTrigger>
        }
      >
        <Helmet>
          <title>Rules Settings</title>
        </Helmet>
        <CardBody>
          <Description>
            Rules allow you to rename, recategorize, split your transactions, and more. If multiple
            rules apply to the same transaction, they will apply in the order listed here (top to
            bottom). You can drag and drop to edit the order as needed.
          </Description>
          <ListHeader>
            <span>
              {limit
                ? `${Math.min(limit, ruleCount)} of ${pluralize('Rule', limit, true)}`
                : pluralize('Rule', ruleCount, true)}
            </span>
            <HeaderActions>
              <SearchBar
                placeholder="Search"
                name="search-rules"
                autoComplete="off"
                value={search}
                onChange={setSearch}
              />
              <PremiumFeatureLimitOverlayTrigger
                featureLimit={ProductFeatureLimit.TransactionRuleLimit}
                currentValue={ruleCount}
              >
                {({ meetsOrExceedsLimit }) => (
                  <PrimaryButton size="small" onClick={() => !meetsOrExceedsLimit && openModal()}>
                    Create rule
                  </PrimaryButton>
                )}
              </PremiumFeatureLimitOverlayTrigger>
            </HeaderActions>
          </ListHeader>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoadingInitialData ? (
            <EmptyList>
              <LoadingSpinner />
            </EmptyList>
          ) : ruleCount === 0 ? (
            <EmptyList>
              <Empty
                emptyTheme={EmptyTheme.OLD}
                title={!search ? 'No rules yet' : 'No rules found'}
                subtitle={!search ? 'Create some to get started.' : 'Try a different search term.'}
              />
            </EmptyList>
          ) : (
            <DragDropList
              items={rules}
              renderItem={(rule) => (
                <TransactionRule rule={rule} onClick={() => openModal(rule.id)} />
              )}
              keyExtractor={({ id }) => id}
              indexExtractor={({ order }) => order}
              interItemSpacing={theme.spacing.default}
              onMoveItem={({ item: rule, destination, source }) =>
                updateOrder({
                  variables: {
                    id: rule.id,
                    order: destination,
                  },
                  optimisticResponse: {
                    __typename: 'Mutation',
                    updateTransactionRuleOrderV2: {
                      __typename: 'UpdateTransactionRuleOrderV2Mutation',
                      transactionRules: [
                        {
                          ...rule,
                          order: getOptimisticItemOrderForMove(source, destination),
                        },
                      ],
                    },
                  },
                })
              }
            />
          )}
        </CardBody>

        <RouteModal path={routes.settings.rules.transactionRule.path} full large>
          {({ close }) => (
            <TransactionRuleModal
              editRule={editingRuleId ? rules.find(({ id }) => id === editingRuleId) : undefined}
              onDone={() => {
                close();
                refetch();
              }}
            />
          )}
        </RouteModal>

        <ConfirmDeleteAllModal>
          <Confirmation
            title={`Delete ${pluralize('rule', ruleCount, true)}`}
            confirm="Delete"
            useDangerButton
            onCancel={closeConfirmDeleteAll}
            onConfirm={() => {
              deleteAllTransactionRules({
                onCompleted: (data) => {
                  if (!data.deleteAllTransactionRules?.deleted) {
                    openErrorToast({
                      description: 'An error occurred while deleting your rules. Please try again.',
                    });
                    return;
                  }

                  closeConfirmDeleteAll();
                  refetch();
                },
                onError: () => {
                  openErrorToast({
                    description: 'An error occurred while deleting your rules. Please try again.',
                  });
                },
              });
            }}
            isLoading={deleteAllRulesLoading}
          >
            <div>
              Are you sure you want to permanently delete all of your transaction rules? This action
              cannot be undone. This action will not change any of your existing transactions.
            </div>
          </Confirmation>
        </ConfirmDeleteAllModal>
      </SettingsCard>
    </Column>
  );
};

const GET_RULES = gql(`
  query GetTransactionRules {
    transactionRules {
      id
      order
      ...TransactionRuleFields
    }
  }
`);

const UPDATE_ORDER_MUTATION = gql(`
  mutation Web_UpdateRuleOrderMutation($id: ID!, $order: Int!) {
    updateTransactionRuleOrderV2(id: $id, order: $order) {
      transactionRules {
        id
        order
        ...TransactionRuleFields
      }
    }
  }
`);

const DELETE_ALL_TRANSACTION_RULES = gql(`
  mutation Web_DeleteAllTransactionRulesMutation {
    deleteAllTransactionRules {
      deleted
    }
  }
`);

export default RulesSettings;
