import _ from 'lodash';
import type { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import type { ExtendedCategoryGroupType } from 'common/lib/budget/Amounts';
import type { SummaryDisplayBudgetType } from 'common/lib/budget/Summary';
import {
  BUDGET_VARIABILITY_ORDER,
  BUDGET_VARIABILITY_TO_TITLE_MAPPING,
  BUDGET_VARIABILITY_STACK_MAPPING,
  DEFAULT_BUDGET_VARIABILITY_IF_UNSET,
  EXCESS_BUDGET_TYPE,
  SAVINGS_PLAN_SECTION_TYPE,
} from 'common/lib/budget/constants';
import { sortByOrder, sortCategoryGroups } from 'common/lib/categories/Adapters';
import { CATEGORY_GROUP_TYPE_TO_TITLE } from 'common/lib/categories/constants';
import { filterOutArchivedGoals, sortGoalsByPriority } from 'common/lib/goalsV2/adapters';
import type { CurrencyType } from 'common/utils/Currency';
import { isNilOrZero } from 'common/utils/Number';

import type { Common_BudgetDataQuery, Maybe } from 'common/generated/graphql';
import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';
import type { KeyOfThemeProp } from 'common/types/Styles';
import type { ElementOf } from 'common/types/utility';

type CategoryGroup = ElementOf<Common_BudgetDataQuery, 'categoryGroups'>;
type Category = ElementOf<CategoryGroup, 'categories'>;
type BudgetGoal = NonNullable<Common_BudgetDataQuery['goalsV2']>[number];

export type PlanSectionType = CategoryGroupType | typeof SAVINGS_PLAN_SECTION_TYPE;

export type AmountsMap = Record<BudgetVariability, number | undefined>;

export type DisplayBudget = {
  startDate: string | null;
  displayTypes: DisplayBudgetType[];
  hiddenGroupsByType: { [key: string]: DisplayGroup[] };
  goalsSections: GoalsGroup[];
};

export type DisplayBudgetType = {
  title: string | undefined;
  displayGroups: DisplayGroup[];
  type: CategoryGroupType;
};

export type ActiveColumnType = 'actual' | 'remaining';

export type GoalsGroup = DisplayGroup & {
  data: DisplayItem[];
};

export type FlexGroup = Omit<CategoryGroup, 'categories' | '__typename' | 'updatedAt'>;

export type DisplayGroup = {
  id: string;
  type: ExtendedCategoryGroupType;
  budgetVariability: Maybe<BudgetVariability>;
  name: string;
  sectionTitle?: string;
  isSectionEditable?: boolean;
  budgetedAmounts: BudgetAmounts;
  budgetedCategories: DisplayItem[];
  unbudgetedCategories: DisplayItem[];
  unbudgetedAmounts?: BudgetAmounts;
  groupLevelBudgetingEnabled: Maybe<boolean>;
  rolloverPeriod?: CategoryGroup['rolloverPeriod'];
  isGoalsSection?: boolean;
};

export type DisplayItem = {
  id: string;
  name: string;
  icon?: string | undefined;
  order: number;
  budgetVariability: Maybe<BudgetVariability>;
  excludeFromBudget: Maybe<boolean>;
  amounts: BudgetAmounts | undefined;
  rolloverPeriod?: Category['rolloverPeriod'];
  itemType: 'category' | 'category_group' | 'goal';
  groupId: string;
  groupType: ExtendedCategoryGroupType;
  groupBudgetVariability: Maybe<BudgetVariability>;
  groupLevelBudgetingEnabled: Maybe<boolean>;
  goal?: BudgetGoal; // Only used for the Goals section
};

export type BudgetAmounts = {
  budgeted: Maybe<number>;
  actual: Maybe<number>;
  available: Maybe<number>;
  rollover: Maybe<number>;
  rolloverType: Maybe<string>;
};

const EMPTY_BUDGET_AMOUNTS: BudgetAmounts = {
  budgeted: null,
  actual: null,
  available: null,
  rollover: null,
  rolloverType: null,
};

export const sumAllNumericProperties = (x: unknown, y: unknown) =>
  R.mergeWith((a, b) => (_.isNumber(a) || a === null ? (a ?? 0) + (b ?? 0) : a ?? 0), x, y);

export const sumBudgetAmountFields = (amounts: BudgetAmounts[]): BudgetAmounts | undefined => {
  if (!amounts.length) {
    return undefined;
  }
  return amounts.reduce(
    (acc, amounts) => sumAllNumericProperties(acc, amounts),
    {},
  ) as BudgetAmounts;
};

const filterHiddenGroups =
  (hidden: boolean) =>
  ({ budgetedCategories, budgetedAmounts: { actual, budgeted } }: DisplayGroup) => {
    const shouldHide =
      budgetedCategories.length === 0 && (actual ?? 0) === 0 && (budgeted ?? 0) === 0;
    return shouldHide ? hidden : !hidden;
  };

const partitionDisplayGroupsByType = R.groupBy<DisplayGroup>(R.prop('type'));

const extractTruthyAmounts = R.pipe(R.pluck('amounts'), RA.compact);

/**
 * Get the budget variability for a category, taking into account group level budgeting
 */
const getBudgetVariabilityForDisplayItem = ({
  budgetVariability,
  groupBudgetVariability,
  groupLevelBudgetingEnabled,
}: DisplayItem) => {
  const variability = groupLevelBudgetingEnabled ? groupBudgetVariability : budgetVariability;
  return variability ?? DEFAULT_BUDGET_VARIABILITY_IF_UNSET;
};

const getSiblings = (item: DisplayItem, items: DisplayItem[]) => {
  const isSameGroup = R.propEq('groupId', item.groupId);
  const isDifferentItem = RA.propNotEq('id', item.id);
  const isSibling = R.allPass([isSameGroup, isDifferentItem]);
  return items.filter(isSibling);
};

const isBudgetedItem = (displayItem: DisplayItem, isFlexBudgetingEnabled: boolean) => {
  const { amounts } = displayItem;
  const hasActual = !isNilOrZero(amounts?.actual);
  const hasBudget = !isNilOrZero(amounts?.budgeted);
  const hasRollover = RA.isNotNil(amounts?.rolloverType) || !isNilOrZero(amounts?.rollover);

  const budgetVariability = getBudgetVariabilityForDisplayItem(displayItem);
  const isFlexible = isFlexBudgetingEnabled && budgetVariability === BudgetVariability.FLEXIBLE;

  const commonCriteria = hasBudget || hasRollover;
  const flexibleCriteria = commonCriteria || hasActual;

  return isFlexible ? flexibleCriteria : commonCriteria;
};

const hasBudgetedSiblings = (
  item: DisplayItem,
  items: DisplayItem[],
  isFlexBudgetingEnabled: boolean,
) => getSiblings(item, items).find((sibling) => isBudgetedItem(sibling, isFlexBudgetingEnabled));

const partitionByBudgetStatus = (items: DisplayItem[], isFlexBudgetingEnabled: boolean) => {
  const budgetedItems: DisplayItem[] = [];
  const unbudgetedItems: DisplayItem[] = [];

  items.forEach((item) => {
    const isBudgeted = isBudgetedItem(item, isFlexBudgetingEnabled);
    const isGrouped = item.groupLevelBudgetingEnabled;

    if (isBudgeted || (isGrouped && hasBudgetedSiblings(item, items, isFlexBudgetingEnabled))) {
      // eslint-disable-next-line fp/no-mutating-methods
      budgetedItems.push(item);
    } else {
      // eslint-disable-next-line fp/no-mutating-methods
      unbudgetedItems.push(item);
    }
  });

  return [unbudgetedItems, budgetedItems] as const;
};

export const rolloverDisplayBudgetAdapter = (
  data: Common_BudgetDataQuery,
  date: DateTime,
  isFlexBudgetingEnabled = false,
  summaryData: SummaryDisplayBudgetType[],
): DisplayBudget => {
  const { budgetData, categoryGroups } = data;
  const { monthlyAmountsByCategory, monthlyAmountsByCategoryGroup, monthlyAmountsForFlexExpense } =
    budgetData;

  const sortedCategoryGroups = sortCategoryGroups(categoryGroups).filter(
    ({ type }) => type !== CategoryGroupType.TRANSFER,
  );

  const groupedByType = R.groupBy(R.prop('type'), sortedCategoryGroups);

  const getAmountsForCategory = (categoryId: string): BudgetAmounts => {
    const categoryAmounts = monthlyAmountsByCategory.find(R.pathEq(['category', 'id'], categoryId));
    const amounts = categoryAmounts?.monthlyAmounts?.find(R.propEq('month', date.toISODate()));

    return {
      budgeted: amounts?.plannedCashFlowAmount ?? null,
      available: amounts?.remainingAmount ?? null,
      actual: amounts?.actualAmount ?? null,
      rollover: amounts?.previousMonthRolloverAmount ?? null,
      rolloverType: amounts?.rolloverType ?? null,
    };
  };

  const toDisplayItem = (category: Category, group: CategoryGroup): DisplayItem => ({
    id: category.id,
    name: category.name,
    icon: category.icon,
    itemType: 'category',
    order: category.order,
    budgetVariability: category.budgetVariability,
    excludeFromBudget: category.excludeFromBudget,
    amounts: getAmountsForCategory(category.id),
    rolloverPeriod: category.rolloverPeriod,
    groupId: group.id,
    groupType: group.type,
    groupBudgetVariability: group.budgetVariability,
    groupLevelBudgetingEnabled: group.groupLevelBudgetingEnabled,
  });

  const getGroupMonthlyAmounts = (
    group: CategoryGroup | FlexGroup,
    isFlexibleGroup: boolean,
  ): BudgetAmounts | undefined => {
    const groupAmounts = isFlexibleGroup
      ? monthlyAmountsForFlexExpense
      : monthlyAmountsByCategoryGroup.find(R.pathEq(['categoryGroup', 'id'], group.id));

    const amounts = groupAmounts?.monthlyAmounts?.find(R.propEq('month', date.toISODate()));

    if (!amounts) {
      return undefined;
    }

    return {
      budgeted: amounts.plannedCashFlowAmount ?? null,
      available: amounts.remainingAmount ?? null,
      actual: amounts.actualAmount ?? null,
      rollover: amounts?.previousMonthRolloverAmount ?? null,
      rolloverType: amounts?.rolloverType ?? null,
    };
  };

  const getDisplayGroup = (
    group: CategoryGroup | FlexGroup,
    displayItems: DisplayItem[],
  ): DisplayGroup => {
    const extractCategories = R.filter<DisplayItem, 'array'>(R.propEq('itemType', 'category'));
    const extractCategoriesAmounts = R.pipe(extractCategories, extractTruthyAmounts);

    const [unbudgetedItems, budgetedItems] = partitionByBudgetStatus(
      displayItems,
      isFlexBudgetingEnabled,
    );

    const unbudgetedAmounts = sumBudgetAmountFields(extractCategoriesAmounts(unbudgetedItems));
    const isFlexibleGroup =
      group.budgetVariability === BudgetVariability.FLEXIBLE &&
      isFlexBudgetingEnabled &&
      group.type === CategoryGroupType.EXPENSE;
    const groupAmounts = getGroupMonthlyAmounts(group, isFlexibleGroup);
    const useMonthlyAmount = groupAmounts && (isFlexibleGroup || group.groupLevelBudgetingEnabled);

    const budgetedAmounts = useMonthlyAmount
      ? groupAmounts
      : getBudgetedAmountsFromSummary(
          group,
          summaryData,
          isFlexBudgetingEnabled,
          sumBudgetAmountFields(extractCategoriesAmounts(displayItems)),
        );

    return {
      id: group.id,
      type: group.type,
      budgetVariability: group.budgetVariability,
      name: group.name,
      groupLevelBudgetingEnabled: group.groupLevelBudgetingEnabled,
      rolloverPeriod: group.rolloverPeriod,
      budgetedCategories: budgetedItems,
      budgetedAmounts: budgetedAmounts ?? EMPTY_BUDGET_AMOUNTS,
      unbudgetedCategories: unbudgetedItems,
      unbudgetedAmounts,
    };
  };

  /**
   * Gets the budgeted amounts for a group from the summary data.
   * This is more reliable as it comes directly from the backend.
   */
  const getBudgetedAmountsFromSummary = (
    group: CategoryGroup | FlexGroup,
    summaryData: SummaryDisplayBudgetType[],
    isFlexBudgetingEnabled: boolean,
    categoryBudgetingAmounts: BudgetAmounts | undefined,
  ): BudgetAmounts | undefined => {
    const summaryForType = summaryData.find(({ type }) => type === group.type);

    // Is Flexible Budgeting and the group has a budget variability
    if (
      isFlexBudgetingEnabled &&
      !!group.budgetVariability &&
      summaryForType?.budgetVariabilities
    ) {
      // Find the variability amounts for the group
      const variabilityAmounts = summaryForType.budgetVariabilities.find(
        ({ type }) => type === group.budgetVariability,
      );

      if (!variabilityAmounts) {
        return undefined;
      }

      // Map the properties to match BudgetAmounts
      return {
        budgeted: variabilityAmounts.budgeted,
        actual: variabilityAmounts.actual,
        available: variabilityAmounts.remaining,
        rollover: variabilityAmounts.rollover,
        rolloverType: null,
      };
    }

    // Not Flexible Budgeting or the group doesn't have a budget variability
    // We use the legacy logic as the new logic introduced in #5798 and #5763 was causing issues
    // See ENG-10960 for more details
    return categoryBudgetingAmounts;
  };

  const flexGroupToDisplayGroup = (group: FlexGroup, displayItems: DisplayItem[]) => {
    const isFlexibleGroup = group.budgetVariability === BudgetVariability.FLEXIBLE;
    const sortedItems = isFlexibleGroup ? sortAndOrganizeByActual(displayItems) : displayItems;

    return {
      ...getDisplayGroup(group, sortedItems),
      isSectionEditable: false,
    };
  };

  /**
   * Only used for Flex Budgeting
   * It creates a display group for all the display categories with the same variability
   */
  const getFlexDisplayGroup = (
    displayItemsByVariability: Record<string, DisplayItem[]>,
    budgetVariability: BudgetVariability,
  ): DisplayGroup => {
    const displayItems = displayItemsByVariability[budgetVariability] ?? [];

    if (R.isEmpty(displayItems)) {
      return {
        id: budgetVariability,
        type: CategoryGroupType.EXPENSE,
        budgetVariability,
        name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[budgetVariability],
        groupLevelBudgetingEnabled: undefined,
        rolloverPeriod: undefined,
        budgetedCategories: [],
        budgetedAmounts: EMPTY_BUDGET_AMOUNTS,
        unbudgetedCategories: [],
        unbudgetedAmounts: undefined,
      };
    }

    const flexGroup: FlexGroup = {
      id: budgetVariability,
      name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[budgetVariability],
      order: BUDGET_VARIABILITY_ORDER[budgetVariability],
      type: CategoryGroupType.EXPENSE,
      budgetVariability,
      groupLevelBudgetingEnabled: undefined,
      rolloverPeriod: undefined,
    };

    return flexGroupToDisplayGroup(flexGroup, displayItems);
  };

  const getDisplayItems = (group: CategoryGroup) =>
    group.categories.map((category) => toDisplayItem(category, group));

  const toDisplayGroup = (group: CategoryGroup) => ({
    ...getDisplayGroup(group, sortByOrder(getDisplayItems(group))),
    isSectionEditable: true,
  });

  const getFlexDisplayItems = (groups: CategoryGroup[]) => {
    const displayItems: DisplayItem[] = [];

    sortByOrder(groups).forEach((group) => {
      // If group level budgeting is enabled, add the group as a display item
      if (group.groupLevelBudgetingEnabled) {
        // eslint-disable-next-line fp/no-mutating-methods
        displayItems.push({
          id: group.id,
          name: group.name,
          itemType: 'category_group',
          order: group.order,
          budgetVariability: group.budgetVariability,
          excludeFromBudget: false,
          amounts: getGroupMonthlyAmounts(group, false),
          rolloverPeriod: group.rolloverPeriod,
          groupId: group.id,
          groupType: group.type,
          groupBudgetVariability: group.budgetVariability,
          groupLevelBudgetingEnabled: group.groupLevelBudgetingEnabled,
        });
      }

      // eslint-disable-next-line fp/no-mutating-methods
      displayItems.push(...getDisplayItems(group));
    });

    return displayItems;
  };

  const getDisplayGroups = (groups: CategoryGroup[], type: string): DisplayGroup[] => {
    if (isFlexBudgetingEnabled && type === CategoryGroupType.EXPENSE) {
      // Group display categories by variability taking into account group level budgeting
      const displayItemsByVariability = R.groupBy(
        getBudgetVariabilityForDisplayItem,
        getFlexDisplayItems(groups),
      );

      return [
        getFlexDisplayGroup(displayItemsByVariability, BudgetVariability.FIXED),
        getFlexDisplayGroup(displayItemsByVariability, BudgetVariability.FLEXIBLE),
        getFlexDisplayGroup(displayItemsByVariability, BudgetVariability.NON_MONTHLY),
      ];
    }

    return groups.map(toDisplayGroup).filter(filterHiddenGroups(false));
  };

  const displayTypes: DisplayBudgetType[] = R.toPairs(groupedByType).map(([type, groups]) => ({
    title: CATEGORY_GROUP_TYPE_TO_TITLE[type],
    type: type as CategoryGroupType,
    displayGroups: getDisplayGroups(groups, type),
  }));

  const getHiddenGroups = (groups: CategoryGroup[]) =>
    isFlexBudgetingEnabled ? [] : groups.map(toDisplayGroup).filter(filterHiddenGroups(true));

  const hiddenGroupsByType = partitionDisplayGroupsByType(getHiddenGroups(sortedCategoryGroups));

  return {
    startDate: date.toISODate(),
    displayTypes,
    hiddenGroupsByType,
    goalsSections: generateGoalsV2Section(data, date),
  };
};

/**
 * Get the total budgeted amount for a list of categories
 * If group level budgeting is enabled, the category budgeted amounts are ignored
 * and the group budgeted amount is used instead
 */
export const getCategoriesBudgetedAmount = (categories: DisplayItem[]) =>
  categories
    .filter((category) => !category.groupLevelBudgetingEnabled || category.itemType !== 'category')
    .reduce((total, category) => total + (category.amounts?.budgeted ?? 0), 0);

export const getHasBudgetedCategories = (categories: DisplayItem[]) =>
  categories.some((category) => !!category.amounts?.budgeted);

const generateGoalsV2Section = (data: Common_BudgetDataQuery, date: DateTime): GoalsGroup[] => {
  const { goalsV2 = [] } = data;

  const groupId = 'goals-group';

  let goals = filterOutArchivedGoals(goalsV2);
  goals = sortGoalsByPriority(goals);

  const displayGoals: DisplayItem[] = goals.map((goal) => {
    const { id, name } = goal ?? {};

    const byMonth = R.propEq('month', date.toISODate());
    const plannedContribution = goal.plannedContributions.find(byMonth);
    const contributionSummary = goal.monthlyContributionSummaries.find(byMonth);

    const budgeted = plannedContribution?.amount ?? 0;
    const actual = contributionSummary?.sum ?? 0;

    return {
      goal,
      id,
      name,
      itemType: 'goal',
      groupId,
      order: 0,
      groupType: SAVINGS_PLAN_SECTION_TYPE,
      groupRolloverPeriod: undefined,
      groupBudgetVariability: undefined,
      groupLevelBudgetingEnabled: undefined,
      rolloverPeriod: undefined,
      budgetVariability: undefined,
      excludeFromBudget: undefined,
      amounts: {
        budgeted,
        actual,
        available: budgeted - actual,
        rollover: null,
        rolloverType: null,
      },
    };
  });

  const aggregateAmounts = sumBudgetAmountFields(extractTruthyAmounts(displayGoals));

  return [
    {
      id: groupId,
      type: SAVINGS_PLAN_SECTION_TYPE,
      budgetVariability: undefined,
      name: 'Goals',
      groupLevelBudgetingEnabled: undefined,
      rolloverPeriod: undefined,
      budgetedCategories: [],
      budgetedAmounts: aggregateAmounts ?? EMPTY_BUDGET_AMOUNTS,
      unbudgetedCategories: [],
      unbudgetedAmounts: undefined,
      data: displayGoals,
      isGoalsSection: true,
      sectionTitle: 'Contributions',
    },
  ];
};

export const getExcessAmount = (data: SummaryDisplayBudgetType[] | undefined): number | null => {
  const excess = data?.find(({ type }) => type === EXCESS_BUDGET_TYPE);
  const amount = excess && RA.isNotNil(excess?.budgeted) ? excess?.budgeted : null;
  return amount ? Math.round(amount) : null;
};

export const getSafeToSpendAmount = (
  data: SummaryDisplayBudgetType[] | undefined,
): number | null => {
  const expenses = data?.find(({ type }) => type === CategoryGroupType.EXPENSE);
  const flex = expenses?.budgetVariabilities?.find(
    ({ type }) => type === BudgetVariability.FLEXIBLE,
  );
  const amount = flex && RA.isNotNil(flex?.remaining) ? flex?.remaining : null;
  return amount ? Math.round(amount) : null;
};

export const getFlexActual = (data: SummaryDisplayBudgetType[] | undefined): number | null => {
  const expenses = data?.find(({ type }) => type === CategoryGroupType.EXPENSE);
  const amounts = expenses?.budgetVariabilities?.find(
    ({ type }) => type === BudgetVariability.FLEXIBLE,
  );
  const amount = amounts && RA.isNotNil(amounts?.actual) ? amounts?.actual : null;
  return amount ? Math.round(amount) : null;
};

export const getFixedExpected = (data: SummaryDisplayBudgetType[] | undefined): number | null => {
  const expenses = data?.find(({ type }) => type === CategoryGroupType.EXPENSE);
  const amounts = expenses?.budgetVariabilities?.find(
    ({ type }) => type === BudgetVariability.FIXED,
  );

  if (!amounts) {
    return null;
  }
  if (!RA.isNotNil(amounts.remaining)) {
    return null;
  }

  return Math.round(amounts.remaining);
};

export const getNonMonthlyExpected = (
  data: SummaryDisplayBudgetType[] | undefined,
): number | null => {
  const expenses = data?.find(({ type }) => type === CategoryGroupType.EXPENSE);
  const amounts = expenses?.budgetVariabilities?.find(
    ({ type }) => type === BudgetVariability.NON_MONTHLY,
  );

  if (!amounts) {
    return null;
  }
  if (!RA.isNotNil(amounts.remaining)) {
    return null;
  }

  return Math.round(amounts.remaining);
};

export const getExpectedExpense = (data: SummaryDisplayBudgetType[] | undefined): number => {
  const fixedExpected = getFixedExpected(data) ?? 0;
  const nonMonthlyExpected = getNonMonthlyExpected(data) ?? 0;

  return fixedExpected + nonMonthlyExpected;
};

export const getSummaryDataForType = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  type: PlanSectionType,
) => budgetSummaryData.find(R.propEq('type', type));

export const getBudgetedAmountForType = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  type: PlanSectionType,
) => getSummaryDataForType(budgetSummaryData, type)?.budgeted ?? 0;

export const getBudgetedAmountForVariability = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  budgetVariability: BudgetVariability,
) => {
  const summary = getSummaryDataForType(budgetSummaryData, CategoryGroupType.EXPENSE);
  return summary?.budgetVariabilities?.find(R.propEq('type', budgetVariability))?.budgeted ?? 0;
};

export const getAccumulatedAmountsMap = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  budgetVariability: BudgetVariability,
) => {
  const stackedVariabilities = BUDGET_VARIABILITY_STACK_MAPPING[budgetVariability];

  const totalByVariability: AmountsMap = {
    [BudgetVariability.FIXED]: undefined,
    [BudgetVariability.NON_MONTHLY]: undefined,
    [BudgetVariability.FLEXIBLE]: undefined,
  };

  stackedVariabilities.forEach((budgetVariability) => {
    totalByVariability[budgetVariability] = getBudgetedAmountForVariability(
      budgetSummaryData,
      budgetVariability,
    );
  });

  return totalByVariability;
};

const getValues = (amountsMap: AmountsMap) => R.values<AmountsMap, BudgetVariability>(amountsMap);

export const getAccumulatedAmountForVariabilities = R.pipe(
  getValues, // Fixes a syntax error in Prettier
  RA.compact,
  R.reduce((total, amount) => total + amount, 0),
);

export const getBudgetVariabilityName = (budgetVariability: BudgetVariability) =>
  BUDGET_VARIABILITY_TO_TITLE_MAPPING[budgetVariability];

export type SummaryRowProps = {
  title: string;
  value: number | undefined;
  placeholder?: string;
  type?: CurrencyType;
  emphasis?: boolean;
};

// Overload signatures
export function getSummaryRows(income: number, amountsMap: AmountsMap): SummaryRowProps[];
export function getSummaryRows(income: number, expenses: number): SummaryRowProps[];

export function getSummaryRows(
  income: number,
  expensesOrAmountsMap: number | AmountsMap,
): SummaryRowProps[] {
  const baseRow: SummaryRowProps = {
    title: 'Income',
    value: income,
    type: 'income',
    emphasis: true,
  };

  if (typeof expensesOrAmountsMap === 'number') {
    return [baseRow, { title: 'Expenses', value: expensesOrAmountsMap }];
  } else {
    return [
      baseRow,
      ...R.toPairs(expensesOrAmountsMap).map(([variability, value]) => ({
        title: `${getBudgetVariabilityName(variability as BudgetVariability)} expenses`,
        value,
        placeholder: 'Up next',
      })),
    ];
  }
}

export const getSummaryRowsAndLeftToBudget = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  budgetVariability: BudgetVariability,
) => {
  const income = getBudgetedAmountForType(budgetSummaryData, CategoryGroupType.INCOME);
  const accumulatedAmountsMap = getAccumulatedAmountsMap(budgetSummaryData, budgetVariability);
  return {
    rows: getSummaryRows(income, accumulatedAmountsMap),
    leftToBudget: income - getAccumulatedAmountForVariabilities(accumulatedAmountsMap),
  };
};

/**
 * Gets the appropriate color for a progress bar based on budget status
 */
export const getProgressBarColorForBudget = (
  remaining: number,
  type: ExtendedCategoryGroupType | BudgetVariability | undefined,
  isExtraBar = false,
): KeyOfThemeProp<'color'> => {
  if (
    remaining < 0 &&
    [
      CategoryGroupType.EXPENSE,
      BudgetVariability.FLEXIBLE,
      BudgetVariability.FIXED,
      BudgetVariability.NON_MONTHLY,
    ].includes(type as CategoryGroupType | BudgetVariability)
  ) {
    return 'red';
  }

  if (isExtraBar) {
    return 'yellow';
  }

  if (type === SAVINGS_PLAN_SECTION_TYPE) {
    return 'blue';
  }

  return 'green';
};

// Only used for the Flexible group
const sortAndOrganizeByActual = (displayItems: DisplayItem[]) => {
  const sortedItems = sortByActual(displayItems);

  return sortedItems.reduce((acc, current, currentIndex, items) => {
    // When a parent group is found it's added along with its children
    if (current.itemType === 'category_group') {
      const children = items.filter((item) => isGroupChild(item, current.id));
      const sortedChildren = sortByActual(children);
      return [...acc, current, ...sortedChildren];
    }

    // Omit adding the item if it's a child of a group because it was added above
    if (current.groupLevelBudgetingEnabled) {
      return acc;
    }

    return [...acc, current];
  }, [] as DisplayItem[]);
};

const isGroupChild = (item: DisplayItem, id: string) =>
  item.groupId === id && item.itemType !== 'category_group';

const sortByActual = R.sort((a: DisplayItem, b: DisplayItem) => {
  const actualA = a.amounts?.actual ?? 0;
  const actualB = b.amounts?.actual ?? 0;
  return actualB - actualA; // descending
});
