import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';

import * as middleware from 'middleware';
import reducer from 'state/reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'employee', 'persistentUi', 'dashboard', 'persistentAuth', 'flags', 'client'],
};

const configureStore = () => {
  const persistedReducer = persistReducer(persistConfig, reducer);
  const store = createStore(
    persistedReducer,
    // @ts-ignore TODO
    composeWithDevTools(applyMiddleware(...Object.values(middleware), thunkMiddleware)),
  );
  const persistor = persistStore(store);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducer', () => {
        store.replaceReducer(persistReducer(persistConfig, reducer));
      });
    }
  }

  return { store, persistor };
};

export default configureStore;
