import { createReducer } from 'typesafe-actions';
import uuid from 'uuid/v4';

export type ClientState = {
  deviceUUID: string;
};

const INITIAL_STATE: ClientState = {
  deviceUUID: uuid(),
};

const client = createReducer<ClientState>(INITIAL_STATE);

export default client;
