import pluralize from 'pluralize';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import FormItemContainer, { LabelContainer } from 'components/lib/form/FormItemContainer';
import NumericField from 'components/lib/form/NumericField';
import Radio, { CircleIndicator } from 'components/lib/form/Radio';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { fontSize, spacing } from 'common/lib/theme/dynamic';

import {
  TIMEFRAME_DAY,
  TIMEFRAME_MONTH,
  TIMEFRAME_QUARTER,
  TIMEFRAME_WEEK,
  TIMEFRAME_YEAR,
} from 'common/constants/timeframes';

import type { DateRangeWithTimeframes } from 'common/types/DateRange';

const RADIO_ITEM_SIZE_PX = 16;

const TIMEFRAME_UNIT_OPTIONS = (timeframeValue: number) => [
  { label: pluralize('Day', timeframeValue), value: TIMEFRAME_DAY },
  { label: pluralize('Week', timeframeValue), value: TIMEFRAME_WEEK },
  { label: pluralize('Month', timeframeValue), value: TIMEFRAME_MONTH },
  { label: pluralize('Quarter', timeframeValue), value: TIMEFRAME_QUARTER },
  { label: pluralize('Year', timeframeValue), value: TIMEFRAME_YEAR },
];

const MASK_OPTIONS = {
  allowNegative: false,
  allowLeadingZeroes: false,
  allowDecimal: false,
};

const TimeframeUnitRow = styled(FlexContainer)`
  align-items: center;
  padding: ${spacing.xxsmall} 0;
`;

const RadioContainer = styled(FormItemContainer)`
  ${LabelContainer} {
    margin-bottom: 0;
  }
`;

const TimeframeUnitRadioRow = styled(TimeframeUnitRow)`
  cursor: pointer;
  font-size: ${fontSize.small};

  ${CircleIndicator} {
    margin-right: ${spacing.xsmall};
    width: ${RADIO_ITEM_SIZE_PX}px;
    height: ${RADIO_ITEM_SIZE_PX}px;
  }
`;

const StyledNumericField = styled(NumericField)`
  :not(:last-child) {
    margin-bottom: 0;
  }

  input {
    padding: 6px 12px;
  }
`;

const DateRangeWithTimeframesLastFields = () => {
  const { values, setFieldValue, validateForm } = useFormContext<DateRangeWithTimeframes>();
  const unitValue = useMemo(() => ({ value: values.timeframeUnit }), [values.timeframeUnit]);

  return (
    <>
      <StyledNumericField
        label="Amount"
        name="timeframeValue"
        placeholder="Enter number"
        maskOptions={MASK_OPTIONS}
      />
      <RadioContainer label="Unit" name="timeframeUnit" fieldId="timeframeUnit">
        <Radio
          RowComponent={TimeframeUnitRadioRow}
          value={unitValue}
          options={TIMEFRAME_UNIT_OPTIONS(values.timeframeValue ?? 1)}
          onChange={async (option) => {
            if (!option) {
              return;
            }
            const unit = RA.isArray(option) ? option[0]?.value : option.value;
            await setFieldValue('timeframeUnit', unit);
            await setFieldValue('includeCurrentPeriod', unit === TIMEFRAME_DAY);
            await validateForm();
          }}
        />
      </RadioContainer>
    </>
  );
};

export default DateRangeWithTimeframesLastFields;
