import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import type { BalanceDataPoint } from 'components/accounts/CopyBalancePreviewGraph';
import CopyBalancePreviewGraph from 'components/accounts/CopyBalancePreviewGraph';
import AccountSummary from 'components/accounts/transferAccountData/AccountSummary';
import RadioGroup from 'components/lib/form/RadioGroup';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import SingleDatePicker from 'components/lib/ui/SingleDatePicker';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import useQuery from 'common/lib/hooks/useQuery';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import { snapshotsToPreviewBalanceDataPoints } from 'lib/accounts/snapshots';
import useDataTransferAccounts from 'lib/hooks/accounts/useDataTransferAccounts';
import useRecommendedDataTransferDates from 'lib/hooks/accounts/useRecommendedDataTransferDates';

import { gql } from 'common/generated/gql';
import type { Web_GetAccountsForTransferQuery } from 'common/generated/graphql';

type StepProps = {
  fromAccountId: string;
  toAccountId: string;
  transferTransactions: boolean;
  startDateTransactions: string | null;
  endDateTransactions: string | null;
  fromAccountExpectedAffectedTransactionCount: number;
  toAccountExpectedAffectedTransactionCount: number;
  toAccountNewerTransactionCount: number;
};

type NextStepProps = StepProps & {
  transferBalanceSnapshots: boolean;
  startDateBalanceSnapshots: string | null;
  endDateBalanceSnapshots: string | null;
  expectedAffectedBalanceSnapshotCount: number;
};

type Props = {
  fromAccountId: string;
  toAccountId: string;
  transferTransactions: boolean;
  next: (params: NextStepProps) => void;
  goBack?: () => void;
} & StepProps;

const GraphRoot = styled(FlexContainer)`
  width: 100%;
`;

const AccountColumn = styled(FlexContainer).attrs({ column: true })`
  width: 50%;
`;

const Snapshots = ({ fromAccountId, toAccountId, next, goBack, ...props }: Props) => {
  const [transferBalanceSnapshots, setTransferBalanceSnapshots] = useState(true);
  const [endDate, setEndDate] = useState<string | null>(null);
  const { data: recommendedDataTransferDates, loading: loadingRecommendedDataTransferDates } =
    useRecommendedDataTransferDates(fromAccountId, toAccountId);
  const { data: accountsForTransfer, loading: loadingAccountsForTransfer } =
    useDataTransferAccounts(fromAccountId, toAccountId);

  useEffect(() => {
    if (recommendedDataTransferDates?.snapshotsEndDate) {
      setEndDate(recommendedDataTransferDates?.snapshotsEndDate);
    }
  }, [recommendedDataTransferDates?.snapshotsEndDate]);

  const startDate = recommendedDataTransferDates?.snapshotsStartDate;
  const { data, isNetworkRequestInFlight: isLoadingPreview } = useQuery(PREVIEW_BALANCES_QUERY, {
    variables: {
      input: {
        fromAccountId,
        toAccountId,
        cutoverDate: null,
        startDate,
        endDate,
      },
    },
    skip: !startDate || !endDate,
  });

  // We don't currently let users select a start date, so we just pass the end date here.
  // If we start letting users select a start date, we'll need to update this.
  const previewSnapshots = data?.previewAccountDataTransferSnapshots.previewSnapshots ?? [];
  const balanceDataPoints = endDate
    ? snapshotsToPreviewBalanceDataPoints(previewSnapshots, endDate)
    : [];

  return (
    <TransferAccountDataStep
      progress={2}
      onClickBack={goBack}
      overrideTitle="Transfer balances"
      isLoading={loadingRecommendedDataTransferDates}
      footer={
        <PrimaryButton
          size="large"
          onClick={() =>
            next({
              fromAccountId,
              toAccountId,
              transferTransactions: props.transferTransactions,
              startDateTransactions: props.startDateTransactions,
              endDateTransactions: props.endDateTransactions,
              fromAccountExpectedAffectedTransactionCount:
                props.fromAccountExpectedAffectedTransactionCount,
              toAccountExpectedAffectedTransactionCount:
                props.toAccountExpectedAffectedTransactionCount,
              toAccountNewerTransactionCount: props.toAccountNewerTransactionCount,
              transferBalanceSnapshots,
              startDateBalanceSnapshots: recommendedDataTransferDates?.snapshotsStartDate ?? null,
              endDateBalanceSnapshots: endDate,
              expectedAffectedBalanceSnapshotCount:
                data?.previewAccountDataTransferSnapshots.fromAccountSummary.numSnapshots ?? 0,
            })
          }
        >
          Next up: Data backup
        </PrimaryButton>
      }
    >
      <FlexContainer center column marginTop="xxlarge" gap="large">
        <RadioGroup
          value={transferBalanceSnapshots ? 'transferBalanceSnapshots' : 'skipBalanceSnapshots'}
          onChange={(value) => setTransferBalanceSnapshots(value === 'transferBalanceSnapshots')}
          options={[
            {
              value: 'transferBalanceSnapshots',
              label: 'Move balance history to your new account',
              description:
                'You can choose which day you want your balance history to move from your old account to your new account.',
            },
            {
              value: 'skipBalanceSnapshots',
              label: 'Don’t move balance history',
              description:
                'If your old account had inaccurate or missing balances and you don’t want those to be carried over to your new account',
            },
          ]}
        />
        {transferBalanceSnapshots && (
          <>
            <Text>Transition date</Text>
            <SingleDatePicker
              date={endDate}
              onDateChange={(value) => {
                setEndDate(value);
              }}
            />
            <Text>
              We recommend the day before your new account was created:{' '}
              {recommendedDataTransferDates?.snapshotsEndDate &&
                isoDateToAbbreviatedMonthDayAndYear(recommendedDataTransferDates?.snapshotsEndDate)}
            </Text>
            <SnapshotsPreview
              isLoading={isLoadingPreview || loadingAccountsForTransfer}
              balanceDataPoints={balanceDataPoints}
              fromAccount={accountsForTransfer?.fromAccount}
              toAccount={accountsForTransfer?.toAccount}
            />
          </>
        )}
      </FlexContainer>
    </TransferAccountDataStep>
  );
};

const SnapshotsPreview = ({
  isLoading,
  balanceDataPoints,
  fromAccount,
  toAccount,
}: {
  isLoading: boolean;
  balanceDataPoints: BalanceDataPoint[];
  fromAccount: Web_GetAccountsForTransferQuery['fromAccount'];
  toAccount: Web_GetAccountsForTransferQuery['toAccount'];
}) => {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <FlexContainer full gap="gutter">
        <AccountColumn>
          <FlexContainer>
            <AccountSummary
              icon={fromAccount?.logoUrl}
              logoUrl={fromAccount?.logoUrl}
              label={fromAccount?.displayName}
              lastUpdatedAt={fromAccount?.createdAt}
              dataProvider={fromAccount?.dataProvider}
              institution={fromAccount?.institution}
              syncDisabled={fromAccount?.syncDisabled}
            />
          </FlexContainer>
        </AccountColumn>
        <AccountColumn>
          <FlexContainer>
            <AccountSummary
              icon={toAccount?.logoUrl}
              logoUrl={toAccount?.logoUrl}
              label={toAccount?.displayName}
              lastUpdatedAt={toAccount?.createdAt}
              dataProvider={toAccount?.dataProvider}
              institution={toAccount?.institution}
              syncDisabled={toAccount?.syncDisabled}
            />
          </FlexContainer>
        </AccountColumn>
      </FlexContainer>
      <GraphRoot column>
        <CopyBalancePreviewGraph isLoading={false} data={balanceDataPoints} />
      </GraphRoot>
    </>
  );
};

const PREVIEW_BALANCES_QUERY = gql(/* GraphQL */ `
  query Web_PreviewAccountDataTransferSnapshots($input: PreviewCopySnapshotsInput!) {
    previewAccountDataTransferSnapshots(input: $input) {
      previewSnapshots {
        date
        signedBalance
      }
      fromAccountSummary {
        numSnapshots
      }
      toAccountSummary {
        numSnapshots
      }
    }
  }
`);

export default Snapshots;
