import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import TabNavLink from 'components/lib/ui/link/TabNavLink';

import { fontSize, spacing } from 'common/lib/theme/dynamic';

import routes from 'constants/routes';

const Root = styled(FlexContainer).attrs({ alignStretch: true })`
  margin-left: ${spacing.xlarge};
`;

const StyledLink = styled(TabNavLink)`
  margin-right: ${spacing.large};
  font-size: ${fontSize.base};
  align-items: center;
  display: flex;
`;

const ReportsHeaderTabs = () => {
  // This needs to be loaded from the window.location because
  // we don't update the history from react-router when updating the query params
  const { search } = window.location;

  return (
    <Root>
      <StyledLink
        to={{
          pathname: routes.reports.cashFlow.path,
          search,
        }}
      >
        Cash Flow
      </StyledLink>

      <StyledLink
        to={{
          pathname: routes.reports.spending.path,
          search,
        }}
      >
        Spending
      </StyledLink>
      <StyledLink
        to={{
          pathname: routes.reports.income.path,
          search,
        }}
      >
        Income
      </StyledLink>
    </Root>
  );
};

export default ReportsHeaderTabs;
