import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalstorageState } from 'rooks';
import styled from 'styled-components';

import AppleSignInButton from 'components/lib/external/AppleSignInButton';
import GoogleSignInButton from 'components/lib/external/GoogleSignInButton';
import Form from 'components/lib/form/Form';
import { Label } from 'components/lib/form/FormItemContainer';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import LoggedOutCardPage from 'components/lib/layouts/LoggedOutCardPage';
import Banner from 'components/lib/ui/Banner';
import Divider from 'components/lib/ui/Divider';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import LoadingSpinnerWithText from 'components/lib/ui/LoadingSpinnerWithText';
import Text from 'components/lib/ui/Text';
import Toggle from 'components/lib/ui/Toggle';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import RouteLink from 'components/lib/ui/link/RouteLink';
import type { LoginParams } from 'components/routes/Login';

import { useQueryParam } from 'lib/hooks';
import { useUpdateQueryParam } from 'lib/hooks/useQueryParams';
import isEnvDevelopment from 'lib/isEnvDevelopment';

import { AUTH_PROVIDER_TO_LABEL } from 'common/constants/auth';
import routes from 'constants/routes';

import { ErrorCode } from 'common/generated/graphQlTypes/globalTypes';
import type { AuthProvider } from 'common/types/auth';

const ForgotPasswordLink = styled(RouteLink)`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const PasswordLabel = styled.div`
  flex-grow: 1;
`;

const NoAccount = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: -${({ theme }) => theme.spacing.xxsmall};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.text};
  text-align: center;
`;

const StyledBanner = styled(Banner)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledLabel = styled(Label)`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.default} 0;
`;

const AuthButtonsContainer = styled(FlexContainer).attrs({ column: true })`
  width: 100%;

  > button:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

const StaySignedInControls = styled.span`
  align-items: center;
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  display: flex;
`;

const StaySignedInToggle = styled(Toggle)`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const LoadingSpinnerContainer = styled.div`
  height: 400px;
`;

const SmallDefaultButton = styled(DefaultButton)`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const ReCaptchaDisclaimer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
`;

export const MESSAGES: { [key: string]: string } = {
  timeout: 'You have been automatically logged out due to inactivity.',
  householdScheduledForDeletion: 'Your household is scheduled for deletion.',
  authError:
    'Sorry, something went wrong during authentication. Please try again or report the issue.',
};

type Props = {
  overrideErrorMessage?: string;
  onSubmit: (values: LoginParams) => Promise<void>;
};

const UsernamePassword = ({ overrideErrorMessage, onSubmit }: Props) => {
  const history = useHistory();
  const [pendingAuthProvider, setPendingAuthProvider] = useState<AuthProvider | undefined>();
  const [staySignedIn, setStaySignedIn] = useLocalstorageState('stay-signed-in', true);
  const [email, setEmail] = useState<string>('');
  const setMessage = useUpdateQueryParam('message');

  const onExternalAuthError = (error?: { detail: string; code: string }) => {
    if (error && error.code === ErrorCode.HOUSEHOLD_SCHEDULED_FOR_DELETION) {
      setMessage('householdScheduledForDeletion');
      setPendingAuthProvider(undefined);
    }
  };

  return (
    <LoggedOutCardPage
      displayTitle="Sign In"
      name="Login"
      controls={
        <StaySignedInControls>
          Stay signed in
          <StaySignedInToggle
            checked={staySignedIn}
            onChange={(e) => setStaySignedIn(e.target.checked)}
          />
        </StaySignedInControls>
      }
      subtext={
        <ReCaptchaDisclaimer>
          <Text size="small">
            This site is protected by reCAPTCHA and the Google
            <Link target="_blank" href="https://policies.google.com/privacy">
              {' '}
              Privacy Policy
            </Link>{' '}
            and
            <Link target="_blank" href="https://policies.google.com/terms">
              {' '}
              Terms of Service
            </Link>{' '}
            apply.
          </Text>
        </ReCaptchaDisclaimer>
      }
    >
      {pendingAuthProvider ? (
        <LoadingSpinnerContainer>
          <LoadingSpinnerWithText
            text={`Signing in with ${AUTH_PROVIDER_TO_LABEL[pendingAuthProvider]}`}
          />
        </LoadingSpinnerContainer>
      ) : (
        <Form
          initialValues={{ username: '', password: '' }}
          onSubmit={(values) => onSubmit({ ...values, trusted_device: staySignedIn })}
        >
          {overrideErrorMessage ? (
            <StyledBanner type="error">{overrideErrorMessage}</StyledBanner>
          ) : (
            <QueryParamErrorBanner />
          )}
          <AuthButtonsContainer>
            <AppleSignInButton
              staySignedIn={staySignedIn}
              setIsPending={(isPending) => setPendingAuthProvider(isPending ? 'apple' : undefined)}
              onError={onExternalAuthError}
            />
            <GoogleSignInButton
              staySignedIn={staySignedIn}
              setIsPending={(isPending) => setPendingAuthProvider(isPending ? 'google' : undefined)}
              onError={onExternalAuthError}
            />
            <StyledDivider />
          </AuthButtonsContainer>
          <TextField
            name="username"
            label="Email address"
            email
            required
            autoComplete="username"
            onChange={setEmail}
          />
          <StyledLabel>
            <PasswordLabel>Password</PasswordLabel>
            <ForgotPasswordLink
              tabIndex={-1}
              to={routes.forgotPassword({ queryParams: { email } })}
            >
              Forgot Password?
            </ForgotPasswordLink>
          </StyledLabel>
          <TextField name="password" hideLabel password required autoComplete="current-password" />
          <FormSubmitButton>Sign In</FormSubmitButton>
          {isEnvDevelopment() && (
            <>
              <StyledDivider text="Development-only options" />
              <SmallDefaultButton
                onClick={() => history.push(routes.advisors.signup())}
                size="small"
              >
                Sign up as Professional
              </SmallDefaultButton>
            </>
          )}
          <NoAccount>
            Don&apos;t have an account? <RouteLink to={routes.signup()}>Sign up</RouteLink>
          </NoAccount>
        </Form>
      )}
    </LoggedOutCardPage>
  );
};

const QueryParamErrorBanner = () => {
  const message = useQueryParam('message');
  const isErrorMessage = message?.toLowerCase().includes('error');

  return (
    <>
      {message && MESSAGES[message] ? (
        <StyledBanner type={isErrorMessage ? 'error' : 'info'}>{MESSAGES[message]}</StyledBanner>
      ) : null}
    </>
  );
};

export default UsernamePassword;
