import { gql } from '@apollo/client';
import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import RelativeDate from 'components/lib/ui/RelativeDate';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import InstitutionLogoWithStatus from 'components/settings/institutions/InstitutionLogoWithStatus';

import { getDisplayName } from 'common/lib/dataProvider';

import { INSTITUTION } from 'common/constants/copy';

import { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';
import type { Web_GetInstitutionSettingsQuery } from 'common/generated/graphql';

const INSTITUTION_LOGO_SIZE_PX = 32;

const InstitutionLogoWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const InstitutionProviderDetailsText = styled(Text).attrs({
  size: 'xxsmall',
  color: 'textLight',
  weight: 'bold',
})<{ $isDisconnected?: boolean }>`
  text-transform: uppercase;
  letter-spacing: 0.08em;

  ${({ $isDisconnected }) =>
    $isDisconnected &&
    css`
      color: ${({ theme }) => theme.color.redText};
    `};
`;

const TooltipContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  credential: Web_GetInstitutionSettingsQuery['credentials'][0];
};

const InstitutionInfo = ({ credential }: Props) => {
  const isUpdateRequired = credential?.updateRequired;
  const isDisconnected = !!credential.syncDisabledAt;

  const { institution, dataProvider, displayLastUpdatedAt } = credential;
  const { name, newConnectionsDisabled, hasIssuesReported, hasIssuesReportedMessage } = institution;

  const showHasIssuesReportedWarning =
    newConnectionsDisabled || (hasIssuesReported && dataProvider === DataProviderLegacy.PLAID);

  return (
    <Tooltip
      place="left"
      effect="solid"
      content={
        showHasIssuesReportedWarning && (
          <TooltipContent>
            <Text size="small" color="textWhite">
              {hasIssuesReportedMessage || INSTITUTION.FALLBACK_ISSUES_REPORTED_MESSAGE}
            </Text>
          </TooltipContent>
        )
      }
      opacity={1}
    >
      <FlexContainer>
        <InstitutionLogoWrapper>
          <InstitutionLogoWithStatus
            size={INSTITUTION_LOGO_SIZE_PX}
            credential={credential}
            isDisconnected={isDisconnected}
            isUpdateRequired={isUpdateRequired}
          />
        </InstitutionLogoWrapper>
        <FlexContainer column>
          <InstitutionProviderDetailsText>
            {getDisplayName(dataProvider)}
            {isDisconnected && (
              <>
                {' '}
                •{' '}
                <InstitutionProviderDetailsText $isDisconnected>
                  Disconnected
                </InstitutionProviderDetailsText>
              </>
            )}
            {displayLastUpdatedAt && !isDisconnected && (
              <>
                {' '}
                • last update <RelativeDate date={displayLastUpdatedAt} />
              </>
            )}
          </InstitutionProviderDetailsText>
          <Text size="large" weight="medium">
            {name}
          </Text>
        </FlexContainer>
      </FlexContainer>
    </Tooltip>
  );
};

InstitutionInfo.fragments = {
  InstitutionInfoFields: gql`
    fragment InstitutionInfoFields on Credential {
      id
      displayLastUpdatedAt
      dataProvider
      updateRequired
      disconnectedFromDataProviderAt
      syncDisabledAt
      syncDisabledReason
      ...InstitutionLogoWithStatusFields

      institution {
        id
        name
        newConnectionsDisabled
        hasIssuesReported
        hasIssuesReportedMessage
      }
    }
    ${InstitutionLogoWithStatus.fragments.InstitutionLogoWithStatusFields}
  `,
};

export default InstitutionInfo;
