import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { MINT_IMPORTER_MAIN_PAGE } from 'constants/copy';
import routes from 'constants/routes';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${({ theme }) => theme.color.white};
`;

const ImportPageTitle = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.color.text};
  font-variant-numeric: lining-nums;
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 150%;
  margin: 0 auto;
`;

const ImportPageSubtitle = styled.p`
  max-width: 560px;
  text-align: center;
  color: ${({ theme }) => theme.color.text};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  line-height: 150%;
  margin: 0 auto ${({ theme }) => theme.spacing.xxlarge};
`;

const ImportOptionsGroup = styled.div`
  max-width: 700px;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.color.grayLight};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const ImportOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  padding: ${({ theme }) => theme.spacing.default};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayLight};
  }
`;

const ImportOptionDescription = styled.div``;

const ImportOptionDescriptionTitle = styled.h3`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 150%;
`;

const ImportOptionDescriptionBody = styled.p`
  color: ${({ theme }) => theme.color.text};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  line-height: 150%;
  margin: 0;
`;

const ImportOptionCTAWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-end;
`;

const ImportOptionCTA = styled(PrimaryButton).attrs({ size: 'small' })``;

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xxxlarge} ${({ theme }) => theme.spacing.xlarge};
`;

const ImportMintData = () => {
  const history = useHistory();

  const onBackButton = () => {
    history.push(routes.accounts.addAccount());
  };

  const INITIAL_PROGRESS = 0.05;

  return (
    <StyledPage
      name="Import Mint Data"
      header={
        <QuestionnaireHeader
          progress={INITIAL_PROGRESS}
          showBackButton
          onClickBackButton={onBackButton}
        />
      }
    >
      <Container>
        <ImportPageTitle>{MINT_IMPORTER_MAIN_PAGE.TITLE}</ImportPageTitle>
        <ImportPageSubtitle>{MINT_IMPORTER_MAIN_PAGE.SUBTITLE}</ImportPageSubtitle>
        <ImportOptionsGroup>
          <ImportOption>
            <ImportOptionDescription>
              <ImportOptionDescriptionTitle>
                {MINT_IMPORTER_MAIN_PAGE.OPTIONS.TRANSACTIONS.TITLE}
              </ImportOptionDescriptionTitle>
              <ImportOptionDescriptionBody>
                {MINT_IMPORTER_MAIN_PAGE.OPTIONS.TRANSACTIONS.DESCRIPTION}
              </ImportOptionDescriptionBody>
            </ImportOptionDescription>
            <ImportOptionCTAWrapper>
              <ImportOptionCTA
                onClick={() => history.push(routes.accounts.importMintTransactions())}
              >
                {MINT_IMPORTER_MAIN_PAGE.OPTIONS.TRANSACTIONS.CTA}
              </ImportOptionCTA>
            </ImportOptionCTAWrapper>
          </ImportOption>
          <ImportOption>
            <ImportOptionDescription>
              <ImportOptionDescriptionTitle>
                {MINT_IMPORTER_MAIN_PAGE.OPTIONS.BALANCE_HISTORY.TITLE}
              </ImportOptionDescriptionTitle>
              <ImportOptionDescriptionBody>
                {MINT_IMPORTER_MAIN_PAGE.OPTIONS.BALANCE_HISTORY.DESCRIPTION}
              </ImportOptionDescriptionBody>
            </ImportOptionDescription>
            <ImportOptionCTAWrapper>
              <ImportOptionCTA
                onClick={() => history.push(routes.accounts.importMintBalanceHistory())}
              >
                {MINT_IMPORTER_MAIN_PAGE.OPTIONS.BALANCE_HISTORY.CTA}
              </ImportOptionCTA>
            </ImportOptionCTAWrapper>
          </ImportOption>
        </ImportOptionsGroup>
      </Container>
    </StyledPage>
  );
};

export default ImportMintData;
