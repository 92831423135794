import * as React from 'react';
import styled from 'styled-components';

import ExternalLink from 'components/lib/ui/Link';
import BillingRow from 'components/settings/billing/BillingRow';

import { HELP_CENTER_SWITCH_BILLING_URL } from 'common/constants/externalUrls';

import type { PromoCode } from 'common/generated/graphql';
import { SubscriptionDetailsPaymentSource } from 'common/generated/graphql';

type Props = {
  promoCode: PromoCode | null | undefined;
  paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
};

const PromoCodeContainer = styled(BillingRow)`
  display: block;
`;

const Content = styled.div``;

const SwitchMessage = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledLink = styled(ExternalLink)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const PromoCodeRow = ({ promoCode, paymentSource }: Props) =>
  promoCode || paymentSource !== SubscriptionDetailsPaymentSource.STRIPE ? (
    <PromoCodeContainer title="Promo Code">
      <Content>
        {promoCode && `${promoCode.code} (${promoCode.description})`}
        {paymentSource !== SubscriptionDetailsPaymentSource.STRIPE && !promoCode && (
          <SwitchMessage>
            To apply a promo code, you&apos;ll need to switch to direct billing.
            <StyledLink href={HELP_CENTER_SWITCH_BILLING_URL} target="_blank">
              Learn how to switch
            </StyledLink>
          </SwitchMessage>
        )}
      </Content>
    </PromoCodeContainer>
  ) : (
    <></>
  );

export default PromoCodeRow;
