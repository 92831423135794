import * as RA from 'ramda-adjunct';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Switch, { Case } from 'common/components/utils/Switch';
import { DateRangeWithTimeframesFormContentType } from 'components/lib/dates/DateRangeWithTimeframesForm';
import DateRangePickerWithTimeframesLastFields from 'components/lib/dates/DateRangeWithTimeframesLastFields';
import DateRangePickerWithTimeframesRangeFields from 'components/lib/dates/DateRangeWithTimeframesRangeFields';
import DateRangeWithTimeframesShortcutContent from 'components/lib/dates/DateRangeWithTimeframesShortcutContent';
import DateRangeWithTimeframesShortcuts from 'components/lib/dates/DateRangeWithTimeframesShortcuts';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardFooter from 'components/lib/ui/CardFooter';
import Divider from 'components/lib/ui/Divider';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import Menu, { MenuItem } from 'components/lib/ui/menu/Menu';

import type { ShortcutId } from 'common/lib/dateRange/dateRangeWithTimeframes';
import { formatDateRangeWithRelativeTimeframeResolution } from 'common/lib/dateRange/dateRangeWithTimeframes';
import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';

import type { DateRangeWithTimeframes } from 'common/types/DateRange';

const MENU_HEADER_HEIGHT_PX = 40;
const MENU_CONTENT_MIN_WIDTH_PX = 280;
const MENU_CONTENT_MIN_HEIGHT_PX = 344;
const MENU_OPTIONS_COLUMN_MIN_WIDTH_PX = 140;

const Row = styled.div`
  display: grid;
  grid-template-columns: ${MENU_OPTIONS_COLUMN_MIN_WIDTH_PX}px 1fr;
`;

const StyledDivider = styled(Divider)`
  padding: 0 ${spacing.xsmall};
  height: 1px;
`;

const OptionsColumn = styled.div`
  border-right: 1px solid ${color.grayBackground};
  overflow: clip;
  min-width: ${MENU_OPTIONS_COLUMN_MIN_WIDTH_PX}px;
`;

const ContentColumn = styled.div`
  min-width: ${MENU_CONTENT_MIN_WIDTH_PX}px;
  min-height: ${MENU_CONTENT_MIN_HEIGHT_PX}px;
`;

const Title = styled(Text)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${color.grayBackground};
  padding: 0 ${spacing.default};
  height: 100%;
  width: 100%;
`;

const Footer = styled(CardFooter).attrs({ $size: 'small' })`
  ${DefaultButton}:first-child {
    margin-right: auto;
  }
`;

const Header = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  height: ${MENU_HEADER_HEIGHT_PX}px;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.small};
  width: 100%;
`;

const InputContainer = styled(FlexContainer).attrs({ column: true, justifyCenter: true })`
  flex: 1;
  gap: ${spacing.xlarge};
  padding: ${spacing.default};
`;

const DateTypeMenu = styled(Menu)`
  padding: ${spacing.xsmall};
`;

const DateTypeMenuItem = styled(MenuItem)`
  color: ${color.text};
  font-weight: ${fontWeight.book};
  padding: 5.5px ${spacing.xsmall};
  margin: ${spacing.xxxsmall} 0;
  font-size: ${fontSize.small};
`;

type Props = {
  contentId: ShortcutId | DateRangeWithTimeframesFormContentType;
  onSetContentId: (contentId: ShortcutId | DateRangeWithTimeframesFormContentType) => void;
  onClear: () => void;
  toggleOpen?: () => void;
};

const DateRangeWithTimeframesFormContent = ({
  contentId,
  onSetContentId,
  onClear,
  toggleOpen,
}: Props) => {
  const { initialValues, values, setValues, validateForm } =
    useFormContext<DateRangeWithTimeframes>();

  const isLast = contentId === DateRangeWithTimeframesFormContentType.LAST;
  const isRange = contentId === DateRangeWithTimeframesFormContentType.RANGE;

  const hasTimeframePeriod =
    RA.isNotNil(values.timeframeUnit) &&
    RA.isNotNil(values.timeframeValue) &&
    RA.isNotNil(values.includeCurrentPeriod);

  const handleClear = () => {
    onClear();
    toggleOpen?.();
  };

  const onSetLastContent = useCallback(() => {
    setValues({
      startDate: undefined,
      endDate: undefined,
      timeframeUnit: undefined,
      timeframeValue: undefined,
      includeCurrentPeriod: undefined,
    });

    onSetContentId(DateRangeWithTimeframesFormContentType.LAST);

    validateForm();
  }, [setValues, onSetContentId, validateForm]);

  const onSetRangeContent = useCallback(() => {
    setValues({
      ...initialValues,
      timeframeUnit: undefined,
      timeframeValue: undefined,
      includeCurrentPeriod: undefined,
    });

    onSetContentId(DateRangeWithTimeframesFormContentType.RANGE);

    validateForm();
  }, [setValues, initialValues, onSetContentId, validateForm]);

  return (
    <>
      <Row>
        <OptionsColumn>
          <Header>
            <Title>Date Range</Title>
          </Header>
          <DateTypeMenu>
            <DateTypeMenuItem selected={isLast} onClick={onSetLastContent}>
              Last
            </DateTypeMenuItem>
            <DateTypeMenuItem selected={isRange} onClick={onSetRangeContent}>
              Range
            </DateTypeMenuItem>
          </DateTypeMenu>
          <StyledDivider text="" />
          <DateRangeWithTimeframesShortcuts selectedShortcutId={contentId} />
        </OptionsColumn>
        <ContentColumn>
          <Header>
            <Title>
              {isLast && !hasTimeframePeriod
                ? ''
                : formatDateRangeWithRelativeTimeframeResolution(values)}
            </Title>
          </Header>
          <InputContainer>
            <Switch>
              <Case when={isLast}>
                <DateRangePickerWithTimeframesLastFields />
              </Case>
              <Case when={isRange}>
                <DateRangePickerWithTimeframesRangeFields />
              </Case>
              <Case default>
                <DateRangeWithTimeframesShortcutContent selectedShortcutId={contentId} />
              </Case>
            </Switch>
          </InputContainer>
        </ContentColumn>
      </Row>
      <Footer>
        <DefaultButton size="xsmall" onClick={handleClear}>
          Clear
        </DefaultButton>
        <DefaultButton size="xsmall" onClick={toggleOpen}>
          Cancel
        </DefaultButton>
        <FormSubmitButton size="xsmall">Apply</FormSubmitButton>
      </Footer>
    </>
  );
};

export default DateRangeWithTimeframesFormContent;
