import { gql, useSubscription } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AssistantControls from 'components/assistant/AssistantControls';
import AssistantLoadingMessage from 'components/assistant/AssistantLoadingMessage';
import AssistantOptInModal from 'components/assistant/AssistantOptInModal';
import MessageInputContainer from 'components/assistant/MessageInputContainer';
import MessageListGroup from 'components/assistant/MessageListGroup';
import MessageThreadControls from 'components/assistant/MessageThreadControls';
import MessageThreadListItem from 'components/assistant/MessageThreadListItem';
import MessageThreadsList from 'components/assistant/MessageThreadsList';
import MessagesList from 'components/assistant/MessagesList';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';

import useQuery from 'common/lib/hooks/useQuery';
import { useQueryParam } from 'lib/hooks/useQueryParams';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import type {
  Web_GetMessageThread,
  Web_GetMessageThreadVariables,
} from 'common/generated/graphQlTypes/Web_GetMessageThread';
import type { Web_OnMessageCreated } from 'common/generated/graphQlTypes/Web_OnMessageCreated';
import type { Web_OnMessageThreadUpdated } from 'common/generated/graphQlTypes/Web_OnMessageThreadUpdated';
import type { Web_OnMessageUpdated } from 'common/generated/graphQlTypes/Web_OnMessageUpdated';
import type { Web_GetMessageThreadsQuery } from 'common/generated/graphql';
import type RouteProps from 'types/RouteProps';

const Layout = styled(FlexContainer)`
  background: ${({ theme }) => theme.color.grayBackground};
  height: 100%;
  width: 100%;
`;

const Sidebar = styled.div`
  width: 400px;
  border-right: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.gutter};
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 0;
`;

const Main = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.gutter};
  padding-left: 0;
  padding-top: 0;
`;

const FlexGrow = styled.div`
  flex: 1;
`;

const FullWidthCard = styled(Card)`
  width: 100%;
  max-height: 100%;
`;

const MainCard = styled(Card)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Assistant = ({
  match: {
    params: { threadId },
  },
}: RouteProps<typeof routes.assistant>) => {
  const history = useHistory();

  const agentType = useQueryParam('agentType');

  const { data, refetch, isLoadingInitialData } =
    useQuery<Web_GetMessageThreadsQuery>(GET_MESSAGE_THREADS);
  const { messageThreads = [], householdPreferences, me } = data ?? {};

  const { aiAssistantEnabled } = householdPreferences ?? {};

  const { profile } = me ?? {};

  const { aiAssistantOptedInAt } = profile ?? {};

  const needsToOptIn = aiAssistantOptedInAt === null;

  const { data: messageThreadData, subscribeToMore } = useQuery<
    Web_GetMessageThread,
    Web_GetMessageThreadVariables
  >(GET_MESSAGE_THREAD, {
    variables: {
      threadId: threadId ?? '',
    },
    skip: !threadId,
  });
  const { messageThread } = messageThreadData ?? {};
  const { messages = [], hasOutstandingAssistantRequests } = messageThread ?? {};
  const lastMessage = R.last(messages);

  // if the last message is from the user, show the loading footer
  const showLoadingFooter =
    hasOutstandingAssistantRequests && lastMessage?.__typename === 'UserMessage';

  useSubscription<Web_OnMessageUpdated>(MESSAGE_UPDATED_SUBSCRIPTION);
  useSubscription<Web_OnMessageThreadUpdated>(MESSAGE_THREAD_UPDATED_SUBSCRIPTION);

  useEffect(() => {
    const stop = subscribeToMore<Web_OnMessageCreated>({
      document: MESSAGE_CREATED_SUBSCRIPTION,
      updateQuery: (data, { subscriptionData }) => {
        const { onMessageCreated } = subscriptionData.data;
        const message = onMessageCreated?.message;
        const { messageThread } = data;

        if (!message || message.threadId !== threadId || !messageThread) {
          return data;
        }

        const updatedMessageThread = R.evolve(
          {
            messages: (prev) => {
              const messages = R.append(message, prev);
              return R.uniqBy(R.prop('id'), messages);
            },
          },
          messageThread,
        );

        return { messageThread: updatedMessageThread };
      },
    });
    return stop;
  }, [subscribeToMore, threadId]);

  useEffect(() => {
    // it doesn't redirect if the user hasn't opted in or the data hasn't loaded yet
    if (aiAssistantEnabled && !isLoadingInitialData) {
      if (!threadId && messageThreads.length && !agentType) {
        // If the current path is just /assistant, redirect to the first thread upon loading
        history.replace(routes.assistant({ threadId: messageThreads[0].id }));
      }
      if (threadId && messageThreads.length && !messageThreads.find((t) => t.id === threadId)) {
        // If the current threadId does not exist in threads (i.e. it was deleted), redirect to the first thread
        history.replace(routes.assistant({ threadId: messageThreads[0].id }));
      }
    }
  }, [aiAssistantEnabled, isLoadingInitialData, messageThreads, threadId, history]);

  const title = messageThread?.subject ?? COPY.ASSISTANT.MESSAGE_THREAD_SUBJECT_PLACEHOLDER;

  const onCreateMessageThread = useCallback(
    async (threadId: string) => {
      await refetch();
      history.push(routes.assistant({ threadId }));
    },
    [refetch, history],
  );

  const shouldShowOptInModal = useMemo(
    () => !isLoadingInitialData && (!aiAssistantEnabled || needsToOptIn),
    [aiAssistantEnabled, isLoadingInitialData, messageThreads],
  );

  return (
    <Page
      name="Assistant"
      shouldScroll={false}
      controls={<AssistantControls onCreateMessageThread={onCreateMessageThread} />}
      overlayEmptyComponent={
        shouldShowOptInModal ? (
          <AssistantOptInModal
            agentType={agentType}
            onCreateMessageThread={onCreateMessageThread}
          />
        ) : null
      }
    >
      <Layout>
        <Sidebar>
          {RA.isNotNilOrEmpty(messageThreads) && (
            <FullWidthCard title="Chats">
              <MessageThreadsList messageThreads={messageThreads} activeThreadId={threadId} />
            </FullWidthCard>
          )}
        </Sidebar>
        <Main>
          {!!threadId && (
            <MainCard title={title} controls={<MessageThreadControls threadId={threadId} />}>
              <FlexGrow>
                {!!messages.length && (
                  <MessagesList
                    // use key to ensure list doesn't animate down when switching threads
                    key={messages[0]?.id}
                    messages={messages}
                    threadId={threadId}
                    footer={showLoadingFooter && <AssistantLoadingMessage />}
                  />
                )}
              </FlexGrow>
              <MessageInputContainer threadId={threadId} lastMessage={lastMessage} />
            </MainCard>
          )}
        </Main>
      </Layout>
    </Page>
  );
};

// don't change the name of this query as it's used in some mutation refetchQueries
const GET_MESSAGE_THREADS = gql`
  query Web_GetMessageThreads {
    messageThreads {
      id
      ...MessageThreadListItemFields
    }
    householdPreferences {
      id
      aiAssistantEnabled
    }
    me {
      id
      profile {
        id
        aiAssistantOptedInAt
      }
    }
  }
  ${MessageThreadListItem.fragments.MessageThreadListItemFields}
`;

const GET_MESSAGE_THREAD = gql`
  query Web_GetMessageThread($threadId: ID!) {
    messageThread(id: $threadId) {
      id
      subject
      hasOutstandingAssistantRequests
      messages {
        ...MessageListGroupFields
      }
    }
  }
  ${MessageListGroup.fragments.MessageListGroupFields}
`;

const MESSAGE_UPDATED_SUBSCRIPTION = gql`
  subscription Web_OnMessageUpdated {
    onMessageUpdated {
      message {
        ...MessageListGroupFields
      }
    }
  }
  ${MessageListGroup.fragments.MessageListGroupFields}
`;

const MESSAGE_CREATED_SUBSCRIPTION = gql`
  subscription Web_OnMessageCreated {
    onMessageCreated {
      message {
        ...MessageListGroupFields
      }
    }
  }
  ${MessageListGroup.fragments.MessageListGroupFields}
`;

const MESSAGE_THREAD_UPDATED_SUBSCRIPTION = gql`
  subscription Web_OnMessageThreadUpdated {
    onMessageThreadUpdated {
      messageThread {
        ...MessageThreadListItemFields
      }
    }
  }
  ${MessageThreadListItem.fragments.MessageThreadListItemFields}
`;

export default Assistant;
