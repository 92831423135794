import { useCallback, useEffect, useState } from 'react';

import type { OnClickApplyOptions } from 'components/reports/filters/FilterMenu';
import type {
  FilterMenuOption,
  FilterMenuSection,
  WithPath,
} from 'components/reports/filters/types';
import { addPathToOptions, getAllLeavesForOptions } from 'components/reports/filters/utils';

import type { SectionAdapterOptions } from 'lib/filters/types';

type Options<TFilters, TData> = {
  data: TData;
  filters: TFilters;
  getFilterSectionsFromQuery: (
    data: TData,
    filters: TFilters,
    options: SectionAdapterOptions,
  ) => FilterMenuSection[];
  onApplyFilters: (
    filters: Partial<TFilters>,
    selectedOptions?: WithPath<FilterMenuOption>[],
  ) => void;
  adaptSelectedOptionsToFilters: (options: WithPath<FilterMenuOption>[]) => Partial<TFilters>;
} & Omit<SectionAdapterOptions, 'hasTransactionsImportedFromMint'>;

/**
 * Hook responsible for managing the state of filters in the FilterMenu component.
 * It doesn't dispatch any actions or mutates anything, it just provides the state and the
 * logic to calculate the filters to update based on the selected options.
 */
export const useFilterMenu = <TFilters, TData>(options: Options<TFilters, TData>) => {
  const {
    data,
    onApplyFilters,
    filters,
    renderAccountLogo,
    getFilterSectionsFromQuery,
    adaptSelectedOptionsToFilters,
  } = options;

  const [sections, setSections] = useState<FilterMenuSection[]>(
    getFilterSectionsFromQuery(data, filters, {}),
  );

  const recalculateSections = useCallback(
    (opts: { keepSelectedAsyncOptions?: boolean } = {}) => {
      const { keepSelectedAsyncOptions = true } = opts;

      const selectedOptions = sections.flatMap((section) =>
        getAllLeavesForOptions(section.options).filter(({ isSelected }) => isSelected),
      ) as WithPath<FilterMenuOption>[];

      const updated = getFilterSectionsFromQuery(data, filters, {
        renderAccountLogo,
      });

      /**
       * Recursively updates the selection state of a list of options and their nested options.
       *
       * This function traverses through the given options array, updating each option's `isSelected`
       * property based on whether it matches an option in the `selectedOptions` array (defined outside
       * this function's scope). If an option has nested options (indicated by an `options` property),
       * the function recursively updates these nested options as well.
       */
      const updateOptions = (options: WithPath<FilterMenuOption>[]) =>
        options.map((option) => {
          const selectedOption = selectedOptions.find(({ id }) => id === option.id);
          if (selectedOption) {
            option.isSelected = true;
          }

          // Recursively update nested options
          if (option.options && option.options.length > 0) {
            option.options = updateOptions(option.options);
          }

          return option;
        });

      updated.forEach((section) => {
        // Only do this for the merchant section, as it's the one we load
        // the items by demand (when the user types in the search input)
        // and have to consolidate the selected options with the new data.
        if (section.id !== 'merchants') {
          return;
        }

        if (keepSelectedAsyncOptions) {
          section.options = updateOptions(section.options);
        }
      });

      setSections(updated);
    },
    [data, filters, sections],
  );

  const resetUnappliedChanges = useCallback(() => {
    // When resetting unapplied changes, we should not keep any selected options
    // as the selected options will come from the filters itself
    recalculateSections({ keepSelectedAsyncOptions: false });
  }, [recalculateSections]);

  useEffect(
    () => {
      // Whenever data or filters change (e.g. review filter changes from the transactions
      // list dropdown), run this effect to update the sections so the menu always has the
      // latest data to render.
      recalculateSections();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, filters],
  );

  const handleApply = useCallback(
    (options?: OnClickApplyOptions) => {
      const { reset } = options ?? {};

      if (reset) {
        resetUnappliedChanges();
        onApplyFilters({} as TFilters);
        return;
      }

      const sectionsWithPath = addPathToOptions(sections, []);
      const selectedOptions = sectionsWithPath.flatMap((section) =>
        getAllLeavesForOptions(section.options).filter(({ isSelected }) => isSelected),
      );

      const filters = adaptSelectedOptionsToFilters(selectedOptions);
      onApplyFilters(filters, selectedOptions);
    },
    [onApplyFilters],
  );

  return {
    sections,
    onChangeSections: setSections,
    resetUnappliedChanges,
    handleApply,
  };
};

export default useFilterMenu;
