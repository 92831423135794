import type { PaymentMethod } from '@stripe/stripe-js';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import StripeCardInputForm from 'components/lib/external/StripeCardInputForm';
import StripeProvider from 'components/lib/external/StripeProvider';
import CheckCircle from 'components/lib/ui/CheckCircle';
import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import TaxLabel from 'components/lib/ui/TaxLabel';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import PrimaryButton, { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import PromoCodeInput from 'components/settings/billing/PromoCodeInput';
import SponsoredSubscriptionBanner from 'components/settings/billing/SponsoredSubscriptionBanner';
import SubscriptionOption from 'components/settings/billing/SubscriptionOption';

import { getYearlyBannerText } from 'common/lib/billing/Billing';
import { formatCurrency } from 'common/utils/Currency';
import { hasDatePassed, isoDateToAbbreviatedMonthDayAndYear, isSameDay } from 'common/utils/date';

import {
  PaymentPeriod,
  SubscriptionDetailsPaymentSource,
} from 'common/generated/graphQlTypes/globalTypes';
import type { Web_GetSubscriptionModalQuery } from 'common/generated/graphql';

const DEFAULT_DUE_TODAY_VALUE = formatCurrency(0);

const Root = styled.div``;

const CardSection = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
`;

const PaymentMethodSection = styled(CardSection)`
  padding: 0 ${({ theme }) => theme.spacing.large};
`;

const OptionalText = styled.span`
  color: ${({ theme }) => theme.color.textLight};
  font-weight: ${({ theme }) => theme.fontWeight.book};
`;

const Footer = styled(FlexContainer)`
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  padding: 0 0 ${({ theme }) => theme.spacing.large};
`;

const SubmitButton = styled(AsyncButton)`
  ${primaryButtonMixin}
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const SubscriptionSection = styled(CardSection)`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`;

const PaymentText = styled(Text)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const PaymentMethodDisclaimer = styled(Text)`
  display: block;
  padding-bottom: ${({ theme }) => theme.spacing.small};
`;

const AddPromoCodeToggle = styled(Text).attrs({
  weight: 'medium',
  size: 'small',
})<{ $isPromoCodeOpen: boolean }>`
  display: block;
  cursor: pointer;
  ${({ $isPromoCodeOpen, theme }) => $isPromoCodeOpen && `margin-bottom: ${theme.spacing.small};`}
`;

const PromoCodeSection = styled(CardSection)`
  padding: ${({ theme }) => theme.spacing.large} 0;
`;

const GrayTextButton = styled(TextButton)`
  margin-top: ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.color.textLight};
`;

const SponsoredSubscriptionBannerWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const PaymentMethodRow = styled(CardSection)`
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.large} 0;
`;

const PaymentMethodRowTitle = styled(Text).attrs({
  weight: 'medium',
  size: 'small',
})`
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  display: block;
`;

const ReactivationSummary = styled(PaymentMethodRow)`
  padding: ${({ theme }) => theme.spacing.large} 0;
`;

const ReactivationSummaryRow = styled(FlexContainer).attrs({
  justifyBetween: true,
})`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const SubscriptionConfirmedMessage = styled(Text).attrs({
  weight: 'medium',
  size: 'large',
})`
  padding: ${({ theme }) => theme.spacing.small};
`;

const StyledCheckCircle = styled(CheckCircle).attrs({
  iconSizePx: 30,
})`
  width: 60px;
  height: 60px;
`;

const StyledTaxLabel = styled(TaxLabel)`
  display: block;
  text-align: right;
`;

const StyledColumn = styled(Column)`
  text-align: right;
`;

type PaymentMethodBrandAndLastFour = {
  brand: string;
  lastFour: string;
};

type Props = {
  hasPremiumEntitlement: boolean;
  willCancelAtPeriodEnd: boolean;
  plans: Web_GetSubscriptionModalQuery['subscriptionOffering']['plans'][0][];
  promoCodeDescription: string | undefined | null;
  promoCodeError: string | undefined | null;
  daysLeftOfTrial: number | undefined | null;
  hasPaymentMethod: boolean;
  paymentSource: SubscriptionDetailsPaymentSource | undefined | null;
  currentBillingPeriod: PaymentPeriod | undefined | null;
  selectedPlanIndex: number;
  setSelectedPlanIndex: (i: number) => void;
  setLoading: () => void;
  setNotLoading: () => void;
  appliedPromoCode: string | null;
  setAppliedPromoCode: (code: string | null) => void;
  createSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  reactivateSubscription: (
    paymentMethod: PaymentMethod | null,
    stripePriceId: string,
    stripePromoCode: string | null,
  ) => Promise<void>;
  changeSubscription: (stripePriceId: string, stripePromoCode: string | null) => Promise<void>;
  hasStripeSubscriptionId: boolean;
  onClickCancelSubscription: () => void;
  onDone: () => void;
  isLoading: boolean;
  referralRedemption: Web_GetSubscriptionModalQuery['subscription']['referralRedemption'];
  paymentMethod: PaymentMethodBrandAndLastFour | null | undefined;
  nextPaymentAmount: number | null | undefined;
  currentPeriodEndsAt: string | null | undefined;
  trialEndsAt: string | null | undefined;
};

const SubscriptionModalBody = ({
  hasPremiumEntitlement,
  willCancelAtPeriodEnd,
  plans,
  selectedPlanIndex,
  setSelectedPlanIndex,
  promoCodeDescription,
  promoCodeError,
  daysLeftOfTrial,
  hasPaymentMethod,
  paymentSource,
  paymentMethod,
  currentBillingPeriod,
  isLoading,
  setLoading,
  setNotLoading,
  appliedPromoCode,
  createSubscription,
  reactivateSubscription,
  changeSubscription,
  hasStripeSubscriptionId,
  onClickCancelSubscription,
  setAppliedPromoCode,
  onDone,
  referralRedemption,
  currentPeriodEndsAt,
  nextPaymentAmount,
  trialEndsAt,
}: Props) => {
  const [subscriptionConfirmed, setSubscriptionConfirmed] = useState(false);

  const today = useMemo(() => DateTime.local(), []);

  const [isPromoCodeOpen, setIsPromoCodeOpen] = useState(!!appliedPromoCode);

  const selectedPlan = plans[selectedPlanIndex] ?? {};
  const hideCreditCardInput = hasPaymentMethod || !selectedPlan.requirePaymentMethod;
  const shouldShowPaymentMethod = hasStripeSubscriptionId && paymentMethod && hideCreditCardInput;

  const subscriptionPrice =
    selectedPlan.discountedPricePerPeriodDollars ?? selectedPlan.pricePerPeriodDollars;

  const isMobilePaymentSource =
    paymentSource && paymentSource !== SubscriptionDetailsPaymentSource.STRIPE;
  const shouldShowPaymentMethodDisclaimer = isMobilePaymentSource && !hideCreditCardInput;

  const successMessage = useMemo(() => {
    if (!hasPremiumEntitlement) {
      return 'Your subscription was successfully confirmed!';
    }

    if (willCancelAtPeriodEnd) {
      if (hasPaymentMethod && hasStripeSubscriptionId) {
        return 'Your subscription was successfully reactivated!';
      }
    }

    return 'Your subscription was successfully updated!';
  }, [hasPremiumEntitlement, willCancelAtPeriodEnd, hasPaymentMethod, hasStripeSubscriptionId]);

  const handleStripeCardInputFormSuccess = async (paymentInfo: PaymentMethod | null) => {
    const paymentMethodPresent = hasPaymentMethod || RA.isNotNil(paymentInfo);
    setLoading();
    try {
      const { stripeId: priceId } = selectedPlan;
      const maybePromoCode = promoCodeDescription ? appliedPromoCode : null;

      if (!hasPremiumEntitlement) {
        if (!paymentMethodPresent && selectedPlan.requirePaymentMethod) {
          throw Error('No card entered! Something is wrong with the given subscription');
        }
        await createSubscription(paymentInfo, priceId, maybePromoCode);
      } else if (willCancelAtPeriodEnd) {
        if (paymentMethodPresent && hasStripeSubscriptionId) {
          await reactivateSubscription(paymentInfo, priceId, maybePromoCode);
        } else {
          if (!paymentMethodPresent && selectedPlan.requirePaymentMethod) {
            throw Error('No card entered! Something is wrong with the given subscription');
          }
          await createSubscription(paymentInfo, priceId, maybePromoCode);
        }
      } else {
        await changeSubscription(priceId, maybePromoCode);
      }
    } finally {
      setNotLoading();
    }

    setSubscriptionConfirmed(true);
  };

  const userHasActiveReferralCampaign = !!referralRedemption?.campaign;

  const currentPeriodEndsAtDate = useMemo(
    () => DateTime.fromISO(currentPeriodEndsAt ?? ''),
    [currentPeriodEndsAt],
  );

  const submitButtonText =
    currentBillingPeriod === selectedPlan.period && !willCancelAtPeriodEnd
      ? 'Done'
      : `Confirm ${selectedPlan.period === PaymentPeriod.MONTHLY ? 'monthly' : 'annual'} subscription`;

  const billedOnDate = useMemo(() => {
    const date = new Date();
    daysLeftOfTrial && date.setDate(date.getDate() + daysLeftOfTrial);

    const newTrialEndsAt = selectedPlan?.newTrialEndsAt ?? trialEndsAt ?? date.toISOString();
    return isoDateToAbbreviatedMonthDayAndYear(currentPeriodEndsAt ?? newTrialEndsAt ?? '');
  }, [selectedPlan, trialEndsAt, currentPeriodEndsAt, daysLeftOfTrial]);

  return subscriptionConfirmed ? (
    <CardSection>
      <FlexContainer center column>
        <StyledCheckCircle />
        <SubscriptionConfirmedMessage>{successMessage}</SubscriptionConfirmedMessage>
        <Footer>
          <PrimaryButton onClick={onDone}>Done!</PrimaryButton>
        </Footer>
      </FlexContainer>
    </CardSection>
  ) : (
    <Root>
      <SubscriptionSection>
        {plans.map(
          (
            { name, pricePerPeriodDollars, discountedPricePerPeriodDollars, period, stripeId },
            i,
          ) => (
            <SubscriptionOption
              checked={selectedPlan.stripeId === stripeId}
              key={name}
              name={name}
              promoBadgeText={getYearlyBannerText(plans, period, {
                compareYearlyOnly: userHasActiveReferralCampaign,
              })}
              infoBadgeText={
                paymentSource === SubscriptionDetailsPaymentSource.STRIPE &&
                hasPremiumEntitlement &&
                currentBillingPeriod === period
                  ? 'current'
                  : undefined
              }
              price={pricePerPeriodDollars}
              discountedPrice={discountedPricePerPeriodDollars}
              period={period}
              onClick={() => setSelectedPlanIndex(i)}
            />
          ),
        )}
      </SubscriptionSection>

      <PaymentMethodSection>
        {!hideCreditCardInput && (
          <PaymentText weight="medium" size="small">
            Payment Method
          </PaymentText>
        )}
        {shouldShowPaymentMethodDisclaimer && (
          <PaymentMethodDisclaimer>
            Since your previous subscription was through the App Store, we don&apos;t have a payment
            saved for you.
          </PaymentMethodDisclaimer>
        )}

        <StripeCardInputForm
          skipPaymentMethod={hideCreditCardInput}
          onSuccess={handleStripeCardInputFormSuccess}
        >
          {({ isValid, isLoading: isStripeInputLoading }) => (
            <>
              <PromoCodeSection>
                <AddPromoCodeToggle
                  onClick={() => setIsPromoCodeOpen(!isPromoCodeOpen)}
                  $isPromoCodeOpen={isPromoCodeOpen}
                >
                  <Icon name="tag" /> Add a promo code <OptionalText>(optional)</OptionalText>{' '}
                  {isPromoCodeOpen ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
                </AddPromoCodeToggle>
                {isPromoCodeOpen && (
                  <PromoCodeInput
                    value={appliedPromoCode}
                    onChange={setAppliedPromoCode}
                    description={promoCodeDescription}
                    error={promoCodeError}
                  />
                )}
              </PromoCodeSection>
              {selectedPlan?.sponsoredBy && (
                <SponsoredSubscriptionBannerWrapper>
                  <SponsoredSubscriptionBanner
                    hideRevokeAccessButton
                    sponsorName={selectedPlan?.sponsoredBy?.name}
                    sponsorProfilePictureUrl={selectedPlan?.sponsoredBy?.profilePictureUrl}
                  />
                </SponsoredSubscriptionBannerWrapper>
              )}
              {shouldShowPaymentMethod && (
                <PaymentMethodRow>
                  <PaymentMethodRowTitle>Payment method</PaymentMethodRowTitle>
                  <Text>{`${capitalCase(paymentMethod?.brand || '')} ending in ${paymentMethod?.lastFour}`}</Text>
                </PaymentMethodRow>
              )}
              <ReactivationSummary>
                <ReactivationSummaryRow>
                  <Column>
                    <Text>Total due</Text>
                  </Column>
                  <StyledColumn>
                    <Text>{`${formatCurrency(subscriptionPrice)}`}</Text>
                    <StyledTaxLabel />
                  </StyledColumn>
                </ReactivationSummaryRow>
                <ReactivationSummaryRow>
                  <Column>
                    <Text>Billed on</Text>
                  </Column>
                  <Column>
                    <Text>{billedOnDate}</Text>
                  </Column>
                </ReactivationSummaryRow>
                <ReactivationSummaryRow>
                  <Column>
                    <Text>Due today</Text>
                  </Column>
                  <Column>
                    <Text>
                      {isSameDay(currentPeriodEndsAtDate, today) ||
                      hasDatePassed(currentPeriodEndsAtDate)
                        ? `${formatCurrency(subscriptionPrice)}`
                        : DEFAULT_DUE_TODAY_VALUE}
                    </Text>
                  </Column>
                </ReactivationSummaryRow>
              </ReactivationSummary>
              <Footer column>
                <SubmitButton
                  size="medium"
                  disabled={!isValid}
                  type="submit"
                  pending={isStripeInputLoading || isLoading}
                >
                  {submitButtonText}
                </SubmitButton>
                {hasPremiumEntitlement && !willCancelAtPeriodEnd ? (
                  <GrayTextButton onClick={onClickCancelSubscription}>
                    Cancel subscription
                  </GrayTextButton>
                ) : null}
              </Footer>
            </>
          )}
        </StripeCardInputForm>
      </PaymentMethodSection>
    </Root>
  );
};

const ProviderWrapper = (props: Props) => (
  <StripeProvider>
    <SubscriptionModalBody {...props} />
  </StripeProvider>
);

export default ProviderWrapper;
