import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ACTIVE_CLASS_NAME } from 'components/lib/ui/link/NavLink';
import AnnualUpsellModalCard from 'components/sidebar/AnnualUpsellModalCard';
import NavBarLink from 'components/sidebar/NavBarLink';

import { fontSize } from 'common/lib/theme/dynamic';
import { track } from 'lib/analytics/segment';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';

import { PurchaseEventNames, ReferralsEventNames } from 'common/constants/analytics';
import routes from 'constants/routes';

import type { Web_GetSidebarDataQuery } from 'common/generated/graphql';
import { PaymentPeriod } from 'common/generated/graphql';

const YellowLink = styled(NavBarLink)`
  color: ${({ theme }) => theme.color.yellow};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${fontSize.base};

  &.${ACTIVE_CLASS_NAME}, &:hover {
    color: ${({ theme }) => theme.color.yellow};
  }
`;

/**
 * This is separated out into its own component to ensure we only log an
 * impression for the feature flag when the user actually sees the button.
 */
interface SidebarYellowLinkButtonProps {
  plans?: Web_GetSidebarDataQuery['subscriptionOffering']['plans'];
}

const SidebarYellowLinkButton = ({ plans }: SidebarYellowLinkButtonProps) => {
  const isAnnualUpsellEnabled = useIsFeatureFlagOn('ab-test-annual-upsell-web', {
    trackImpression: true,
  });

  const annualUpsellCopy = useMemo(() => {
    if (plans) {
      const yearlyPlan = plans.find(({ period }) => period === PaymentPeriod.YEARLY);
      const monthlyPlan = plans.find(({ period }) => period === PaymentPeriod.MONTHLY);

      if (yearlyPlan && monthlyPlan) {
        const yearlyPrice = yearlyPlan.pricePerPeriodDollars;
        const monthlyPrice = monthlyPlan.pricePerPeriodDollars;

        const totalDiscount = 100 - Math.floor((yearlyPrice / (monthlyPrice * 12)) * 100);

        return `Get ${totalDiscount}% discount`;
      }
    }

    return '';
  }, [plans]);

  const [AnnualUpsellModal, { open: openAnnualUpsellModal, close: closeAnnualUpsellModal }] =
    useModal();

  return isAnnualUpsellEnabled ? (
    <>
      <YellowLink
        to="#"
        onClick={() => {
          openAnnualUpsellModal();
          track(PurchaseEventNames.PurchaseUpsellAnnualButtonClicked);
        }}
        iconName="gift"
      >
        {annualUpsellCopy}
      </YellowLink>
      <AnnualUpsellModal>
        <AnnualUpsellModalCard closeAnnualUpsellModal={closeAnnualUpsellModal} />
      </AnnualUpsellModal>
    </>
  ) : (
    <YellowLink
      to={routes.settings.referrals()}
      onClick={() => track(ReferralsEventNames.ButtonClicked)}
      iconName="gift"
    >
      {'Get 1 Month Free'}
    </YellowLink>
  );
};

export default SidebarYellowLinkButton;
