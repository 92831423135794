import type { ThemeType } from 'common/types/Styles';

export const ONBOARDING_TOUR_STEPS = (_: ThemeType) => {
  const imageBaseUrl = `https://monarch-static-assets.s3.amazonaws.com/onboarding/v2`;

  return [
    {
      title: 'See all your money in one place',
      subtitle: `Monarch's intuitive dashboard brings all of your accounts into one view, giving you a complete picture of your finances.`,
      image: {
        url: `${imageBaseUrl}/onboarding-tour-1.png`,
        height: 350,
      },
    },
    {
      title: 'Make the most of your cash flow',
      subtitle:
        'Analyze your spending and saving habits, create a budget, and make a plan to reach your financial goals faster.',
      image: {
        url: `${imageBaseUrl}/onboarding-tour-2.png`,
        height: 355,
      },
    },
    {
      title: 'See into your financial future',
      subtitle: `Use Monarch to predict when you'll hit your goals, and see how changes to your budget affect your finances over time.`,
      image: {
        url: `${imageBaseUrl}/onboarding-tour-3.png`,
        height: 328,
      },
    },
    {
      title: 'Keep the big picture in view',
      subtitle: `Track your goals and create a budget to achieve them. If you get off track, no problem! We'll help you get back on track when life happens.`,
      image: {
        url: `${imageBaseUrl}/onboarding-tour-4.png`,
        height: 254,
      },
    },
    {
      title: 'No ads, always private and secure',
      subtitle: `Monarch uses bank-level security, and never stores your financial credentials. We'll also never show you ads, ever.`,
      image: {
        url: `${imageBaseUrl}/onboarding-tour-5.png`,
        height: 296,
      },
    },
  ];
};

export enum CollaboratesOnFinancesValue {
  // Do not changes these values, match with the backend
  YesWithProfessional = 'yes_with_professional',
  YesWithPartner = 'yes_with_partner',
  YesWithPartnerAndProfessional = 'yes_with_partner_and_professional',
  NoJustMe = 'no',
}
