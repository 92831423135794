import * as R from 'ramda';

import type { Common_GetJointPlanningDataQuery } from 'common/generated/graphql';

export type PlanAmounts = {
  budgeted: number | null | undefined;
  actual: number | null | undefined;
  remaining?: number | null | undefined; // Only present if rollovers is enabled
  rollover?: number | null | undefined;
  rolloverType?: string;
  rolloverTargetAmount?: number | null;
  rolloverStartingBalance?: number | null;
  applyToFutureAmount?: number;
  loadingGroupBudget?: boolean;
  cumulativeActualAmount?: number | null;
};

export type PlanningAmountsByIdDate = {
  [itemId in string]: {
    [startDate in GraphQlDate]: PlanAmounts;
  };
};

export const getGoalsV2Amounts = (
  goals: Common_GetJointPlanningDataQuery['goalsV2'] = [],
): PlanningAmountsByIdDate => {
  const pairs = goals.map(({ id, plannedContributions, monthlyContributionSummaries }) => {
    const plannedIndexedByMonth = R.indexBy(R.prop('month'), plannedContributions);
    const summaryIndexedByMonth = R.indexBy(R.prop('month'), monthlyContributionSummaries);
    const merged = R.mergeDeepLeft(plannedIndexedByMonth, summaryIndexedByMonth);

    const planAmountsByDate = R.mapObjIndexed(
      ({ sum, amount: budgeted }): PlanAmounts => ({
        actual: sum,
        budgeted,
        remaining: (budgeted ?? 0) - (sum ?? 0),
      }),
      merged,
    );

    return [id, planAmountsByDate] as [string, Record<string, PlanAmounts>];
  });

  return R.fromPairs(pairs);
};
