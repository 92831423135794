import React from 'react';
import styled from 'styled-components';

import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Row from 'components/lib/ui/Row';
import TextButton from 'components/lib/ui/TextButton';

import { DELETE_HOUSEHOLD_BUTTON_TITLE } from 'common/constants/copy';

type Props = {
  onClickDeleteHousehold: () => void;
};

const StyledTextButton = styled(TextButton)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding-left: 0;
  margin: 0;
`;

const DeleteHouseholdRowContainer = styled(Row)`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const DeleteHouseholdRow = ({ onClickDeleteHousehold }: Props) => (
  <DeleteHouseholdRowContainer>
    <Column>
      <FlexContainer>
        <StyledTextButton onClick={onClickDeleteHousehold}>
          {DELETE_HOUSEHOLD_BUTTON_TITLE}
        </StyledTextButton>
      </FlexContainer>
    </Column>
  </DeleteHouseholdRowContainer>
);

export default DeleteHouseholdRow;
