import { mergeLeft } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

import { useArrayQueryParam, useQueryParam } from 'lib/hooks/useQueryParams';
import { DEFAULT_FILTERS } from 'lib/transactions/Filters';

import type { TransactionFilters, TransactionFiltersBooleanKeys } from 'types/filters';

// Using Record instead of just `TransactionFiltersBooleanKeys[]` to make all keys required.
const BOOLEAN_FILTERS: Record<TransactionFiltersBooleanKeys, true> = {
  hasAttachments: true,
  hasNotes: true,
  hideFromReports: true,
  importedFromMint: true,
  isSplit: true,
  isRecurring: true,
  syncedFromInstitution: true,
  needsReview: true,
  needsReviewUnassigned: true,
  isInvestmentAccount: true,
  isPending: true,
  isFlexSpending: true,
  isUncategorized: true,
  creditsOnly: true,
  debitsOnly: true,
};

const parseBoolean = (value: unknown) => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

/**
 * Hook alternative to useQueryParamFilters, but it includes falsy values (false, 0, etc.).
 * We preferred to create another hook instead of changing the behavior of useQueryParamFilters
 * to avoid breaking changes.
 *
 * @vanessa:
 * I'm aware this name is quite lengthy, but it's the most descriptive one I could come up with.
 * Eventually, we should remove useQueryParamFilters and rename this hook to useQueryParamFilters.
 */
const useQueryParamFiltersWithFalsyValues = (): TransactionFilters => {
  const absAmountGteQueryParam = useQueryParam('absAmountGte');
  const absAmountLteQueryParam = useQueryParam('absAmountLte');

  const search = useQueryParam('search') || undefined;
  const accounts = useArrayQueryParam('accounts') || undefined;
  const categories = useArrayQueryParam('categories') || undefined;
  const merchants = useArrayQueryParam('merchants') || undefined;
  const startDate = useQueryParam('startDate') || undefined;
  const endDate = useQueryParam('endDate') || undefined;
  const absAmountGte = absAmountGteQueryParam ? parseFloat(absAmountGteQueryParam) : undefined;
  const absAmountLte = absAmountLteQueryParam ? parseFloat(absAmountLteQueryParam) : undefined;
  const amountFilter = useQueryParam('amountFilter') || undefined;
  const isSplit = useQueryParam('isSplit') || undefined;
  const isRecurring = useQueryParam('isRecurring') || undefined;
  const hideFromReports = useQueryParam('hideFromReports') || undefined;
  const hasAttachments = useQueryParam('hasAttachments') || undefined;
  const hasNotes = useQueryParam('hasNotes') || undefined;
  const importedFromMint = useQueryParam('importedFromMint') || undefined;
  const syncedFromInstitution = useQueryParam('syncedFromInstitution') || undefined;
  const order = useQueryParam('order') || undefined;
  const tags = useArrayQueryParam('tags') || undefined;
  const needsReview = useQueryParam('needsReview') || undefined;
  const needsReviewByUser = useQueryParam('needsReviewByUser') || undefined;
  const needsReviewUnassigned = useQueryParam('needsReviewUnassigned') || undefined;
  const categoryGroups = useArrayQueryParam('categoryGroups') || undefined;
  const isInvestmentAccount = useQueryParam('isInvestmentAccount') || undefined;
  const isPending = useQueryParam('isPending') || undefined;
  const debitsOnly = useQueryParam('debitsOnly') || undefined;
  const creditsOnly = useQueryParam('creditsOnly') || undefined;
  const timeframeUnit = useQueryParam('timeframeUnit') || undefined;
  const timeframeValue = useQueryParam('timeframeValue') || undefined;
  const timeframeIncludeCurrent = useQueryParam('timeframeIncludeCurrent') === 'true';
  const timeframePeriod =
    timeframeUnit && timeframeValue
      ? {
          unit: timeframeUnit,
          value: parseInt(timeframeValue, 10),
          includeCurrent: timeframeIncludeCurrent,
        }
      : undefined;

  const result = {
    absAmountGte,
    absAmountLte,
    accounts,
    amountFilter,
    categories,
    endDate,
    hasAttachments,
    hasNotes,
    hideFromReports,
    importedFromMint,
    isSplit,
    isRecurring,
    merchants,
    needsReview,
    order,
    search,
    startDate,
    syncedFromInstitution,
    tags,
    needsReviewByUser,
    needsReviewUnassigned,
    categoryGroups,
    isInvestmentAccount,
    isPending,
    debitsOnly,
    creditsOnly,
    timeframePeriod,
  };

  const withParsedValues = Object.entries(result).reduce((acc, [key, value]) => {
    if (isNilOrEmpty(value)) {
      return acc;
    }

    const isBooleanFilter = BOOLEAN_FILTERS[key as TransactionFiltersBooleanKeys];
    return { ...acc, [key]: isBooleanFilter ? parseBoolean(value) : value };
  }, {});

  return mergeLeft(withParsedValues, DEFAULT_FILTERS);
};

export default useQueryParamFiltersWithFalsyValues;
