import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import SetupHeader from 'components/lib/ui/SetupHeader';

import boxShadow from 'common/lib/styles/boxShadow';
import { spacing } from 'common/lib/theme/dynamic';
import variables from 'common/lib/theme/variables';

import routes from 'constants/routes';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${variables.color.background.fullscreenHeader};
`;

const ContentContainer = styled(FlexContainer).attrs({ column: true })`
  max-width: 600px;
  width: 100%;
  flex-grow: 1;
`;

const FooterContainer = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  padding: ${spacing.large};
  position: sticky;
  bottom: 0;
  background-color: ${variables.color.background.primary};

  ${boxShadow.mediumTop}
`;

type Props = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  progress: number;
  onClickBack?: () => void;
  isLoading?: boolean;
  showBackButton?: boolean;
  overrideTitle?: string;
};

export type TransferAccountDataStepProps = Omit<Props, 'children' | 'footer'> & {
  onClickNext: () => void;
};

const TransferAccountDataStep = ({ children, footer, isLoading, ...props }: Props) => {
  const { push } = useHistory();

  return (
    <StyledPage
      name="Transfer Account Data"
      overrideTitle={props.overrideTitle}
      header={
        <SetupHeader
          title="Transfer Account Data"
          cancelButtonText="Exit"
          progress={props.progress}
          onClickBack={props.onClickBack ?? (() => push(routes.accounts()))}
          onClickCancel={() => push(routes.accounts())}
          showBackButton={props.showBackButton}
        />
      }
    >
      {isLoading ? (
        <FlexContainer center column full>
          <LoadingSpinner />
        </FlexContainer>
      ) : (
        <FlexContainer alignCenter column full>
          <ContentContainer>{children}</ContentContainer>
          {footer && (
            <FooterContainer alignCenter>
              <ContentContainer>{footer}</ContentContainer>
            </FooterContainer>
          )}
        </FlexContainer>
      )}
    </StyledPage>
  );
};

export default TransferAccountDataStep;
