import {
  formatDateRange,
  formatDateRangeWithRelativeTimeframe,
  resolveDateRangeWithRelativeTimeframe,
} from 'common/utils/date';

import type { DateRangeWithTimeframes } from 'common/types/DateRange';

export type ShortcutId =
  | 'last_7_days'
  | 'last_30_days'
  | 'this_month'
  | 'last_month'
  | 'this_year'
  | 'last_year';

export type Shortcut = DateRangeWithTimeframes & {
  id?: ShortcutId;
  title: string;
  description?: string;
};

export const EMPTY_DATE_RANGE_WITH_TIMEFRAMES: DateRangeWithTimeframes = {
  startDate: undefined,
  endDate: undefined,
  timeframeUnit: undefined,
  timeframeValue: undefined,
  includeCurrentPeriod: undefined,
};

export const DEFAULT_SHORTCUTS: Shortcut[] = [
  {
    id: 'last_7_days',
    title: 'Last 7 days',
    description: 'This date range will show the last 7 days including today.',
    startDate: undefined,
    endDate: undefined,
    timeframeUnit: 'day',
    timeframeValue: 7,
    includeCurrentPeriod: true,
  },
  {
    id: 'last_30_days',
    title: 'Last 30 days',
    description: 'This date range will show the last 30 days including today.',
    startDate: undefined,
    endDate: undefined,
    timeframeUnit: 'day',
    timeframeValue: 30,
    includeCurrentPeriod: true,
  },
  {
    id: 'this_month',
    title: 'This month',
    description: 'This date range will show the current month.',
    startDate: undefined,
    endDate: undefined,
    timeframeUnit: 'month',
    timeframeValue: 1,
    includeCurrentPeriod: true,
  },
  {
    id: 'last_month',
    title: 'Last month',
    description: 'This date range will show the previous month.',
    startDate: undefined,
    endDate: undefined,
    timeframeUnit: 'month',
    timeframeValue: 1,
    includeCurrentPeriod: false,
  },
  {
    id: 'this_year',
    title: 'This year',
    description: 'This date range will show the current year.',
    startDate: undefined,
    endDate: undefined,
    timeframeUnit: 'year',
    timeframeValue: 1,
    includeCurrentPeriod: true,
  },
  {
    id: 'last_year',
    title: 'Last year',
    description: 'This date range will show the previous year.',
    startDate: undefined,
    endDate: undefined,
    timeframeUnit: 'year',
    timeframeValue: 1,
    includeCurrentPeriod: false,
  },
];

/**
 * Returns a formatted date range not including shortcuts
 *
 * For example, "This month" will be formatted as "Jan 1 – 31, 2025"
 */
export const formatDateRangeWithRelativeTimeframeResolution = (
  dateRangeWithTimeframes: DateRangeWithTimeframes,
) => {
  const resolvedDateRange = resolveDateRangeWithRelativeTimeframe(
    dateRangeWithTimeframes.startDate,
    dateRangeWithTimeframes.endDate,
    dateRangeWithTimeframes.timeframeUnit,
    dateRangeWithTimeframes.timeframeValue,
    dateRangeWithTimeframes.includeCurrentPeriod,
  );

  return formatDateRange(resolvedDateRange.startDate, resolvedDateRange.endDate);
};

/**
 * Returns a formatted date range including shortcuts
 *
 * For example, "This month" will be formatted as "This month"
 */
export const formatDateRangeWithRelativeTimeframeOrShortcut = (
  dateRangeWithTimeframes: DateRangeWithTimeframes,
) => {
  const selectedShortcut = getShortcutByDateRange(dateRangeWithTimeframes);

  if (selectedShortcut) {
    return selectedShortcut.title;
  }

  return formatDateRangeWithRelativeTimeframe(
    dateRangeWithTimeframes.startDate,
    dateRangeWithTimeframes.endDate,
    dateRangeWithTimeframes.timeframeUnit,
    dateRangeWithTimeframes.timeframeValue,
    dateRangeWithTimeframes.includeCurrentPeriod,
  );
};

export const getShortcutByDateRange = (dateRange: DateRangeWithTimeframes) =>
  DEFAULT_SHORTCUTS.find((shortcut) => areDateRangesEqual(dateRange, shortcut));

export const getShortcutById = (id: string) =>
  DEFAULT_SHORTCUTS.find((shortcut) => shortcut.id === id);

export const areDateRangesEqual = (
  dateRangeA: DateRangeWithTimeframes,
  dateRangeB: DateRangeWithTimeframes,
) =>
  dateRangeA.startDate === dateRangeB.startDate &&
  dateRangeA.endDate === dateRangeB.endDate &&
  dateRangeA.timeframeUnit === dateRangeB.timeframeUnit &&
  dateRangeA.timeframeValue === dateRangeB.timeframeValue &&
  dateRangeA.includeCurrentPeriod === dateRangeB.includeCurrentPeriod;
