import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';

const useRecommendedDataTransferDates = (
  fromAccountId?: string | null,
  toAccountId?: string | null,
) => {
  const { data, isNetworkRequestInFlight: loading } = useQuery(GET_RECOMMENDED_DATES_QUERY, {
    skip: !fromAccountId || !toAccountId,
    variables: { fromAccountId, toAccountId },
  });
  return { data: data?.recommendedAccountDataTransferDates, loading };
};

const GET_RECOMMENDED_DATES_QUERY = gql(/* GraphQL */ `
  query Web_RecommendedAccountDataTransferDates($fromAccountId: ID, $toAccountId: ID) {
    recommendedAccountDataTransferDates(fromAccountId: $fromAccountId, toAccountId: $toAccountId) {
      transactionsStartDate
      transactionsEndDate
      snapshotsStartDate
      snapshotsEndDate
    }
  }
`);

export default useRecommendedDataTransferDates;
