import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import NetWorthPerformanceChart from 'components/accounts/NetWorthPerformanceChart';
import Select from 'components/lib/form/Select';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import TimeframeTrendIndicator from 'components/lib/ui/TimeframeTrendIndicator';
import { GET_AGGREGATE_SNAPSHOTS } from 'components/routes/Accounts';

import {
  getDateRanges,
  getSnapshotDataForSnapshotGraph,
  getSnapshotDataForTimePeriod,
} from 'common/lib/accounts/accountCharts';
import { getNetWorthBalancesForPerformanceChart } from 'common/lib/accounts/netWorthCharts';
import { getAccountsDashboardWidgetTitle } from 'common/lib/dashboard';
import useQuery from 'common/lib/hooks/useQuery';
import { fontSize } from 'common/lib/theme/dynamic';
import useMeasure from 'lib/hooks/useMeasure';

import routes from 'constants/routes';

const ROOT_PADDING_PX = 32;

const Body = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacing.xlarge};
`;

const StyledSelect = styled(Select)`
  min-width: 150px;
`;

const NetWorthPerformanceChartWrapper = styled.div`
  padding-left: ${({ theme }) => `${theme.spacing.default}`};
  padding-right: ${({ theme }) => `${theme.spacing.default}`};
`;

const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  font-size: ${fontSize.base};
`;

const AccountsDashboardWidget = () => {
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();

  const dateRanges = useMemo(() => getDateRanges(), []);

  const [selectedDateRange, setSelectedDateRange] = useState(dateRanges[0]);

  const {
    data: aggregateSnapshotsData,
    isLoadingInitialData: isLoadingAggregateSnapshots,
    isNetworkRequestInFlight: isAggregateSnapshotsNetworkRequestInFlight,
  } = useQuery(GET_AGGREGATE_SNAPSHOTS, {
    variables: {
      filters: {
        startDate: DateTime.local().minus(selectedDateRange.duration).toISODate(),
        endDate: null,
        accountType: null,
        useAdaptiveGranularity: true,
      },
    },
  });

  const { snapshots: aggregateSnapshots } = useMemo(
    () => getSnapshotDataForSnapshotGraph(aggregateSnapshotsData?.aggregateSnapshots ?? [], true),
    [aggregateSnapshotsData],
  );

  const lineChartData = useMemo(
    () =>
      aggregateSnapshots.length
        ? getSnapshotDataForTimePeriod(
            selectedDateRange?.duration ?? dateRanges[0].duration,
            aggregateSnapshots,
          ).data
        : [],
    [selectedDateRange, aggregateSnapshots],
  );

  const [previousNetWorth, currentNetWorth] = useMemo(
    () => getNetWorthBalancesForPerformanceChart(lineChartData, 'balance'),
    [lineChartData],
  );

  const chartWidth = useMemo(() => width - ROOT_PADDING_PX, [width]);

  const contextualMenuOptions = useMemo(
    () =>
      getDateRanges().map((dateRange) => ({
        value: dateRange.display,
        label: dateRange.longDisplay,
        onClick: () => setSelectedDateRange(dateRange),
      })),
    [setSelectedDateRange],
  );

  const isLoading = isLoadingAggregateSnapshots || isAggregateSnapshotsNetworkRequestInFlight;

  return (
    <DashboardWidget
      title={getAccountsDashboardWidgetTitle({ isLoading, currentNetWorth })}
      loading={isLoading}
      description={
        !isLoading && (
          <StyledTimeframeTrendIndicator
            startAmount={previousNetWorth}
            endAmount={currentNetWorth}
            isAsset
          />
        )
      }
      headerLink={routes.accounts()}
      showHeaderBorder
      headerRight={
        <StyledSelect
          small
          options={contextualMenuOptions}
          value={selectedDateRange.display}
          onChange={({ value }: { value: string }) =>
            setSelectedDateRange(dateRanges.find((r) => r.display === value) ?? dateRanges[0])
          }
          isDisabled={isLoadingAggregateSnapshots}
        />
      }
    >
      <Body ref={ref}>
        <NetWorthPerformanceChartWrapper>
          <NetWorthPerformanceChart
            title="Net Worth"
            aggregateSnapshotsData={aggregateSnapshotsData?.aggregateSnapshots ?? []}
            isAggreateSnapShotsNetworkRequestInFlight={isAggregateSnapshotsNetworkRequestInFlight}
            selectedAccountType={null}
            selectedDateRange={selectedDateRange}
            isAsset
            width={chartWidth}
            yDataKey="balance"
          />
        </NetWorthPerformanceChartWrapper>
      </Body>
    </DashboardWidget>
  );
};

export default AccountsDashboardWidget;
