import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import SetupContentHeader from 'components/lib/ui/SetupContentHeader';
import SetupHeader from 'components/lib/ui/SetupHeader';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import variables from 'common/lib/theme/variables';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${({ theme }) => variables.color.background.fullscreenHeader({ theme })};
`;

const NextButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: 25vh;
`;

export type FeatureOnboardingPageProps = {
  /** Only used for event tracking purposes. */
  pageName: string;
  title: string;
  headerMaxWidth?: number;
  description: React.ReactNode;
  descriptionMaxWidth?: number;
  progress: number;
  onClickBack: () => void;
  onClickNext?: () => void;
  onClickCancel?: () => void;
  children?: React.ReactNode;
  hideNextButton?: boolean;
  nextButtonText?: string;
  cancelButtonText?: string;
  header?: React.ReactNode;
  isLoading?: boolean;
};

const FeatureOnboardingPage: React.FC<FeatureOnboardingPageProps> = ({
  pageName,
  title,
  headerMaxWidth,
  description,
  descriptionMaxWidth,
  progress,
  onClickBack,
  onClickNext,
  onClickCancel,
  nextButtonText,
  cancelButtonText,
  hideNextButton,
  children,
  header,
  isLoading,
}) => (
  <StyledPage
    name={pageName}
    header={
      <SetupHeader
        onClickBack={onClickBack}
        progress={progress}
        onClickCancel={onClickCancel}
        cancelButtonText={cancelButtonText}
      />
    }
  >
    {isLoading ? (
      <FlexContainer alignCenter column full>
        <StyledLoadingSpinner />
      </FlexContainer>
    ) : (
      <FlexContainer center column>
        <SetupContentHeader
          header={header}
          title={title}
          description={description}
          descriptionMaxWidth={descriptionMaxWidth}
          maxWidth={headerMaxWidth}
        />
        {children}
        {!hideNextButton && (
          <NextButton size="large" onClick={onClickNext}>
            {nextButtonText || 'Continue'}
          </NextButton>
        )}
      </FlexContainer>
    )}
  </StyledPage>
);

export default FeatureOnboardingPage;
