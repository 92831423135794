import { gql } from '@apollo/client';
import { transparentize } from 'polished';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import { FiEyeOff } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import AccountLogo from 'components/accounts/AccountLogo';
import AccountMask from 'components/accounts/AccountMask';
import AccountSparklines from 'components/accounts/AccountSparklines';
import InstitutionStatusTooltip from 'components/institutions/InstitutionStatusTooltip';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import DragDots from 'components/lib/ui/DragDots';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import RelativeDate from 'components/lib/ui/RelativeDate';
import StatusIndicator from 'components/lib/ui/StatusIndicator';
import Tooltip from 'components/lib/ui/Tooltip';

import { isSyncDisabledWithReason } from 'common/lib/credentials/adapters';
import { spacing } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';
import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';
import useTheme from 'lib/hooks/useTheme';

import { SyncDisabledReason } from 'common/constants/credential';
import { HIDDEN_ACCOUNT_BALANCE_FROM_NET_WORTH_TEXT } from 'constants/copy';
import routes from 'constants/routes';

import type { AccountListItemFieldsFragment } from 'common/generated/graphql';
import { ContactSupportTopic } from 'common/generated/graphql';

const LOGO_SIZE_V2 = 40;

const Root = styled.div<{ $isLast?: boolean; $isDragging?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.small};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${spacing.default} ${spacing.large};
  padding-left: 0;
  color: inherit;
  background-color: ${({ theme }) => theme.color.white};
  transition: ${({ theme }) => theme.transition.default};
  box-shadow: ${({ theme, $isDragging }) =>
    $isDragging
      ? `0 8px 16px ${transparentize(0.95, theme.color.black)};`
      : `0 4px 8px transparent`};

  &:hover {
    color: inherit;
    background-color: ${({ theme }) => theme.color.grayLightBackground};
  }

  ${({ theme, $isLast }) =>
    $isLast &&
    css`
      border-bottom: none;
      border-radius: 0 0 ${theme.radius.medium} ${theme.radius.medium};
    `}
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const SubText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  margin-top: 0;
`;

const StatusSubText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const AccountInfoAndSparklinesCell = styled(Flex)`
  flex-grow: 1;
  align-items: flex-start;
`;

const AccountBalanceCell = styled(Flex)`
  width: 170px;
  justify-content: flex-end;
`;

const AccountInfo = styled(Flex)`
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing.default};
  align-items: center;
`;

const EyeOffIcon = styled(FiEyeOff).attrs({ size: 16 })`
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const StyledNavLink = styled(NavLink)`
  padding-left: 0;

  &:hover {
    cursor: pointer;
  }
`;

const StyledDragDots = styled(DragDots)<{ $isHidden: boolean }>`
  align-self: center;
  cursor: move;
  opacity: 0;
  transition: ${({ theme }) => theme.transition.default};
  margin-left: 0;
  margin-right: ${spacing.xxxsmall};

  ${Root}:hover &, ${Root}:active & {
    opacity: 1;
  }

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      cursor: unset;
      opacity: 0 !important;
    `}
`;

type Props = {
  account: AccountListItemFieldsFragment;
  recentBalances?: number[];
  className?: string;
  isLast?: boolean;
  isDragging?: boolean;
  isDraggable?: boolean;
};

const AccountListItem = ({
  account,
  recentBalances = [],
  className,
  isLast,
  isDragging,
  isDraggable = true,
}: Props) => {
  const { openContactSupportModal } = useContactSupportContext();

  const { displayName, displayBalance, displayLastUpdatedAt, credential, institution, isAsset } =
    account;
  const theme = useTheme();

  // Only show sparklines if more than one recentBalance
  const showSparklines = R.filter(RA.isNotNil, recentBalances).length > 1;

  return (
    <>
      <Root
        as={StyledNavLink}
        to={routes.accounts.accountDetails({ id: account.id })}
        className={className}
        $isLast={isLast}
        $isDragging={isDragging}
      >
        <StyledDragDots $isHidden={account.isHidden || !isDraggable} />
        <AccountInfoAndSparklinesCell>
          <AccountInfo>
            <AccountLogo logoUrl={account.logoUrl} icon={account.icon} size={LOGO_SIZE_V2} />
            <Flex column>
              <Text {...sensitiveClassProps}>{displayName}</Text>
              <SubText>{account.subtype?.display}</SubText>
            </Flex>
          </AccountInfo>
          {showSparklines && <AccountSparklines balances={recentBalances} isAsset={isAsset} />}
        </AccountInfoAndSparklinesCell>
        <AccountBalanceCell>
          <Flex column alignEnd justifyCenter>
            <Text {...sensitiveClassProps}>
              {!account.includeInNetWorth && (
                <Tooltip content={HIDDEN_ACCOUNT_BALANCE_FROM_NET_WORTH_TEXT}>
                  <EyeOffIcon />
                </Tooltip>
              )}
              {!R.isNil(displayBalance) ? formatCurrency(displayBalance) : '?'}
            </Text>
            <StatusSubText>
              <Flex alignCenter>
                <Tooltip
                  place="top"
                  effect="solid"
                  clickable
                  content={
                    institution &&
                    credential && (
                      <InstitutionStatusTooltip
                        institution={institution}
                        openReportIssueModal={() =>
                          openContactSupportModal({
                            topic: ContactSupportTopic.CONNECTION_ISSUE,
                            credentialId: credential.id,
                          })
                        }
                        credential={credential}
                        syncDisabled={account.syncDisabled}
                      />
                    )
                  }
                  maxWidth={InstitutionStatusTooltip.WIDTH_PX}
                  opacity={1}
                >
                  <FlexContainer justifyCenter>
                    <Switch>
                      <Case
                        when={
                          Boolean(account.syncDisabled) ||
                          Boolean(institution?.newConnectionsDisabled)
                        }
                      >
                        <FlexContainer alignCenter column>
                          <StatusIndicator color={theme.color.gray}>
                            <RelativeDate date={displayLastUpdatedAt} />
                          </StatusIndicator>
                        </FlexContainer>
                      </Case>
                      <Case
                        when={
                          Boolean(credential?.updateRequired) ||
                          isSyncDisabledWithReason(credential, SyncDisabledReason.InactiveUser)
                        }
                      >
                        <FlexContainer alignCenter>
                          <StatusIndicator color={theme.color.red}>
                            <RelativeDate date={displayLastUpdatedAt} />
                          </StatusIndicator>
                        </FlexContainer>
                      </Case>

                      <Case default>
                        <RelativeDate date={displayLastUpdatedAt} />
                      </Case>
                    </Switch>
                  </FlexContainer>
                </Tooltip>
              </Flex>
            </StatusSubText>
          </Flex>
        </AccountBalanceCell>
      </Root>
    </>
  );
};

AccountListItem.fragments = {
  AccountListItemFields: gql`
    fragment AccountListItemFields on Account {
      id
      displayName
      displayBalance
      signedBalance
      updatedAt
      syncDisabled
      icon
      logoUrl
      isHidden
      isAsset
      includeInNetWorth
      includeBalanceInNetWorth
      displayLastUpdatedAt
      ...AccountMaskFields
      credential {
        id
        updateRequired
        dataProvider
        disconnectedFromDataProviderAt
        syncDisabledAt
        syncDisabledReason
      }
      institution {
        id
        ...InstitutionStatusTooltipFields
      }
    }
    ${InstitutionStatusTooltip.fragments.InstitutionStatusTooltipFields}
    ${AccountMask.fragments.AccountMaskFields}
  `,
};

export default AccountListItem;
