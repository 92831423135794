import { gql } from '@apollo/client';
import { Widget } from '@typeform/embed-react';
import * as R from 'ramda';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import AdviceCategoriesMenu from 'components/advice/AdviceCategoriesMenu';
import AdviceItemDrawer from 'components/advice/AdviceItemDrawer';
import AdviceItemList from 'components/advice/AdviceItemList';
import AdviceTaskDrawer from 'components/advice/AdviceTaskDrawer';
import Card from 'components/lib/ui/Card';
import Controls from 'components/lib/ui/Controls';
import EmptyCreateCard from 'components/lib/ui/EmptyCreateCard';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import HelpIconTooltip from 'components/lib/ui/HelpIconTooltip';
import Page from 'components/lib/ui/Page';
import type { ScrollHandles } from 'components/lib/ui/Scroll';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useMockedQueryData } from 'common/lib/hooks/useMockableQuery';
import useQuery from 'common/lib/hooks/useQuery';
import { spacing } from 'common/lib/theme/dynamic';
import { getUserEmail, getUserId } from 'common/state/user/selectors';
import { getQuestionnaireRoute } from 'lib/advice/questionnaire';
import typewriter from 'lib/analytics/typewriter';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import { useQueryParam } from 'lib/hooks/useQueryParams';
import { getForceEmptyState } from 'selectors';

import { AdviceItemType, AdviceQuestionnaireName, HOME_CATEGORY } from 'constants/advice';
import * as COPY from 'constants/copy';
import routes from 'constants/routes';

import type {
  AdviceQuery_Web,
  AdviceQuery_WebVariables,
} from 'common/generated/graphQlTypes/AdviceQuery_Web';
import type RouteProps from 'types/RouteProps';

const ADVISOR_DIRECTORY_TYPEFORM_SURVEY_ID = 'esE5J46r';

const InfoTooltip = styled(HelpIconTooltip).attrs({ sizePx: 16 })`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: ${spacing.default};
  margin-left: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledGrid = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing.default};
`;

const SectionElement = styled(Element)`
  :not(:last-child) {
    margin-bottom: var(--spacing);
  }
`;

const Advice = ({
  match: {
    params: { category: categoryName },
  },
}: RouteProps<typeof routes.advice>) => {
  const { push } = useHistory();
  const isHome = categoryName === HOME_CATEGORY;

  const email = useSelector(getUserEmail);
  const userId = useSelector(getUserId) || '';
  const showFindAdvisorSurvey = useIsFeatureFlagOn('show-advisor-directory-in-product', {
    trackImpression: true,
  });

  const forceEmptyState = useSelector(getForceEmptyState);
  const scrollElement = useQueryParam('scroll');
  const scrollRef = useRef<ScrollHandles>(null);

  const { data: realData, isLoadingInitialData } = useQuery<
    AdviceQuery_Web,
    AdviceQuery_WebVariables
  >(QUERY, {
    variables: {
      categoryName: isHome ? undefined : categoryName,
    },
  });
  const showEmptyState =
    forceEmptyState || (!isLoadingInitialData && !realData?.profileQuestionnaire?.state.completed);
  const [mockData] = useMockedQueryData<AdviceQuery_Web, AdviceQuery_WebVariables>(QUERY, {
    skip: !showEmptyState,
  });
  const data = showEmptyState ? mockData : realData;

  const {
    essentials,
    objectives,
    adviceItemCategories = [],
    profileQuestionnaire,
    objectivesQuestionnaire,
  } = data ?? {};
  const { state, firstQuestion } = profileQuestionnaire ?? {};
  const { numQuestionsAnswered } = state ?? {};
  const { firstQuestion: objectivesFirstQuestion } = objectivesQuestionnaire ?? {};

  const category = useMemo(
    () => (categoryName ? adviceItemCategories.find(R.propEq('name', categoryName)) : undefined),
    [categoryName, adviceItemCategories],
  );

  const sections = useMemo(
    () => [
      {
        type: AdviceItemType.Objective,
        title: 'Prioritized by you',
        tooltip: COPY.ADVICE.TOOLTIPS.PRIORITIES,
        items: objectives ?? [],
      },
      {
        type: AdviceItemType.Essential,
        title: 'Essential advice',
        tooltip: COPY.ADVICE.TOOLTIPS.ESSENTIALS,
        items: essentials ?? [],
      },
    ],
    [essentials, objectives],
  );

  useEffect(() => {
    if (scrollElement && !isLoadingInitialData) {
      scrollRef.current?.scrollTo(scrollElement);
    }
  }, [scrollElement, isLoadingInitialData]);

  useEffect(() => {
    categoryName && typewriter.viewedAdviceCategoryPage({ name: categoryName });
  }, [categoryName]);

  if (showFindAdvisorSurvey) {
    const hiddenVariables = {
      user_id: userId,
      email,
    };
    return (
      <Page name="Advice">
        <Widget
          id={ADVISOR_DIRECTORY_TYPEFORM_SURVEY_ID}
          hidden={hiddenVariables}
          style={{ height: '100%' }}
        />
      </Page>
    );
  }

  return (
    <Page
      name="Advice"
      isLoading={isLoadingInitialData}
      controls={
        <Controls>
          {firstQuestion && !showEmptyState && (
            <PrimaryButton
              linkTo={getQuestionnaireRoute(AdviceQuestionnaireName.General, firstQuestion.name)}
            >
              Update profile
            </PrimaryButton>
          )}
        </Controls>
      }
      overlayEmptyComponent={
        showEmptyState ? (
          <EmptyCreateCard
            icon="thumbs-up"
            title={COPY.ADVICE.EMPTY.TITLE}
            subtitle={COPY.ADVICE.EMPTY.SUBTITLE}
            button={{
              gradient: true,
              text: (numQuestionsAnswered ?? 0) > 0 ? 'Resume' : 'Get started',
              onClick: () =>
                push(
                  routes.advice.questionnaireIntro({
                    questionnaireName: AdviceQuestionnaireName.General,
                  }),
                ),
            }}
          />
        ) : undefined
      }
      scrollRef={scrollRef}
    >
      <Title>
        {category ? category.displayName : 'Recommendations'}
        {category && <InfoTooltip tooltip={category.description} place="bottom" />}
      </Title>
      <StyledGrid template={`"advice categories" / 1fr 30%`} md={`"advice" "categories"`}>
        <GridItem area="advice">
          {sections.map(({ type, title, tooltip, items }) => (
            <SectionElement key={type} name={type}>
              <Card
                title={
                  <FlexContainer alignCenter>
                    <Text>{title}</Text>
                    {!!tooltip && <InfoTooltip tooltip={tooltip} />}
                  </FlexContainer>
                }
                controls={
                  type === AdviceItemType.Objective &&
                  objectivesFirstQuestion && (
                    <TextButton
                      onClick={() =>
                        push(
                          getQuestionnaireRoute(
                            AdviceQuestionnaireName.Objectives,
                            objectivesFirstQuestion.name,
                          ),
                        )
                      }
                    >
                      Edit
                    </TextButton>
                  )
                }
              >
                <AdviceItemList items={items} />
              </Card>
            </SectionElement>
          ))}
        </GridItem>
        <GridItem area="categories" sticky>
          <Card title="Categories">
            <AdviceCategoriesMenu categories={adviceItemCategories} />
          </Card>
        </GridItem>
      </StyledGrid>
      <Route path={routes.advice.itemDetails.path} exact component={AdviceItemDrawer} />
      <Route path={routes.advice.taskDetails.path} exact component={AdviceTaskDrawer} />
      <Route
        path="/advice"
        exact
        render={() => <Redirect to={routes.advice({ category: HOME_CATEGORY })} />}
      />
    </Page>
  );
};

const QUERY = gql`
  query AdviceQuery_Web($categoryName: String) {
    essentials: adviceItems(group: "essential", category: $categoryName) {
      id
      ...AdviceItemListFields
    }
    objectives: adviceItems(group: "objective", category: $categoryName) {
      id
      ...AdviceItemListFields
    }
    adviceItemCategories {
      name
      displayName
      description
    }
    profileQuestionnaire(name: "general") {
      state {
        numQuestionsAnswered
        completed
      }
      firstQuestion {
        name
      }
    }
    objectivesQuestionnaire: profileQuestionnaire(name: "objectives") {
      firstQuestion {
        name
      }
    }
  }
  ${AdviceItemList.fragments.AdviceItemListFields}
`;

export default Advice;
