import React from 'react';
import styled from 'styled-components';

import CheckCircle from 'components/lib/ui/CheckCircle';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

type Props = {
  fromAccountId: string;
  toAccountId: string;
  next: (params: { toAccountId: string }) => void;
  goBack?: () => void;
};

const StyledCheckCircle = styled(CheckCircle).attrs({
  iconSizePx: 30,
})`
  width: 60px;
  height: 60px;
`;

const Results = (props: Props) => (
  <TransferAccountDataStep
    overrideTitle="Results"
    progress={5}
    onClickBack={props.goBack}
    showBackButton={false}
    footer={
      <PrimaryButton size="large" onClick={() => props.next({ toAccountId: props.toAccountId })}>
        View new account
      </PrimaryButton>
    }
  >
    <FlexContainer center column marginTop="xxlarge" gap="large">
      <StyledCheckCircle />
      <Text size="xlarge" weight="bold">
        Your data has been transferred!
      </Text>
      <FlexContainer column gap="small">
        <Text align="center">
          Check out your <Text>new account</Text> to ensure everything looks correct. If it does,
          you can delete your old account.
        </Text>
        <Text align="center">
          Reminder: if anything doesn&apos;t look right, you can reference the CSVs you downloaded
          before the transfer.
        </Text>
      </FlexContainer>
    </FlexContainer>
  </TransferAccountDataStep>
);

export default Results;
