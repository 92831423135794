import styled from 'styled-components';

import avatarV2 from 'static/images/avatar-v2@2x.png';

const DEFAULT_SIZE_PX = 24;

const Avatar = styled.div.attrs({
  referrerpolicy: 'no-referrer', // needed for Google
})<{
  $size?: number;
  $url?: Maybe<string>;
}>`
  height: ${({ $size }) => $size ?? DEFAULT_SIZE_PX}px;
  width: ${({ $size }) => $size ?? DEFAULT_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  background: ${({ $url }) => `url('${$url ?? avatarV2}')`};
  background-size: contain;
`;

export default Avatar;
