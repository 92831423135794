import React from 'react';
import type { ElementType, ReactNode } from 'react';

import { MEMBERS } from 'common/constants/copy';

export enum OwnerRestrictedAccessMessageVariant {
  AddMember = 'addMember',
  RemoveMember = 'removeMember',
  RemoveInvite = 'removeInvite',
}

type OwnerRestrictedAccessMessageProps = {
  ownerNameEl: ReactNode;
  TextComponent: ElementType;
  variant: OwnerRestrictedAccessMessageVariant;
};

const OwnerRestrictedAccessMessage = ({
  ownerNameEl,
  TextComponent,
  variant,
}: OwnerRestrictedAccessMessageProps) => (
  <>
    <TextComponent>{MEMBERS.OWNER_RESRICTED[variant].LINE_1}</TextComponent>
    <TextComponent>
      {MEMBERS.OWNER_RESRICTED.LINE_2_A} {ownerNameEl} {MEMBERS.OWNER_RESRICTED[variant].LINE_2_B}
    </TextComponent>
  </>
);

export default OwnerRestrictedAccessMessage;
