import React, { useCallback, useMemo } from 'react';

import DateRangeWithTimeframesPopover from 'components/lib/dates/DateRangeWithTimeframesPopover';
import WithIndicatorContainer from 'components/lib/ui/WithIndicatorContainer';

import {
  areDateRangesEqual,
  EMPTY_DATE_RANGE_WITH_TIMEFRAMES,
  formatDateRangeWithRelativeTimeframeOrShortcut,
} from 'common/lib/dateRange/dateRangeWithTimeframes';

import type { DateRangeWithTimeframes } from 'common/types/DateRange';

type Props = {
  dateRange: DateRangeWithTimeframes;
  defaultRange: DateRangeWithTimeframes;
  requiredBoundedRange?: boolean;
  onChangeDateRange: (dateRange: DateRangeWithTimeframes) => void;
};

const DateRangeWithTimeframesPickerButton = ({
  dateRange,
  defaultRange,
  onChangeDateRange,
}: Props) => {
  const isDifferentFromDefaultRange = useMemo(
    () => !areDateRangesEqual(dateRange, defaultRange),
    [dateRange, defaultRange],
  );

  const pickerButtonLabel = useMemo(
    () =>
      isDifferentFromDefaultRange
        ? formatDateRangeWithRelativeTimeframeOrShortcut(dateRange)
        : 'Date',
    [dateRange, isDifferentFromDefaultRange],
  );

  const clearDateRange = useCallback(
    () => onChangeDateRange(EMPTY_DATE_RANGE_WITH_TIMEFRAMES),
    [onChangeDateRange],
  );

  return (
    <WithIndicatorContainer show={isDifferentFromDefaultRange}>
      <DateRangeWithTimeframesPopover
        dateRange={dateRange}
        value={pickerButtonLabel}
        onChange={onChangeDateRange}
        onClear={clearDateRange}
      />
    </WithIndicatorContainer>
  );
};

export default DateRangeWithTimeframesPickerButton;
