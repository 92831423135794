import React from 'react';
import { useHistory } from 'react-router-dom';

import AccountSelection from 'components/routes/accounts/transferAccountData/steps/AccountSelection';
import Backup from 'components/routes/accounts/transferAccountData/steps/Backup';
import Results from 'components/routes/accounts/transferAccountData/steps/Results';
import Review from 'components/routes/accounts/transferAccountData/steps/Review';
import Snapshots from 'components/routes/accounts/transferAccountData/steps/Snapshots';
import Transactions from 'components/routes/accounts/transferAccountData/steps/Transactions';
import Flow from 'components/utils/Flow';

import routes from 'constants/routes';

import type RouteProps from 'types/RouteProps';

export type Props = RouteProps<typeof routes.accounts.transferAccountData>;

const TransferAccountData = ({
  match: {
    params: { id },
  },
}: Props) => {
  const { push, replace } = useHistory();

  return (
    <Flow
      steps={[AccountSelection, Transactions, Snapshots, Backup, Review, Results]}
      initialProps={{
        fromAccountId: id,
      }}
      onComplete={({ toAccountId }: { toAccountId: string }) => {
        replace(routes.accounts.accountDetails({ id: toAccountId }));
      }}
      onBack={() => {
        push(routes.accounts.accountDetails({ id }));
      }}
    />
  );
};

export default TransferAccountData;
