import React, { useMemo } from 'react';
import styled from 'styled-components';

import AnimatedCurrency from 'components/lib/ui/AnimatedCurrency';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';
import type { AmountsTooltipRowProps } from 'components/plan/AmountsTooltip';
import AmountsTooltip, { WIDTH_PX as TOOLTIP_WIDTH_PX } from 'components/plan/AmountsTooltip';

import { formatBudgetAmount } from 'common/lib/budget/Amounts';
import type { SummaryDisplayBudgetType } from 'common/lib/budget/Summary';
import { EXCESS_BUDGET_TYPE } from 'common/lib/budget/constants';
import { fontWeight, radius, spacing } from 'common/lib/theme/dynamic';

import type { WithTheme } from 'types/utility';

const TOOLTIP_SHOW_DELAY_MS = 500;

type RootProps = {
  $isNegative: boolean;
  $isZero: boolean;
};

const getTextColor = ({ $isNegative, $isZero, theme }: WithTheme<RootProps>) => {
  if ($isNegative) {
    return theme.color.redText;
  }
  if ($isZero) {
    return theme.color.textLight;
  }
  return theme.color.greenText;
};

const getBackgroundColor = ({ $isNegative, $isZero, theme }: WithTheme<RootProps>) => {
  if ($isNegative) {
    return theme.color.redBackground;
  }
  if ($isZero) {
    return theme.color.grayBackground;
  }
  return theme.color.greenBackground;
};

const Root = styled(FlexContainer).attrs({ column: true })<RootProps>`
  background-color: ${getBackgroundColor};
  color: ${getTextColor};
  border-radius: ${radius.medium};
  padding: ${spacing.gutter};
  text-align: center;
  font-weight: ${fontWeight.medium};
  gap: ${spacing.xxxsmall};
`;

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 14 })`
  display: inline-block;
  margin-left: ${spacing.xxsmall};
  cursor: default;
`;

type Props = {
  data: SummaryDisplayBudgetType[];
  isLoading: boolean;
};

const PlanSummaryWidgetHeader = ({ data, isLoading }: Props) => {
  const amount = useMemo(() => {
    const excessGroup = data.find(({ type }) => type === EXCESS_BUDGET_TYPE);
    return formatBudgetAmount(excessGroup?.budgeted || 0);
  }, [data]);

  return (
    <TooltipV2
      content={<AmountsTooltip title="Budgeted" rows={getRowsForAmountsTooltip(data)} />}
      maxWidth={TOOLTIP_WIDTH_PX}
      delayShow={TOOLTIP_SHOW_DELAY_MS}
      side="left"
    >
      <Root $isNegative={(amount || 0) < 0} $isZero={amount === 0}>
        <Text size="xxlarge">{isLoading ? '-' : <AnimatedCurrency value={amount} />}</Text>
        <Text>
          Left to budget <InfoIcon />
        </Text>
      </Root>
    </TooltipV2>
  );
};

const getRowsForAmountsTooltip = (data: SummaryDisplayBudgetType[]): AmountsTooltipRowProps[] =>
  data.map(({ name, budgeted, type }) => {
    if (type === EXCESS_BUDGET_TYPE) {
      return {
        title: 'Left to budget',
        value: budgeted || 0,
        type: 'income',
        emphasis: true,
      };
    }

    return { title: name || '', value: budgeted || 0 };
  });

export default PlanSummaryWidgetHeader;
