import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';

import { groupByType, isAccountDataGrouped } from 'common/lib/accounts/GroupingLogic';
import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';
import type { AccountFilters, Web_GetFilteredAccountsQuery } from 'common/generated/graphql';
import type { ConditionalReturnType, ElementOf } from 'common/types/utility';
import type { OptionType } from 'types/select';

const AccountLogoSmall = styled(AccountLogo).attrs({ size: 16 })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

export type AccountSelectOption = OptionType & {
  accountData?: ElementOf<Web_GetFilteredAccountsQuery, 'accounts'>;
};

type GroupedAccountOption = {
  label: string;
  options: AccountSelectOption[];
};

type Params<ShouldGroupT> = {
  shouldGroupByType: ShouldGroupT;
  queryFilters: Partial<AccountFilters>;
  excludeAccountId: string;
  includeSyncDisabled: boolean;
  shouldIncludeAccount?: (account: ElementOf<Web_GetFilteredAccountsQuery, 'accounts'>) => boolean;
};

const useAccountSelectOptions = <ShouldGroupT extends boolean | undefined = true>(
  params?: Partial<Params<ShouldGroupT>>,
) => {
  const {
    queryFilters,
    shouldGroupByType = true,
    excludeAccountId,
    includeSyncDisabled = false,
    shouldIncludeAccount = R.T,
  } = params ?? {};

  const {
    data: accountsData,
    isLoadingInitialData,
    ...restOfQuery
  } = useQuery(GET_FILTERED_ACCOUNTS_QUERY, {
    variables: {
      filters: {
        accountType: queryFilters?.accountType ?? undefined,
        excludeAccountTypes: queryFilters?.excludeAccountTypes ?? undefined,
        groups: queryFilters?.groups ?? undefined,
        includeManual: queryFilters?.includeManual ?? undefined,
        ids: queryFilters?.ids ?? undefined,
        ignoreHiddenFromNetWorth: queryFilters?.ignoreHiddenFromNetWorth ?? undefined,
        includeDeleted: queryFilters?.includeDeleted ?? undefined,
        includeHidden: queryFilters?.includeHidden ?? undefined,
        ignoreMappedToLiability: queryFilters?.ignoreMappedToLiability ?? undefined,
        ...queryFilters,
      },
    },
  });
  const { accounts = [] } = accountsData ?? {};

  const filteredAccounts = useMemo(
    () =>
      accounts.filter((account) => {
        const isNotExcluded = account.id !== excludeAccountId;
        const isSyncEnabled = includeSyncDisabled || !account.syncDisabled;
        return isNotExcluded && isSyncEnabled && shouldIncludeAccount(account);
      }),
    [accounts, excludeAccountId, includeSyncDisabled, shouldIncludeAccount],
  );

  const parsedAccounts = useMemo(
    () => (shouldGroupByType ? groupByType(filteredAccounts) : filteredAccounts),
    [filteredAccounts, shouldGroupByType],
  );

  const options = useMemo(
    () =>
      isAccountDataGrouped(parsedAccounts)
        ? parsedAccounts.map<GroupedAccountOption>(([label, accountsForGroup]) => ({
            label,
            options: accountsForGroup.map((account) => ({
              value: account.id,
              label: account.displayName,
              icon: <AccountLogoSmall logoUrl={account.logoUrl} icon={account.icon} />,
              accountData: account,
            })),
          }))
        : parsedAccounts.map<AccountSelectOption>((account) => ({
            value: account.id,
            label: account.displayName,
            icon: <AccountLogoSmall logoUrl={account.logoUrl} icon={account.icon} />,
            accountData: account,
          })),
    [parsedAccounts],
  );

  return [
    isLoadingInitialData,
    options as ConditionalReturnType<GroupedAccountOption[], AccountSelectOption[], ShouldGroupT>,
    restOfQuery,
  ] as const;
};

const GET_FILTERED_ACCOUNTS_QUERY = gql(/* GraphQL */ `
  query Web_GetFilteredAccounts($filters: AccountFilters) {
    accounts(filters: $filters) {
      id
      createdAt
      displayName
      displayBalance
      displayLastUpdatedAt
      dataProvider
      icon
      logoUrl
      order
      isAsset
      includeBalanceInNetWorth
      deactivatedAt
      manualInvestmentsTrackingMethod
      isManual
      syncDisabled
      type {
        display
        name
      }
      credential {
        updateRequired
      }
      institution {
        status
        newConnectionsDisabled
      }
    }
  }
`);

export default useAccountSelectOptions;
