import type { DateTime } from 'luxon';
import * as R from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import { DynamicPageTitle } from 'components/lib/ui/Page';
import TextButton from 'components/lib/ui/TextButton';
import RecurringActions from 'components/recurring/RecurringActions';
import RecurringCalendar from 'components/recurring/calendar/RecurringCalendar';
import RecurringEmptyPeriod from 'components/recurring/list/RecurringEmptyPeriod';
import RecurringListLoading from 'components/recurring/list/RecurringListLoading';
import RecurringUpcomingList from 'components/recurring/list/RecurringUpcomingList';

import { toggleCollapsedRecurringGroup } from 'actions';
import { GET_AGGREGATED_RECURRING_ITEMS } from 'common/lib/graphQl/recurring';
import { sortGroupsByStatus } from 'common/lib/recurring';
import { getRecurringFilters } from 'common/lib/recurring/filters';
import type { RecurringStreamToReview } from 'common/lib/recurring/types';
import { color, radius, spacing } from 'common/lib/theme/dynamic';
import { capitalize } from 'common/utils/String';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';
import type { RecurringFilters } from 'state/recurring/reducer';
import { getCollapsedGroupsMap } from 'state/recurring/selectors';

const Root = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.gutter};
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
  padding-top: 0;
`;

const StreamsToReviewBanner = styled(Flex).attrs({ row: true, justifyBetween: true })`
  padding: ${spacing.xsmall} ${spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
  background: ${color.orange};
  color: ${color.textWhite};
  border-radius: ${radius.small};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const ReviewButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.textWhite};
  text-decoration: underline;

  :hover {
    color: ${({ theme }) => theme.color.textWhite};
    background-color: transparent;
  }
`;

export enum RecurringView {
  Calendar = 'calendar',
  List = 'list',
}

type Props = {
  streamsToReview: RecurringStreamToReview[];
  currentStartDate: DateTime;
  endDate: DateTime;
  filters: RecurringFilters;
  setStartDate: (date: string) => void;
  onClickToReviewStreams?: () => void;
  openAddRecurringModal: () => void;
  openAddManualRecurringModal: () => void;
  onClickAddBills: () => void;
};

const RecurringUpcoming = ({
  currentStartDate,
  endDate,
  filters,
  setStartDate,
  streamsToReview,
  onClickToReviewStreams,
  openAddRecurringModal,
  openAddManualRecurringModal,
  onClickAddBills,
}: Props) => {
  const dispatch = useDispatch();
  const collapsedGroupsMap = useSelector(getCollapsedGroupsMap);
  const toggleCollapsed = useCallback((key: string) => {
    dispatch(toggleCollapsedRecurringGroup(key));
  }, []);

  const [recurringView, setRecurringView] = useState<RecurringView>(RecurringView.List);

  const shouldShowCalendarView = recurringView === RecurringView.Calendar;
  const shouldShowListView = recurringView === RecurringView.List;

  const needsReviewCount = streamsToReview.length;

  const {
    data: aggregatedRecurringItemsData,
    isNetworkRequestInFlight: isLoadingAggregated,
    refetch: refetchAggregated,
  } = useMockDataWhenNoAccountsQuery(GET_AGGREGATED_RECURRING_ITEMS, {
    variables: {
      startDate: currentStartDate.toISODate(),
      endDate: endDate.toISODate(),
      filters: getRecurringFilters(filters),
    },
  });

  const shouldShowEmptyState = useMemo(
    () =>
      !isLoadingAggregated &&
      R.isEmpty(aggregatedRecurringItemsData?.aggregatedRecurringItems?.groups),
    [isLoadingAggregated, aggregatedRecurringItemsData],
  );

  const { groups, aggregatedSummary } =
    aggregatedRecurringItemsData?.aggregatedRecurringItems ?? {};

  const sortedGroups = useMemo(() => sortGroupsByStatus(groups ?? []), [groups]);

  const calendarItems = useMemo(
    () => R.flatten(groups?.map((group) => group.results) ?? []),
    [groups],
  );

  const refetchAll = useCallback(() => {
    refetchAggregated();
  }, [refetchAggregated]);

  return (
    <>
      <DynamicPageTitle>Monthly</DynamicPageTitle>
      <Root>
        {needsReviewCount > 0 ? (
          <StreamsToReviewBanner>
            <Switch>
              <Case when={needsReviewCount > 1}>
                There are {needsReviewCount} new recurring merchants and accounts for you to review
              </Case>

              <Case when={needsReviewCount === 1}>
                There is 1 new recurring{' '}
                {streamsToReview[0]?.stream?.merchant ? 'merchant' : 'account'} for you to review
              </Case>
            </Switch>

            <ReviewButton onClick={onClickToReviewStreams}>Review now</ReviewButton>
          </StreamsToReviewBanner>
        ) : null}
        <RecurringActions
          startDate={currentStartDate}
          setStartDate={setStartDate}
          recurringView={recurringView}
          setRecurringView={setRecurringView}
          aggregatedSummary={aggregatedSummary}
          isLoadingSummary={isLoadingAggregated}
          openAddManualRecurringModal={openAddManualRecurringModal}
          onClickAddBills={onClickAddBills}
        />

        <Switch>
          <Case when={shouldShowListView}>
            {isLoadingAggregated && <RecurringListLoading />}

            {!isLoadingAggregated
              ? sortedGroups.map((group, index) => (
                  <RecurringUpcomingList
                    key={index}
                    title={capitalize(group.groupBy?.status ?? '')}
                    items={group.results}
                    startDate={currentStartDate}
                    refetch={refetchAll}
                    isLoading={false}
                    summary={group.summary}
                    isCollapsed={collapsedGroupsMap?.[group.groupBy?.status ?? '']}
                    toggleCollapsed={() => toggleCollapsed(group.groupBy?.status ?? '')}
                  />
                ))
              : null}
          </Case>

          <Case when={shouldShowCalendarView}>
            <RecurringCalendar
              items={calendarItems}
              startDate={currentStartDate}
              setStartDate={setStartDate}
              refetch={refetchAll}
            />
          </Case>
        </Switch>

        {shouldShowEmptyState && <RecurringEmptyPeriod onClickPrimary={openAddRecurringModal} />}
      </Root>
    </>
  );
};

export default RecurringUpcoming;
