import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import ManualAccountLogo from 'components/accounts/ManualAccountLogo';
import Mark from 'components/brand/Mark';
import InstitutionLogo from 'components/institutions/InstitutionLogo';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import RadioGroupField from 'components/lib/form/RadioGroupField';
import TextField from 'components/lib/form/TextField';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import { Root as CardHeaderRoot } from 'components/lib/ui/CardHeader';
import Divider from 'components/lib/ui/Divider';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import InformationList, { InformationItem } from 'components/lib/ui/InformationList';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { track } from 'lib/analytics/segment';
import useTheme from 'lib/hooks/useTheme';

import { CredentialDeleteOrDisableEventNames } from 'common/constants/analytics';

import type { Web_GetInstitutionSettingsQuery } from 'common/generated/graphql';

const Title = styled(Text).attrs({ weight: 'medium', color: 'text' })`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const HeaderLessCardBody = styled(CardBody)`
  padding-top: 0;
  gap: 4px;
`;

const LogoBackground = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.color.orange};
  width: 48px;
  height: 48px;

  border-radius: ${({ theme }) => theme.radius.medium};
`;

const Dash = styled.div`
  width: 10px;
  height: 0px;

  border: 1px solid ${({ theme }) => theme.color.redLight};
  border-radius: ${({ theme }) => theme.radius.small};
`;

const LogoHeader = styled(FlexContainer)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const DisconnectIcon = styled(Icon).attrs({ name: 'x-circle', size: 22 })`
  color: ${({ theme }) => theme.color.red11};
`;

const CustomSpacingInformationList = styled(InformationList)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const CustomRadioGroupField = styled(RadioGroupField)`
  && label {
    font-weight: ${({ theme }) => theme.fontWeight.book};
    font-size: ${({ theme }) => theme.fontSize.base};
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
  ${Text} {
    font-size: ${({ theme }) => theme.fontSize.base};
  }
  &:not(:last-child) {
    margin-bottom: 0;
  }
`;

const ModalCardWithNoPaddingOnHeader = styled(ModalCard)`
  ${CardHeaderRoot} {
    padding-bottom: 0;
  }
`;

const DividerWithMargin = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.medium};
  && div {
    background-color: ${({ theme }) => theme.color.grayBackground};
  }
`;

const CustomLabelTextField = styled(TextField)`
  && label {
    font-size: ${({ theme }) => theme.fontSize.base};
  }
`;

export type DeleteOrDisableFormValues = {
  deleteOrDisableChoice: 'disable' | 'delete' | null;
  institutionNameConfirmDeletion: string;
};

type Props = {
  credential: Web_GetInstitutionSettingsQuery['credentials'][0];
  disabled: boolean;
  onCancel: () => void;
};

const RADIO_OPTION_DISABLE = {
  value: 'disable',
  label: 'Connection only',
  description:
    'This will preserve the accounts, balances, and transactions history, but they will stop syncing and updating',
};

const RADIO_OPTION_DELETE = {
  value: 'delete',
  label: 'Connection, accounts, and transactions',
  description:
    'This will delete the connection and all accounts, balances, and transactions linked to this connection',
};

const RADIO_OPTIONS = [RADIO_OPTION_DISABLE, RADIO_OPTION_DELETE];

const DeleteOrDisableCredentialConfirmationInnerForm = ({
  credential,
  disabled,
  onCancel,
}: Props) => {
  const theme = useTheme();

  const { values, isValid, setFieldValue } = useFormContext<DeleteOrDisableFormValues>();

  useEffect(() => {
    if (credential.syncDisabledAt) {
      setFieldValue('deleteOrDisableChoice', 'delete');
    }
  }, [credential.syncDisabledAt]);

  useEffect(() => {
    track(CredentialDeleteOrDisableEventNames.DeleteOrDisableCredentialConfirmationOpened, {
      credentialId: credential?.id,
    });
  }, [credential?.id]);

  const confirmDeleteButtonDisabled =
    disabled ||
    !isValid ||
    !values.deleteOrDisableChoice ||
    values.institutionNameConfirmDeletion?.toLowerCase() !==
      credential.institution.name?.toLowerCase();

  return (
    <ModalCardWithNoPaddingOnHeader hideBottomBorder>
      <HeaderLessCardBody>
        <LogoHeader justifyCenter alignCenter gap="small">
          <LogoBackground justifyCenter alignCenter>
            <Mark size={30} color={theme.color.textWhite} />
          </LogoBackground>
          <Dash />
          <DisconnectIcon />
          <Dash />
          {credential.institution.logo ? (
            <InstitutionLogo logo={credential.institution.logo} />
          ) : (
            <ManualAccountLogo icon="dollar-sign" />
          )}
        </LogoHeader>
        <Title>Delete connection to {credential.institution.name}</Title>

        {credential.syncDisabledAt ? (
          <>
            <Text color="text" size="base">
              This action is final and cannot be undone.
            </Text>
            <Text color="text" size="base">
              Delete all accounts, balances, and transactions linked to this connection.
            </Text>
          </>
        ) : (
          <CustomRadioGroupField
            name="deleteOrDisableChoice"
            label="This action is final and cannot be undone. Choose specifically what you’d like to delete:"
            options={RADIO_OPTIONS}
          />
        )}

        <DividerWithMargin text="" />

        {values.deleteOrDisableChoice === 'disable' && (
          <CustomSpacingInformationList title="What will happen:">
            <InformationItem iconName="x-circle">
              Accounts will stop syncing and updating
            </InformationItem>
            <InformationItem iconName="check-circle">
              Accounts still show in the list / net worth
            </InformationItem>
            <InformationItem iconName="check-circle">
              Balances and transactions remain
            </InformationItem>
          </CustomSpacingInformationList>
        )}
        {values.deleteOrDisableChoice === 'delete' && (
          <CustomSpacingInformationList title="What will happen:">
            <InformationItem iconName="x-circle">
              Accounts won’t show in list or net worth
            </InformationItem>
            <InformationItem iconName="x-circle">All transactions will be deleted</InformationItem>
            <InformationItem iconName="x-circle">All balances will be deleted</InformationItem>
          </CustomSpacingInformationList>
        )}

        <CustomLabelTextField
          name="institutionNameConfirmDeletion"
          label={`Type "${credential.institution.name}" to confirm deletion`}
          required
        />
      </HeaderLessCardBody>
      <CardFooter>
        <DefaultButton onClick={onCancel} disabled={disabled}>
          Cancel
        </DefaultButton>
        <FormSubmitButton size="small" disabled={confirmDeleteButtonDisabled}>
          Delete permanently
        </FormSubmitButton>
      </CardFooter>
    </ModalCardWithNoPaddingOnHeader>
  );
};

export default DeleteOrDisableCredentialConfirmationInnerForm;
