import type { FormikErrors } from 'formik';
import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';

import CategorySelect from 'components/categories/FullHeightCategorySelect';
import GoalSelect from 'components/goalsV2/GoalSelect';
import CurrencyField from 'components/lib/form/CurrencyField';
import NumericField from 'components/lib/form/NumericField';
import type { SelectConformance } from 'components/lib/form/SelectField';
import SelectField from 'components/lib/form/SelectField';
import ToggleField from 'components/lib/form/ToggleField';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Column from 'components/lib/ui/Column';
import Drawer from 'components/lib/ui/Drawer';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Row from 'components/lib/ui/Row';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';
import MerchantSelect from 'components/merchants/FullHeightMerchantSelect';
import ReviewStatusSelect from 'components/transactions/review/ReviewStatusSelect';
import TransactionTagSelect from 'components/transactions/tags/TransactionTagSelect';

import { color, radius, spacing } from 'common/lib/theme/dynamic';
import { fieldStyleHideUntilHoverMixin } from 'lib/styles/fieldStyleMixin';

import type { OptionType } from 'types/select';
import type { Split } from 'types/splits';

const COLUMN_GUTTER_PX = 49;
const CURRENCY_INPUT_MASK_OPTIONS = { allowDecimal: true, allowNegative: true };
const PERCENTAGE_INPUT_MASK_OPTIONS = { suffix: '%', allowDecimal: false };

const RightAmountFieldCss = css<{ hideUntilHover: boolean }>`
  input {
    text-align: right;
    font-variant-numeric: normal;
    height: 38px;
    padding: ${spacing.xsmall} ${spacing.small};
    ${fieldStyleHideUntilHoverMixin}
  }
`;

const StyledCurrencyField = styled(CurrencyField)<{ hideUntilHover: boolean }>`
  ${RightAmountFieldCss}
`;

const StyledPercentageField = styled(NumericField)<{ hideUntilHover: boolean }>`
  ${RightAmountFieldCss}
`;

const SplitContainer = styled(FlexContainer).attrs({ column: true })`
  border-radius: ${radius.medium};
  background-color: ${color.grayBackground};
`;

const SplitFieldColumn = styled(Column)`
  margin: 0 0 auto;
  input,
  button {
    &:hover,
    &:focus {
      background-color: ${color.white};
    }
  }
`;

const SplitHeaderContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  padding: 'xsmall',
  gap: 'xsmall',
})<{
  $open: boolean;
}>`
  border-bottom: ${({ $open }) => ($open ? 1 : 0)}px solid ${color.grayFocus};
`;

const SplitDrilldownContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  gap: 'xsmall',
})<{ largeColumnGutter: boolean }>`
  padding: ${spacing.small}
    ${({ theme, largeColumnGutter }) =>
      largeColumnGutter ? `${COLUMN_GUTTER_PX}px` : theme.spacing.default}
    ${spacing.default};
`;

const ToggleColumn = styled(Column)`
  margin: auto 0 0;
`;

const StyledToggleField = styled(ToggleField)`
  :not(:last-child) {
    margin-bottom: 0;
  }
  margin-right: ${spacing.default};
`;

type Props<T extends Split> = {
  className?: string;
  expanded: boolean;
  compact?: boolean;
  absoluteAmount: boolean;
  formFieldPrefix: string;
  transactionId?: string;
  accountIds?: string[];
  split: T;
  onAdjustSplit: (data: T) => Promise<void | FormikErrors<any>>;
  onRemoveSplit: () => void;
  onToggleSplitExpanded: () => void;
  shouldAnimateDrawerOnMount?: boolean;
};

const FormSplitContainer = <T extends Split>({
  className,
  expanded = false,
  compact = false,
  absoluteAmount = true,
  formFieldPrefix,
  transactionId,
  accountIds,
  split,
  onAdjustSplit,
  onRemoveSplit,
  onToggleSplitExpanded,
  shouldAnimateDrawerOnMount,
}: Props<T>) => (
  <SplitContainer className={className}>
    <SplitHeaderContainer $open={expanded}>
      <IconButton
        icon={expanded ? 'chevron-down' : 'chevron-right'}
        onClick={onToggleSplitExpanded}
      />
      <Row padding={0} columnGutterPx={compact ? 8 : 16}>
        <SplitFieldColumn xs={4} {...sensitiveClassProps}>
          <MerchantSelect
            name={`${formFieldPrefix}.merchantName`}
            hideLabel
            fullWidthTrigger
            menuPlacement="auto"
            hideUntilHover
            alwaysShowBorder={false}
            transactionId={transactionId}
            transactionMerchantName={split.merchantName}
            placeholder="Select merchant..."
            value={split?.merchantName && { value: split.merchantName, label: split.merchantName }}
            onChange={async (selectedMerchantName: string) => {
              if (selectedMerchantName) {
                onAdjustSplit({ ...split, merchantName: selectedMerchantName });
              }
            }}
          />
        </SplitFieldColumn>
        <SplitFieldColumn xs={4}>
          <SelectField
            InputComponent={CategorySelect}
            name={`${formFieldPrefix}.categoryId`}
            hideLabel
            menuPlacement="auto"
            isCreatable
            placeholder="Select category..."
            alwaysShowBorder={false}
          />
        </SplitFieldColumn>
        <SplitFieldColumn xs={4}>
          {absoluteAmount ? (
            <StyledCurrencyField
              name={`${formFieldPrefix}.amount`}
              placeholder="$0.00"
              hideLabel
              hideUntilHover
              autoComplete="off"
              maskOptions={CURRENCY_INPUT_MASK_OPTIONS}
            />
          ) : (
            <StyledPercentageField
              name={`${formFieldPrefix}.amount`}
              placeholder="0%"
              hideLabel
              hideUntilHover
              autoComplete="off"
              maskOptions={PERCENTAGE_INPUT_MASK_OPTIONS}
              isPercent
            />
          )}
        </SplitFieldColumn>
      </Row>
      <IconButton onClick={onRemoveSplit} icon="trash" />
    </SplitHeaderContainer>
    <Drawer open={expanded} shouldAnimateOnMount={shouldAnimateDrawerOnMount}>
      <SplitDrilldownContainer largeColumnGutter={!compact}>
        <Row padding={0} columnGutterPx={16} rowGutterPx={12}>
          <SplitFieldColumn xs={compact ? 6 : 4}>
            <SelectField
              InputComponent={TransactionTagSelect}
              name={`${formFieldPrefix}.tags`}
              placeholder="Tags..."
              label="Tags"
              isCreatable
              isMulti
              menuPortalTarget={document.body}
            />
          </SplitFieldColumn>
          <SplitFieldColumn xs={compact ? 6 : 4}>
            <SelectField
              InputComponent={GoalSelect}
              name={`${formFieldPrefix}.goalId`}
              placeholder={compact ? 'Goal...' : 'Select goal...'}
              label="Link to goal"
              transactionId={transactionId}
              accountIds={accountIds}
              isDisabled={isNil(transactionId) && accountIds?.length !== 1}
              menuPortalTarget={document.body}
            />
          </SplitFieldColumn>
          <SplitFieldColumn xs={compact ? 6 : 4}>
            <SelectField
              InputComponent={(props: SelectConformance<OptionType>) => (
                <ReviewStatusSelect
                  {...props}
                  reviewStatusFieldName={`${formFieldPrefix}.reviewStatus`}
                  needsReviewByIdFieldName={`${formFieldPrefix}.needsReviewByUserId`}
                />
              )}
              placeholder={compact ? 'Status...' : 'Select status...'}
              label="Review status"
              name={`${formFieldPrefix}.reviewStatus`}
              menuPortalTarget={document.body}
            />
          </SplitFieldColumn>
          <ToggleColumn xs={compact ? 6 : 4}>
            <FlexContainer alignCenter marginTop="xsmall" marginBottom="xsmall">
              <StyledToggleField name={`${formFieldPrefix}.hideFromReports`} hideLabel />
              <Text size="small">Hide this split transaction</Text>
            </FlexContainer>
          </ToggleColumn>
        </Row>
      </SplitDrilldownContainer>
    </Drawer>
  </SplitContainer>
);

export default FormSplitContainer;
