import * as R from 'ramda';
import { isFunction } from 'ramda-adjunct';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ReferenceLineProps, TickFormatterFunction, XAxisProps } from 'recharts';
import {
  Bar,
  Cell,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  Rectangle,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import CartesianGrid from 'components/lib/charts/CartesianGrid';
import type { TooltipComponentProps } from 'components/lib/charts/ChartTooltip';
import { DEFAULT_STROKE_WIDTH_PX } from 'components/lib/charts/MultipleLineChart';
import type { Props as ReferenceLineDividerLabelProps } from 'components/lib/charts/ReferenceLineDividerLabel';
import ReferenceLineDividerLabel from 'components/lib/charts/ReferenceLineDividerLabel';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Dot from 'components/lib/ui/Dot';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';

import { getYAxisDomainBounds } from 'common/lib/charts';
import THEME from 'common/lib/theme/staticTheme';
import { useIsChartPreview } from 'lib/contexts/ChartContext';
import usePan from 'lib/hooks/usePan';
import useTheme from 'lib/hooks/useTheme';

import type { BarBackgroundProps } from 'types/Recharts';

const TICK_PADDING_PX = parseInt(THEME.spacing.xsmall, 10);
const MARGIN_TOP_PX = 52;
const DEFAULT_BAR_OPACITY = 1;
const DEFAULT_ACTIVE_BAR_OPACITY = 1;
const DEFAULT_NON_ACTIVE_BAR_OPACITY = 0.32;
const STACK_ID = 'STACK_ID';

const MIN_BAR_WIDTH_PX = 48;
const X_AXIS_TICK_LINE_HEIGHT = 18;
const X_AXIS_TICK_WIDTH_PX = 80;
const Y_AXIS_DOMAIN_MULTIPLIER = 1.05;
const Y_AXIS_IGNORE_OUTLIERS_COUNT = 0;
const Y_AXIS_MINIMUM_ORDER_OF_MAGNITUDE = 100;

export const ARROW_WIDTH_PX = 32;
const LINE_ANIMATION_DURATION_MS = 1800;
const HOVERED_BRIGHTNESS = 0.75;

const LeftRightScroll = styled(IconButton)<{ invisible: boolean }>`
  width: ${ARROW_WIDTH_PX}px;
  height: ${ARROW_WIDTH_PX}px;
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: 100%;
  opacity: ${({ invisible }) => (invisible ? 0 : 1)};
  cursor: ${({ invisible }) => (invisible ? 'inherit' : 'pointer')};
`;

const BarBackground = styled(Rectangle)<{ isActive: boolean }>`
  cursor: pointer;
  fill: transparent;
  stroke: transparent;
`;

const Root = styled(Flex)`
  .recharts-tooltip-wrapper {
    /** We can't adjust the index by just editing our tooltip */
    z-index: 1; /* stylelint-disable-line plugin/no-z-index */
  }
`;

const LegendContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.large};
  text-align: center;
  line-height: 1.8;

  > * {
    display: inline-flex;

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.large};
    }
  }
`;

export type XAxisReferenceLineProps = Pick<
  ReferenceLineDividerLabelProps,
  'leftLabelText' | 'rightLabelText'
> &
  Pick<ReferenceLineProps, 'x'>;

export type YAxisReferenceLineProps = Pick<ReferenceLineProps, 'y'> & {
  labels: string[];
  dashed?: boolean;
} & React.ComponentProps<typeof ReferenceLine>;

export type BarColorFunctionProps = {
  amount: number;
  index: number;
  hoveredBarIndex?: number;
  activeBarIndex?: number;
};

export type BarDataKeyOptions = {
  name: string;
  color: string | ((props: BarColorFunctionProps) => string);
};

type Props<
  // eslint-disable-next-line
  DataT extends object,
> = {
  activeBarIndex?: number;
  heightPx: number;
  widthPx: number;
  data: readonly DataT[];
  barDataKeys: BarDataKeyOptions[];
  totalDataKey?: string;
  dashedLineDataKey?: string;
  xAxisDataKey: keyof DataT;
  formatXAxis?: TickFormatterFunction;
  formatYAxis?: TickFormatterFunction;
  xAxisReferenceLines?: XAxisReferenceLineProps[];
  yAxisReferenceLines?: YAxisReferenceLineProps[];
  xAxisProps?: Partial<XAxisProps>;
  onBarAreaClick?: (data: DataT) => void;
  barWidthPx?: number;
  activeBarOpacity?: number;
  nonActiveBarOpacity?: number;
  /* Some consumers control the colors and opacity of the bars themselves via BarDataKeyOptions.color. */
  barColorInteractive?: boolean;
  tooltipComponent?: TooltipComponentProps<string, string, DataT>;
  /** Override default y axis. */
  yAxis?: React.ReactNode;
  hideGrid?: boolean;
  hideLines?: boolean;
  roundBarCorners?: boolean;
  minBarWidthPx?: number;
  /** Show label above each bar. */
  labelDataKey?: string;
  onAnimationEnd?: () => void;
  formatLabel?: (label: string | number) => React.ReactNode;
  withLegend?: boolean;
  /** Show bars side by side instead of stacked. */
  horizontalStack?: boolean;
  margin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
};

// eslint-disable-next-line
const StackedBarChart = <DataT extends object>({
  activeBarIndex,
  data: _data,
  formatXAxis,
  formatYAxis,
  heightPx,
  barDataKeys,
  totalDataKey,
  dashedLineDataKey,
  widthPx,
  xAxisDataKey,
  tooltipComponent,
  xAxisReferenceLines = [],
  xAxisProps,
  barWidthPx,
  onBarAreaClick,
  activeBarOpacity = DEFAULT_ACTIVE_BAR_OPACITY,
  nonActiveBarOpacity = DEFAULT_NON_ACTIVE_BAR_OPACITY,
  barColorInteractive = false,
  yAxis,
  yAxisReferenceLines,
  hideGrid,
  hideLines,
  roundBarCorners,
  minBarWidthPx = MIN_BAR_WIDTH_PX,
  labelDataKey,
  onAnimationEnd,
  margin = {},
  withLegend,
  horizontalStack,
  formatLabel,
}: Props<DataT>) => {
  const isPreview = useIsChartPreview();

  /**
   * If data or width change too quickly upon initial render, the rechart animation fails.
   * To fix this, we memoize data, and restart the animation any time width or height change
   * by changing the key props on ComposedChart.
   *
   * Once the animation has completed once, we don't want to retrigger it again.
   * See https://github.com/recharts/recharts/issues/1083
   */
  const nextAnimationKey = useMemo(() => `${widthPx}${heightPx}`, [widthPx, heightPx]);
  const [hoveredBarIndex, setHoveredBarIndex] = useState<number | undefined>(undefined);
  const [animationKey, setAnimationKey] = useState(nextAnimationKey);
  const [animationEnded, setAnimationEnd] = useState(false);
  const [lineAnimationEnded, setLineAnimationEnded] = useState(false);
  const dataString = JSON.stringify(_data);
  const data = useMemo(() => _data.map((data, index) => ({ ...data, index })), [dataString]);
  const theme = useTheme();

  const getBarOpacity = useCallback(
    (barIndex: number, activeBarIndex?: number, hoveredBarIndex?: number) => {
      if (activeBarIndex === undefined) {
        return DEFAULT_BAR_OPACITY;
      }
      if (barColorInteractive) {
        if (barIndex === activeBarIndex || barIndex === hoveredBarIndex) {
          return activeBarOpacity;
        }
        return nonActiveBarOpacity;
      }
      return DEFAULT_BAR_OPACITY;
    },
    [activeBarOpacity, nonActiveBarOpacity, barColorInteractive],
  );

  /**
   * Calculate numbers for panning behavior. We only start panning when our available space
   * is smaller than the area required to allow each bar to be at least MIN_BAR_WIDTH_PX
   *
   * Each pan click pans the viewport by 1/2 the total number of bars.
   */
  const { length: dataLength } = data;
  const maxNumberBars = Math.floor((widthPx - 2 * ARROW_WIDTH_PX) / (1.5 * minBarWidthPx));
  const panSizeIntervals = Math.floor(maxNumberBars / 2);
  const { panLeft, panRight, panCount, canPanLeft, canPanRight, setPanCount } = usePan(
    dataLength,
    maxNumberBars,
  );

  if (!animationEnded && animationKey !== nextAnimationKey) {
    setAnimationKey(nextAnimationKey);
  }

  const canPan = dataLength > maxNumberBars && !isPreview;

  useEffect(() => {
    // If we transition from panning -> not panning, we have to reset the pan count
    // or we could end up with a negative lowerDomainBound
    setPanCount(0);
  }, [canPan, setPanCount]);

  /* Domain bounds */
  const lowerDomainBoundXAxis =
    dataLength - Math.min(maxNumberBars, dataLength) - (canPan ? panCount : 0);
  const upperDomainBoundXAxis = dataLength - (canPan ? panCount : 0);

  const visibleData = useMemo(
    () => data.slice(lowerDomainBoundXAxis, upperDomainBoundXAxis),
    [data, lowerDomainBoundXAxis, upperDomainBoundXAxis],
  );

  const [lowerDomainBoundYAxis, upperDomainBoundYAxis] = useMemo(() => {
    // Calculate sorted values for each data key independently
    const visibleBarKeyStats = barDataKeys.map(({ name }) =>
      // @ts-expect-error Generic type doesn't work nicely with BarDataKeyOptions `name` accessor
      [...visibleData.map((dataPoint) => dataPoint[name])],
    );

    // If we're showing lines or are vertically stacking, we need to consider the various summations
    // of the bars to calculate the overall scale.
    const shouldConsiderCalculations = !hideLines || !horizontalStack;

    // Calculate stacked totals for each data point
    const visibleBarSignedTotals = visibleData.map((dataPoint) => {
      const positiveSum = barDataKeys.reduce((sum, { name }) => {
        // @ts-expect-error Generic type doesn't work nicely with BarDataKeyOptions `name` accessor
        const value = dataPoint[name] || 0;
        return sum + (value > 0 ? value : 0);
      }, 0);

      const negativeSum = barDataKeys.reduce((sum, { name }) => {
        // @ts-expect-error Generic type doesn't work nicely with BarDataKeyOptions `name` accessor
        const value = dataPoint[name] || 0;
        return sum + (value < 0 ? value : 0);
      }, 0);

      return { positive: positiveSum, negative: negativeSum };
    });

    // Get the positive and negative totals for each data point
    const visibleBarPositiveTotals = shouldConsiderCalculations
      ? [...visibleBarSignedTotals.map((dataPoint) => dataPoint.positive)]
      : [];
    const visibleBarNegativeTotals = shouldConsiderCalculations
      ? [...visibleBarSignedTotals.map((dataPoint) => dataPoint.negative)]
      : [];

    // Calculate totals for overall scale. This isn't always necessary though.
    const visibleBarTotals =
      totalDataKey && shouldConsiderCalculations
        ? // @ts-expect-error Generic type doesn't work nicely with accessor
          [...visibleData.map((dataPoint) => dataPoint[totalDataKey])]
        : [];

    // Calculate totals for overall scale. This isn't always necessary though.
    const visibleBarDashedLineTotals =
      dashedLineDataKey && shouldConsiderCalculations
        ? // @ts-expect-error Generic type doesn't work nicely with accessor
          [...visibleData.map((dataPoint) => dataPoint[dashedLineDataKey])]
        : [];

    return getYAxisDomainBounds(
      [
        ...R.flatten(visibleBarKeyStats),
        ...visibleBarPositiveTotals,
        ...visibleBarNegativeTotals,
        ...visibleBarTotals,
        ...visibleBarDashedLineTotals,
      ],
      Y_AXIS_DOMAIN_MULTIPLIER,
      Y_AXIS_IGNORE_OUTLIERS_COUNT,
      Y_AXIS_MINIMUM_ORDER_OF_MAGNITUDE,
    );
  }, [barDataKeys, hideLines, horizontalStack, totalDataKey, dashedLineDataKey, visibleData]);

  // Ensure that the 0 y-axis reference lines are always visible
  const allYAxisReferenceLines = [...(yAxisReferenceLines ?? []), { y: 0, labels: [] }];

  return (
    <Root alignCenter {...sensitiveClassProps}>
      {canPan && (
        <LeftRightScroll
          icon="arrow-left"
          invisible={!canPanLeft}
          onClick={() => panLeft(panSizeIntervals)}
        />
      )}
      <ComposedChart
        data={data}
        height={heightPx}
        width={widthPx - (canPan ? 2 * ARROW_WIDTH_PX : 0)}
        stackOffset="sign"
        margin={{ top: MARGIN_TOP_PX, bottom: 5, left: 0, right: canPan ? 30 : 0, ...margin }}
        key={animationKey}
        barCategoryGap="15%"
      >
        {!hideGrid && <CartesianGrid />}
        {tooltipComponent && !isPreview && (
          <Tooltip
            cursor={false}
            content={tooltipComponent}
            offset={parseInt(theme.spacing.xxlarge, 10)}
            useTranslate3d
          />
        )}
        <XAxis
          tick={{
            fontSize: theme.fontSize.xsmall,
            fill: theme.color.textLight,
            fontWeight: parseInt(theme.fontWeight.medium, 10),
            transform: `translate(0, ${TICK_PADDING_PX})`,
            lineHeight: X_AXIS_TICK_LINE_HEIGHT,
            width: X_AXIS_TICK_WIDTH_PX,
          }}
          ticks={R.range(lowerDomainBoundXAxis, upperDomainBoundXAxis)}
          stroke={theme.color.gray}
          dataKey="index"
          // @ts-ignore
          tickFormatter={(index) => formatXAxis?.(data[index]?.[xAxisDataKey]) ?? index}
          allowDataOverflow
          interval={0}
          // We need to check `dataLength` to set the type because (looks like) there is a bug on the recharts lib,
          // when we have only one data point and the type is `number` the bar doesn't render.
          // Check: https://github.com/recharts/recharts/issues/565
          // The correct type for this chart would be `number`, but to make it work for single data point
          // we are checking if the amount of data is equal to one and set the type to `category`.
          type={dataLength === 1 ? 'category' : 'number'}
          domain={[-0.5 + lowerDomainBoundXAxis, -0.5 + upperDomainBoundXAxis]}
          tickLine={false}
          axisLine={false}
          {...xAxisProps}
        />
        {yAxis !== undefined ? (
          yAxis
        ) : (
          <YAxis
            tick={{
              fontSize: theme.fontSize.xsmall,
              fill: theme.color.textLight,
              fontWeight: parseInt(theme.fontWeight.medium, 10),
              transform: `translate(${TICK_PADDING_PX * -1}, 0)`,
            }}
            stroke={theme.color.gray}
            tickFormatter={formatYAxis}
            tickLine={false}
            axisLine={false}
            allowDataOverflow
            domain={[lowerDomainBoundYAxis, upperDomainBoundYAxis]}
          />
        )}
        {xAxisReferenceLines.map(({ x, leftLabelText, rightLabelText }) => {
          // @ts-ignore
          const dataIndex = data.findIndex(({ [xAxisDataKey]: key }) => x === key);
          // don't show reference lines when they intersect with the y axis or end of the chart
          return !(dataIndex === lowerDomainBoundXAxis || dataIndex === upperDomainBoundXAxis) ? (
            <ReferenceLine
              key={x}
              x={dataIndex - 0.5}
              stroke={theme.color.grayFocus}
              label={(props) => (
                <ReferenceLineDividerLabel
                  {...props}
                  leftLabelText={leftLabelText}
                  rightLabelText={rightLabelText}
                  labelOffsetXPx={40}
                  labelOffsetYPx={MARGIN_TOP_PX / 2}
                />
              )}
              position="start"
            />
          ) : null;
        })}
        {allYAxisReferenceLines.map(({ y, labels, dashed, ...referenceLineProps }) => (
          <ReferenceLine
            key={y}
            y={y}
            stroke={theme.color.grayFocus}
            strokeDasharray={dashed ? '4 4' : undefined}
            position="start"
            {...referenceLineProps}
            label={(p) => {
              const { x, y } = p.viewBox;

              return (
                <g transform={`translate(${x},${y})`}>
                  <text
                    x={0}
                    y={0}
                    fill={theme.color.textLight}
                    fontWeight={theme.fontWeight.medium}
                    fontSize={theme.fontSize.small}
                  >
                    {labels.map((label, i) => (
                      <tspan key={i} x="0" y={`${i * 21}`} textAnchor="end">
                        {label}
                      </tspan>
                    ))}
                  </text>
                </g>
              );
            }}
          />
        ))}
        {barDataKeys.map((barDataKey, dataBarIndex) => (
          <Bar
            key={barDataKey.name}
            dataKey={barDataKey.name}
            stackId={horizontalStack ? barDataKey.name : STACK_ID}
            onClick={onBarAreaClick}
            onMouseEnter={(_, index) => setHoveredBarIndex(index)}
            onMouseLeave={() => setHoveredBarIndex(undefined)}
            isAnimationActive={!isPreview}
            onAnimationEnd={() => {
              setAnimationEnd(true);
              onAnimationEnd?.();
            }}
            cursor="pointer"
            background={({ width, height, x, y, index, ...datum }: BarBackgroundProps<DataT>) => (
              <BarBackground
                key={index}
                isActive={activeBarIndex === index && dataBarIndex === 0}
                width={2 * width}
                height={height}
                x={x - width / 2}
                y={y}
                // @ts-ignore Different subtype instantiation error TODO
                onClick={() => onBarAreaClick?.(datum)}
                onMouseEnter={() => setHoveredBarIndex(index)}
                onMouseLeave={() => setHoveredBarIndex(undefined)}
              />
            )}
            animationBegin={
              !horizontalStack && !animationEnded ? 400 * (dataBarIndex + 1) : undefined
            }
            animationDuration={horizontalStack || !animationEnded ? 400 : undefined}
            {...(barWidthPx ? { barSize: barWidthPx } : {})}
          >
            {data.map((value, index) => {
              // @ts-ignore
              const values = barDataKeys.map((key) => data[index][key.name]);
              // @ts-ignore
              const amount: number = value[barDataKey.name];
              const { color } = barDataKey;
              const barColor: string =
                typeof color === 'string'
                  ? color
                  : color({ amount, index, hoveredBarIndex, activeBarIndex });
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={barColor}
                  opacity={getBarOpacity(index, activeBarIndex, hoveredBarIndex)}
                  // @ts-ignore
                  radius={roundBarCorners ? [6, 6, 0, 0] : [0, 0, 0, 0]}
                  style={{
                    filter: `brightness(${barColorInteractive && hoveredBarIndex === index ? HOVERED_BRIGHTNESS : 1})`,
                  }}
                />
              );
            })}
            {
              // Right now the LabelList doesn't play well with panning
              !canPan &&
                !!labelDataKey &&
                !horizontalStack &&
                barDataKey.name === R.last(barDataKeys)?.name && (
                  <LabelList
                    dataKey={labelDataKey}
                    position="top"
                    // @ts-ignore this works but isn't typed for some reason
                    style={{
                      fill: theme.color.text,
                      fontSize: theme.fontSize.xsmall,
                      fontWeight: theme.fontWeight.medium,
                      pointerEvents: 'none',
                    }}
                    offset={parseInt(theme.spacing.small)}
                    formatter={formatLabel}
                  />
                )
            }
          </Bar>
        ))}

        {!hideLines && !!totalDataKey && (
          <Line
            stroke={theme.color.text}
            strokeWidth={DEFAULT_STROKE_WIDTH_PX}
            dataKey={totalDataKey}
            onAnimationEnd={() => setLineAnimationEnded(true)}
            animationDuration={LINE_ANIMATION_DURATION_MS}
            strokeLinecap="round"
            dot={false}
            isAnimationActive={!isPreview}
            activeDot={{ strokeWidth: 0, r: 6, style: { pointerEvents: 'none' } }}
            style={{ pointerEvents: 'none' }}
          />
        )}
        {!hideLines && !!dashedLineDataKey && (
          <Line
            stroke={theme.color.text}
            strokeWidth={DEFAULT_STROKE_WIDTH_PX}
            dataKey={dashedLineDataKey}
            strokeLinecap="round"
            strokeDasharray="4 10"
            animationBegin={!lineAnimationEnded ? LINE_ANIMATION_DURATION_MS : undefined}
            dot={false}
            isAnimationActive={!isPreview}
            style={{ pointerEvents: 'none' }}
          />
        )}
        {withLegend && (
          <Legend
            content={() => (
              <LegendContainer>
                {barDataKeys.map(({ name, color }) => (
                  <FlexContainer key={name} center gap="xsmall">
                    <Dot size={12} color={isFunction(color) ? theme.color.gray : color} />
                    <Text size="xsmall" color="textLight" weight="medium" clampLines={1}>
                      {name}
                    </Text>
                  </FlexContainer>
                ))}
              </LegendContainer>
            )}
          />
        )}
      </ComposedChart>
      {canPan && (
        <LeftRightScroll
          icon="arrow-right"
          invisible={!canPanRight}
          onClick={() => panRight(panSizeIntervals)}
        />
      )}
    </Root>
  );
};

export default StackedBarChart;
