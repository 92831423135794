import { gql } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import InvestmentsAllocationChart from 'components/investments/InvestmentsAllocationChart';
import InvestmentsAllocationTable from 'components/investments/InvestmentsAllocationTable';
import FlexContainer from 'components/lib/ui/FlexContainer';

import useQuery from 'common/lib/hooks/useQuery';
import { getCleanedAllocationData, sortAllocationData } from 'common/lib/investments/table';
import typewriter from 'lib/analytics/typewriter';

import type {
  Web_GetAllocation,
  Web_GetAllocationVariables,
} from 'common/generated/graphQlTypes/Web_GetAllocation';

const Root = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.gutter};
  padding-top: 0;
`;

type Props = {
  activeAccountIds: string[];
  onClickLinkAccount: () => void;
};

const InvestmentsAllocation = ({ activeAccountIds, onClickLinkAccount }: Props) => {
  useEffect(() => {
    typewriter.viewedInvestmentAllocationPage();
  }, []);
  const { data, isLoadingInitialData } = useQuery<Web_GetAllocation, Web_GetAllocationVariables>(
    GET_ALLOCATION_QUERY,
    {
      variables: {
        porfolioInput: { accountIds: activeAccountIds.length > 0 ? activeAccountIds : undefined },
      },
    },
  );
  const allocationData = getCleanedAllocationData(data);
  const sortedAllocationData = useMemo(() => sortAllocationData(allocationData), [allocationData]);
  const performanceData = data?.portfolio?.performance;

  return (
    <React.Fragment>
      <Helmet>
        <title>Monarch | Investment Types</title>
      </Helmet>
      <Root>
        <InvestmentsAllocationChart data={sortedAllocationData} isLoading={isLoadingInitialData} />
        <InvestmentsAllocationTable
          data={sortedAllocationData}
          meta={performanceData}
          isLoading={isLoadingInitialData}
          onClickLinkAccount={onClickLinkAccount}
        />
      </Root>
    </React.Fragment>
  );
};

const GET_ALLOCATION_QUERY = gql`
  query Web_GetAllocation($porfolioInput: PortfolioInput) {
    portfolio(input: $porfolioInput) {
      allocationSimple {
        type
        typeDisplay
        allocationPercent
        totalValue
      }

      performance {
        totalValue
      }
    }
  }
`;

export default InvestmentsAllocation;
