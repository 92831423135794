import React from 'react';

import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DangerButton from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { useFlowContext } from 'lib/contexts/FlowContext';

type Props = {
  reportName: string;
  onDelete: () => void;
  goBack: () => void;
};

const DeleteReportModal = ({ reportName, onDelete, goBack }: Props) => {
  const { skipToComplete } = useFlowContext();

  const handleDelete = () => {
    onDelete();
    skipToComplete();
  };

  return (
    <ModalCard title="Delete saved report">
      <CardBody>
        <Text>
          The saved report <strong>{reportName}</strong> will be removed from your saved list.
        </Text>
      </CardBody>
      <CardFooter>
        <DefaultButton onClick={goBack}>Cancel</DefaultButton>
        <DangerButton onClick={handleDelete}>Delete saved report</DangerButton>
      </CardFooter>
    </ModalCard>
  );
};

export default DeleteReportModal;
