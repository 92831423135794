import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import TaxLabel from 'components/lib/ui/TaxLabel';
import { textButtonMixin } from 'components/lib/ui/TextButton';
import RouteLink from 'components/lib/ui/link/RouteLink';
import BillingRow from 'components/settings/billing/BillingRow';
import SponsoredSubscriptionBanner from 'components/settings/billing/SponsoredSubscriptionBanner';

import { formatCurrency } from 'common/utils/Currency';

import routes from 'constants/routes';

import type { GetSubscriptionQuery } from 'common/generated/graphql';

const StyledTaxLabel = styled(TaxLabel)`
  display: block;
`;

const Flex = styled(FlexContainer)`
  flex: 1;
`;

const StyledLink = styled(RouteLink)`
  margin-left: ${({ theme }) => theme.spacing.default};
  text-align: right;
  ${textButtonMixin};
`;

type Props = {
  amount: number;
  date: string;
  monthlyPriceDollars: number;
  hasLifetimeSubscription: boolean;
  activeSponsorship: GetSubscriptionQuery['subscription']['activeSponsorship'];
  onRevokeSponsorAccess?: () => Promise<void> | void;
};

const NextPaymentRow = ({
  amount,
  date,
  monthlyPriceDollars,
  activeSponsorship,
  hasLifetimeSubscription,
  onRevokeSponsorAccess,
}: Props) => (
  <BillingRow title="Next Payment">
    <Flex column>
      <Flex alignCenter justifyBetween marginBottom="large">
        <div>
          {`${formatCurrency(amount)} on ${DateTime.fromISO(date).toLocaleString(
            DateTime.DATE_FULL,
          )}`}
          <StyledTaxLabel />
        </div>
        {R.isNil(activeSponsorship) && !hasLifetimeSubscription && (
          <StyledLink to={routes.settings.referrals()}>
            Get {formatCurrency(monthlyPriceDollars)} off your next payment
          </StyledLink>
        )}
      </Flex>
      {RA.isNotNil(activeSponsorship) && (
        <SponsoredSubscriptionBanner
          sponsorName={activeSponsorship?.sponsor?.name}
          sponsorProfilePictureUrl={activeSponsorship?.sponsor?.profilePictureUrl}
          onRevokeAccess={onRevokeSponsorAccess}
        />
      )}
    </Flex>
  </BillingRow>
);

export default NextPaymentRow;
