import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import Icon from 'components/lib/ui/Icon';
import MonarchLogo from 'components/lib/ui/MonarchLogo';
import type { Props as PageProps } from 'components/lib/ui/Page';
import Page from 'components/lib/ui/Page';
import PagedCarousel from 'components/lib/ui/PagedCarousel';

import { spacing } from 'common/lib/theme/dynamic';

import { externalUrls } from 'constants/routes';

const StyledPage = styled(Page)`
  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    min-height: 100vh;
  }
`;

const LargeCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.xxlarge} 0;
  width: 650px;
  margin: 70px 0;
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    width: 100%;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    padding-top: 0;
  }
`;

const LargeCardWithoutShadow = styled(LargeCard)`
  width: 90%;
  max-width: 650px;
  box-shadow: none;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    width: 100%;
    padding: 0 ${({ theme }) => theme.spacing.default};
    margin: auto;
    display: flex;
    justify-content: center;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    margin: auto;
    display: flex;
    justify-content: flex-start;
  }
`;

const Logo = styled(MonarchLogo)`
  width: 153px;
  margin-bottom: ${({ theme }) => theme.spacing.default};
  margin-top: ${({ theme }) => theme.spacing.default};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin-top: ${({ theme }) => theme.spacing.xxlarge};
  }
`;
const SplitPage = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    flex-direction: column-reverse;
  }
`;

const QuotesContainer = styled.div`
  background: ${({ theme }) => theme.color.gray1};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 700px;

  @media (max-width: ${({ theme }) => theme.breakPoints.xl}px) {
    max-width: 55%;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    max-width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    display: none;
  }
`;

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  flex: 1 40%;
  background: ${({ theme }) => theme.color.white};

  @media (max-width: ${({ theme }) => theme.breakPoints.xl}px) {
    flex: 1 45%;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    flex: 1 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    display: block;
  }
`;

const MobileQuotes = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    flex: 0 200px;
    display: block;
  }
`;

const Quote = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacing.xxxlarge} 0 0 ${({ theme }) => theme.spacing.xxxlarge};

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    padding: ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xlarge} 0;
    gap: ${({ theme }) => theme.spacing.default};

    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.color.grayBackground} 0%,
      ${({ theme }) => theme.color.white} 100%
    );
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.default} 0;
  }
`;

const QuoteBody = styled.div`
  max-width: 800px;
  padding-left: 25px;
`;

const QuoteStars = styled.div`
  color: ${({ theme }) => theme.color.yellow};
  text-align: left;
  line-height: 22px;
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const StarIcon = styled(Icon).attrs({ name: 'star-filled', size: 24 })`
  line-height: 100%;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    font-size: 20px;
  }
`;

const QuoteText = styled.p`
  position: relative;
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 135%;
  text-align: left;
  padding-right: ${({ theme }) => theme.spacing.xxxxlarge};
  margin: ${({ theme }) => theme.spacing.xlarge} 0 ${({ theme }) => theme.spacing.small};

  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    font-size: ${({ theme }) => theme.fontSize.xlarge};
    margin: ${({ theme }) => theme.spacing.small} 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.large};
    padding: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    font-size: ${({ theme }) => theme.fontSize.xsmall};
    padding: 0;
    margin: ${({ theme }) => theme.spacing.default} 0;
  }
`;

const QuoteAuthor = styled.p`
  text-align: left;
  color: ${({ theme }) => theme.color.black};
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.default};
  line-height: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.small};
    width: 100%;
    display: inline-block;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    font-size: ${({ theme }) => theme.fontSize.xsmall};
    margin: 0;
  }
`;

const QuoteContent = styled.div`
  padding: 0 ${spacing.default};
  padding-bottom: ${spacing.xlarge};
`;

const QuoteAuthorName = styled.span``;

const QuoteImage = styled.img`
  max-width: 800px;
  width: 100%;
  height: auto;
  align-self: flex-end;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    max-width: 28%;
    height: auto;
    align-self: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    max-width: 38%;
  }
`;

const Carousel = styled(PagedCarousel)`
  max-width: 100%;
  width: auto;
`;

type Props = PageProps & {
  children: React.ReactNode;
  header?: React.ReactNode;
  overrideLogo?: React.ReactNode;
};

const OnboardingCardPage = ({ children, header, overrideLogo, ...pageProps }: Props) => {
  const imageBaseUrl = `https://monarch-static-assets.s3.amazonaws.com/onboarding/quotes-experiment/v2`;

  const quotes = [
    {
      text: 'I tried YNAB, I tried Mint, I use Monarch. Soooo much more intuitive and the UI/UX is delightful.',
      author: 'Josh W',
      image: `${imageBaseUrl}/dashboard.png`,
      mobileImage:
        'https://monarch-static-assets.s3.amazonaws.com/onboarding/quotes-experiment/mobile/dashboard.png',
    },
    {
      text: 'Love it. Best personal finance app out there. Good bank connectivity, great reporting, active development and support.',
      author: 'Alex C',
      image: `${imageBaseUrl}/accounts.png`,
      mobileImage:
        'https://monarch-static-assets.s3.amazonaws.com/onboarding/quotes-experiment/mobile/accounts.png',
    },
    {
      text: "Feels like our finances are shared and that we're in this together even though all our accounts are actually separate.",
      author: 'Anu J',
      image: `${imageBaseUrl}/sankey-chart.png`,
    },
    {
      text: 'Bar none the greatest budgeting and cash flow management app I have EVER experienced. worth the money.',
      author: 'David D',
      image: `${imageBaseUrl}/cash-flow.png`,
      mobileImage:
        'https://monarch-static-assets.s3.amazonaws.com/onboarding/quotes-experiment/mobile/cash-flow.png',
    },
    {
      text: "One of the more comprehensive budgeting apps I've tried. Great for if you share expenses with a partner or are working with a financial advisor.",
      author: 'Kara J',
      image: `${imageBaseUrl}/goals.png`,
      mobileImage:
        'https://monarch-static-assets.s3.amazonaws.com/onboarding/quotes-experiment/mobile/goals.png',
    },
  ];

  const mobileQuotes = quotes.filter((quote) => quote.mobileImage);
  const desktopQuotes = quotes.filter((quote) => quote.image);

  return (
    <StyledPage showHeader={false} {...pageProps}>
      <SplitPage>
        <PageContainer>
          <MobileQuotes>
            <Carousel name="mobile" automated>
              {mobileQuotes.map((quote, i) => (
                <Quote key={i}>
                  <QuoteContent>
                    <QuoteStars>
                      <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon />
                    </QuoteStars>
                    <QuoteText>{quote.text}</QuoteText>
                    <QuoteAuthor>&mdash; {quote.author}</QuoteAuthor>
                  </QuoteContent>
                </Quote>
              ))}
            </Carousel>
          </MobileQuotes>
          <LargeCardWithoutShadow>
            {header}
            <a href={externalUrls.marketingSite} target="_blank" rel="noopener noreferrer">
              {overrideLogo ?? <Logo dark />}
            </a>
            {children}
          </LargeCardWithoutShadow>
        </PageContainer>
        <QuotesContainer>
          <Carousel name="desktop" dotsPosition="top" automated>
            {desktopQuotes.map((quote, i) => (
              <Quote key={i}>
                <QuoteBody>
                  <QuoteStars>
                    <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon />
                  </QuoteStars>
                  <QuoteText>{quote.text}</QuoteText>
                  <QuoteAuthor>
                    <QuoteAuthorName>&mdash; {quote.author}</QuoteAuthorName>
                  </QuoteAuthor>
                </QuoteBody>
                {quote.image && <QuoteImage src={quote.image} />}
              </Quote>
            ))}
          </Carousel>
        </QuotesContainer>
      </SplitPage>
    </StyledPage>
  );
};

export default OnboardingCardPage;
