import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import RelativeDate from 'components/lib/ui/RelativeDate';
import Text from 'components/lib/ui/Text';

import {
  evaluateInstitutionStatus,
  getInstitutionPlaidStatus,
  getStatusForDataProvider,
} from 'common/lib/accounts/Institution';
import { getDisplayName } from 'common/lib/dataProvider';
import { spacing } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';

import type { DataProviderLegacy, Institution } from 'common/generated/graphql';
import { InstitutionStatus } from 'common/types/institutions';

const StatusText = styled(Text)<{ $isHealthy: boolean }>`
  color: ${({ theme, $isHealthy }) => ($isHealthy ? theme.color.greenText : theme.color.redText)};
`;
const StyledAccountLogo = styled(AccountLogo)`
  margin-right: ${spacing.small};
`;
const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

const AccountSummary = ({
  icon,
  logoUrl,
  label,
  balance,
  createdAt,
  lastUpdatedAt,
  dataProvider,
  institution,
  syncDisabled,
}: {
  icon?: string | null;
  logoUrl?: string | null;
  label: React.ReactNode;
  balance?: number | null;
  createdAt?: string;
  lastUpdatedAt?: string;
  dataProvider?: string | null;
  institution?: Partial<Institution> | null;
  syncDisabled?: boolean;
}) => {
  const hasBalance = balance !== undefined && balance !== null;
  const hasCreatedAt = createdAt !== undefined;
  const hasLastUpdatedAt = lastUpdatedAt !== undefined;
  const isHealthy = getConnectionIsHealthy(
    institution ?? {},
    dataProvider ?? '',
    syncDisabled ?? false,
  );
  const providerName = getDisplayName(dataProvider as DataProviderLegacy);
  return (
    <>
      <StyledAccountLogo size={32} icon={icon} logoUrl={logoUrl} />
      <FlexContainer column full>
        <FlexContainer justifyBetween>
          <Text weight="medium">{label}</Text>
          {hasBalance && <Text weight="medium">{formatCurrency(balance)}</Text>}
        </FlexContainer>
        <FlexContainer justifyBetween>
          {hasCreatedAt && (
            <Text size="small">
              Created: <RelativeDate date={createdAt ?? ''} />
            </Text>
          )}
          {hasLastUpdatedAt && (
            <Text size="small">
              Last update: <RelativeDate date={lastUpdatedAt} />
            </Text>
          )}
        </FlexContainer>
        <FlexContainer>
          {dataProvider && (
            <StatusText size="small" $isHealthy={isHealthy}>
              <StyledIcon size={12} name={isHealthy ? 'check-circle' : 'alert-triangle'} />
              {isHealthy ? 'Connected through ' : 'Disconnected from '}
              {providerName}
            </StatusText>
          )}
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default AccountSummary;

const getConnectionIsHealthy = (
  institution: Partial<Institution>,
  dataProvider: string,
  syncDisabled: boolean,
) => {
  const newConnectionsDisabled = institution?.newConnectionsDisabled ?? false;
  const balanceStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'balanceStatus'),
    dataProvider,
    syncDisabled,
    newConnectionsDisabled,
  );
  const transactionsStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'transactionsStatus'),
    dataProvider,
    syncDisabled,
    newConnectionsDisabled,
  );
  const showTransactionSyncingStatus = !['zillow'].includes(dataProvider ?? '');
  const status = evaluateInstitutionStatus({
    transactionsStatus,
    balanceStatus,
    showTransactionSyncingStatus,
  });
  return status === InstitutionStatus.Healthy;
};
