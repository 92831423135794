import React from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import DateRangePickerShortcuts from 'components/lib/ui/DateRangePickerShortcuts';

import { DEFAULT_SHORTCUTS } from 'common/lib/dateRange/dateRangeWithTimeframes';

import type { DateRangeWithTimeframes } from 'common/types/DateRange';

const StyledDateRangePickerShortcuts = styled(DateRangePickerShortcuts)`
  border-right: none;
  height: auto;
  min-width: 0;
`;

const DateRangeWithTimeframesShortcuts = ({
  selectedShortcutId,
}: {
  selectedShortcutId: string;
}) => {
  const { setValues, submitForm } = useFormContext<DateRangeWithTimeframes>();

  return (
    <StyledDateRangePickerShortcuts
      shortcuts={DEFAULT_SHORTCUTS}
      selectedShortcutId={selectedShortcutId}
      onSelectDateRange={async (dateRange) => {
        await setValues(dateRange);
        await submitForm();
      }}
    />
  );
};

export default DateRangeWithTimeframesShortcuts;
