import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import ModalCard from 'components/lib/ui/ModalCard';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import RouteLink from 'components/lib/ui/link/RouteLink';

import { spacing } from 'common/lib/theme/dynamic';

import { PWNED_PASSWORDS } from 'common/constants/externalUrls';
import routes from 'constants/routes';

const Root = styled.div`
  margin: ${spacing.default};
`;

const PasswordNeedsReset = ({ username }: { username?: string }) => {
  const forgotPasswordRouteWithParams = routes.forgotPassword({ queryParams: { email: username } });

  return (
    <ModalCard title="Password Breach Detection">
      <Root>
        We&apos;ve detected that your current password may have been involved in an external data
        breach and is risky to use. To protect your financial information, please reset your
        password by <RouteLink to={forgotPasswordRouteWithParams}>clicking here</RouteLink>, then
        use the new password to log in.
        <p>
          {' '}
          For more information about risky passwords and password re-use, please{' '}
          <Link href={PWNED_PASSWORDS} target="_blank" rel="noopener noreferrer">
            click here
          </Link>
          .
        </p>
        <FlexContainer justifyEnd>
          <RouteLink to={forgotPasswordRouteWithParams}>
            <PrimaryButton>Continue</PrimaryButton>
          </RouteLink>
        </FlexContainer>
      </Root>
    </ModalCard>
  );
};

export default PasswordNeedsReset;
