import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FeatureEntitlementGate from 'common/components/premium/FeatureEntitlementGate';
import AccountsDashboardWidget from 'components/dashboard/AccountsDashboardWidget';
import AdviceDashboardWidget from 'components/dashboard/AdviceDashboardWidget';
import CashFlowDashboardWidget from 'components/dashboard/CashFlowDashboardWidget';
import CustomizeDashboardModal from 'components/dashboard/CustomizeDashboardModal';
import GoalsDashboardWidgetV2 from 'components/dashboard/GoalsDashboardWidgetV2';
import InvestmentsDashboardWidget from 'components/dashboard/InvestmentsDashboardWidget';
import OnboardingDashboardWidget from 'components/dashboard/OnboardingDashboardWidget';
import PlanDashboardWidget from 'components/dashboard/PlanDashboardWidget';
import RecurringTransactionsDashboardWidget from 'components/dashboard/RecurringTransactionsDashboardWidget';
import TransactionsDashboardWidget from 'components/dashboard/TransactionsDashboardWidget';
import DragDropContext from 'components/lib/dnd/DragDropContext';
import Draggable from 'components/lib/dnd/Draggable';
import Droppable from 'components/lib/dnd/Droppable';
import RouteModal from 'components/lib/routing/RouteModal';
import Column from 'components/lib/ui/Column';
import Controls from 'components/lib/ui/Controls';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import Row, { GRID_COLUMNS } from 'components/lib/ui/Row';
import SpendingInsightsBanner from 'components/lib/ui/SpendingInsightsBanner';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import WhatsNewModal from 'components/routes/WhatsNewModal';
import ContactSupportFlow from 'components/support/ContactSupportFlow';
import RequestRefundFlow from 'components/support/RequestRefundFlow';

import useDashboardConfig from 'common/lib/hooks/dashboard/useDashboardConfig';
import { DashboardWidgetName } from 'common/state/dashboard/types';
import ApolloClient from 'lib/graphQl/ApolloClient';
import useDashboardDrag from 'lib/hooks/dashboard/useDashboardDrag';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';
import { getUserFirstName } from 'selectors';

import * as COPY from 'common/constants/copy';
import { DASHBOARD_WIDGET_ASSOCIATED_FEATURE } from 'common/constants/premium';
import routes from 'constants/routes';

const WIDGET_COMPONENTS: { [name in DashboardWidgetName]: React.FunctionComponent } = {
  [DashboardWidgetName.PLAN]: PlanDashboardWidget,
  [DashboardWidgetName.NET_WORTH]: AccountsDashboardWidget,
  [DashboardWidgetName.GOALS]: GoalsDashboardWidgetV2,
  [DashboardWidgetName.CASH_FLOW]: CashFlowDashboardWidget,
  [DashboardWidgetName.TRANSACTIONS]: TransactionsDashboardWidget,
  [DashboardWidgetName.GETTING_STARTED]: OnboardingDashboardWidget,
  [DashboardWidgetName.REVIEW]: () => null, // Web doesn't have weekly review, but this is needed for TS
  [DashboardWidgetName.INVESTMENTS]: InvestmentsDashboardWidget,
  [DashboardWidgetName.RECURRING]: RecurringTransactionsDashboardWidget,
  [DashboardWidgetName.ADVICE]: AdviceDashboardWidget,
};

const DroppableColumn = styled(Droppable)`
  height: 100%;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.gutter};
  }
`;

const StyledRow = styled(Row)`
  padding-top: 0;
`;

const Dashboard = () => {
  const [dashboardConfig] = useDashboardConfig('web');
  const { onDragEnd } = useDashboardDrag();
  const { layout, widgets } = dashboardConfig;

  const numColumns = layout.length;

  const [CustomizeModal, { open: openCustomizeModal }] = useModal();

  const isAiSpendingInsightsOn = useIsFeatureFlagOn('ai-spending-insights');
  const isCreditCardFirstOn = useIsFeatureFlagOn('ab-test-credit-card-first', {
    trackImpression: true,
    sendToMsClarity: true,
  });

  const userFirstName = useSelector(getUserFirstName);

  const CREDIT_CARD_FIRST_EXPERIMENT = {
    emptyTitle: "Connect your credit card, and we'll automatically categorize your spending.",
    emptyButtonText: 'Connect your credit card(s)',
  };

  const DEFAULT_COPY = {
    emptyTitle: COPY.DASHBOARD_AI_INSIGHTS.OVERLAY_TITLE,
    emptyButtonText: COPY.DASHBOARD_AI_INSIGHTS.OVERLAY_BUTTON,
  };

  const { emptyTitle, emptyButtonText } = isCreditCardFirstOn
    ? CREDIT_CARD_FIRST_EXPERIMENT
    : DEFAULT_COPY;

  // TODO: time adapted greetings, i.e. good morning, etc.
  const greeting = userFirstName ? `Welcome back, ${userFirstName}!` : 'Welcome back!';
  const pageTitle = greeting;

  return (
    <PageWithNoAccountsEmptyState
      name="Dashboard"
      overrideHtmlTitle="Dashboard"
      overrideTitle={pageTitle}
      emptyIcon="home"
      emptyTitle={emptyTitle}
      emptyButtonText={emptyButtonText}
      onAccountAdded={() => ApolloClient.resetStore()}
      controls={
        <Controls>
          <DefaultButton onClick={openCustomizeModal} size="small">
            <ButtonIcon name="grid" />
            <span>Customize</span>
          </DefaultButton>
        </Controls>
      }
    >
      {isAiSpendingInsightsOn && <SpendingInsightsBanner />}

      <DragDropContext onDragEnd={onDragEnd}>
        <StyledRow rowGutterPx={0}>
          {layout.map((widgetNames, column) => (
            <Column key={column} md={GRID_COLUMNS / numColumns}>
              <DroppableColumn droppableId={`${column}`}>
                {widgetNames.map((widgetName, index) => {
                  const Component = WIDGET_COMPONENTS[widgetName];

                  const { enabled } = widgets[widgetName];

                  // Check if this widget is associated with a premium feature. FeatureEntitlementGate
                  // won't render it if the user doesn't have access to the feature.
                  const associatedProductFeature = DASHBOARD_WIDGET_ASSOCIATED_FEATURE[widgetName];

                  return (
                    <FeatureEntitlementGate key={widgetName} feature={associatedProductFeature}>
                      {enabled && Component && (
                        <Draggable draggableId={widgetName} index={index} customDragHandle>
                          <Component />
                        </Draggable>
                      )}
                    </FeatureEntitlementGate>
                  );
                })}
              </DroppableColumn>
            </Column>
          ))}
        </StyledRow>
      </DragDropContext>
      <RouteModal path={routes.contactSupport.path} exact>
        {({ close }) => <ContactSupportFlow onComplete={close} />}
      </RouteModal>
      <RouteModal path={routes.refundRequest.path} exact>
        {({ close }) => <RequestRefundFlow onComplete={close} />}
      </RouteModal>
      <RouteModal path={routes.whatsNew.path} exact>
        {({ close }) => <WhatsNewModal onClose={close} />}
      </RouteModal>
      <CustomizeModal large>
        <CustomizeDashboardModal />
      </CustomizeModal>
    </PageWithNoAccountsEmptyState>
  );
};

export default Dashboard;
