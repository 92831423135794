import React from 'react';

import type { Props as DateRangeWithTimeframesFormProps } from 'components/lib/dates/DateRangeWithTimeframesForm';
import DateRangeWithTimeframesForm from 'components/lib/dates/DateRangeWithTimeframesForm';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';

type Props = DateRangeWithTimeframesFormProps & { value: string };

const DateRangeWithTimeframesPopover = ({ value, ...props }: Props) => (
  <OverlayTrigger
    placement="bottom-end"
    overlay={({ toggleOpen }) => (
      <Popover>
        <DateRangeWithTimeframesForm toggleOpen={toggleOpen} {...props} />
      </Popover>
    )}
  >
    {({ toggleOpen, isOpen }) => (
      <DefaultButton onClick={toggleOpen} active={isOpen}>
        <ButtonIcon name="calendar" />
        <span>{value}</span>
      </DefaultButton>
    )}
  </OverlayTrigger>
);

export default DateRangeWithTimeframesPopover;
