import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import MonarchLogo from 'components/lib/ui/MonarchLogo';

import { color } from 'common/lib/theme/dynamic';
import useNavigationTracking from 'lib/hooks/useNavigationTracking';

import Flex from '../ui/Flex';

type CardSize = 'small' | 'medium' | 'large';
export type Props = {
  // Static name of the page, used for logging. Should not change
  name: string;
  // Dynamic title to override the page. Used just for display. Set null to show no title
  displayTitle?: string | null;
  description?: React.ReactNode;
  children: React.ReactNode;
  subtext?: React.ReactNode;
  controls?: React.ReactNode;
  header?: React.ReactNode;
  cardWidthPx?: number;
  className?: string;
  size?: CardSize;
};

const DEFAULT_CARD_WIDTH_PX = 400;
const LOGO_WIDTH = '153px';
const MARGIN_TOP = '80px';

const CARD_WIDTH_SIZES_PX: Record<CardSize, number> = {
  small: 320,
  medium: DEFAULT_CARD_WIDTH_PX,
  large: 650,
};

const Layout = styled(Flex)<{ $cardWidthPx: number }>`
  width: ${({ $cardWidthPx }) => `${$cardWidthPx}px`};
  max-width: 100%;
  align-items: center;
`;

const Root = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: ${color.grayBackground};
  padding: ${({ theme }) => theme.spacing.xsmall};
  align-items: center;
  padding-top: ${MARGIN_TOP};
  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    justify-content: center;
    padding-top: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const FormContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const SmallCard = styled(({ $cardWidthPx, ...props }) => <Card {...props} />)`
  width: 100%;
  max-width: ${({ $cardWidthPx }) => `${$cardWidthPx}px`};
  margin: auto;
  flex-shrink: 0;
`;

const SubText = styled.div<{ $cardWidthPx: number }>`
  padding: ${({ theme }) => theme.spacing.default};
  width: ${({ $cardWidthPx }) => `${$cardWidthPx}px`};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.color.textLight};
`;

const LogoContainer = styled.div`
  width: ${LOGO_WIDTH};
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const Logo = styled(MonarchLogo)`
  max-width: 100%;
`;

// A logged out page with a card in the middle, useful for forms like Login, ResetPassword, etc.
const SingleCardPage = ({
  name,
  displayTitle,
  description,
  children,
  subtext,
  className,
  header = null,
  controls,
  cardWidthPx,
  size = 'medium',
}: Props) => {
  const widthPx = cardWidthPx ?? CARD_WIDTH_SIZES_PX[size];
  const pageTitle = displayTitle ? `Monarch | ${displayTitle}` : 'Monarch';

  useNavigationTracking(name, pageTitle);

  return (
    <Root column>
      <Layout column $cardWidthPx={widthPx}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <LogoContainer>
          <Logo dark />
        </LogoContainer>
        {header}
        <SmallCard
          $cardWidthPx={widthPx}
          title={displayTitle}
          description={description}
          controls={controls}
        >
          {/* Override styles will set children container styles */}
          <FormContainer className={className}>{children}</FormContainer>
        </SmallCard>
        {!!subtext && <SubText $cardWidthPx={widthPx}>{subtext}</SubText>}
      </Layout>
    </Root>
  );
};

export default SingleCardPage;
