import { useMutation } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import InformationList, { InformationItem } from 'components/lib/ui/InformationList';
import Text from 'components/lib/ui/Text';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import { fontSize } from 'common/lib/theme/dynamic';
import variables from 'common/lib/theme/variables';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';

import { gql } from 'common/generated/gql';
import type {
  Web_TransferAccountDataMutation,
  Web_TransferAccountDataMutationVariables,
} from 'common/generated/graphql';

type Props = {
  fromAccountId: string;
  toAccountId: string;
  transferTransactions: boolean;
  startDateTransactions: string;
  endDateTransactions: string;
  fromAccountExpectedAffectedTransactionCount: number;
  toAccountExpectedAffectedTransactionCount: number;
  transferBalanceSnapshots: boolean;
  startDateBalanceSnapshots: string;
  endDateBalanceSnapshots: string;
  expectedAffectedBalanceSnapshotCount: number;
  toAccountNewerTransactionCount: number;
  next: (params: { fromAccountId: string; toAccountId: string }) => void;
  goBack?: () => void;
};

const StyledForm = styled(Form)<{
  className?: string;
}>`
  width: 100%;
` as typeof Form;

const DescriptionText = styled.div`
  color: ${variables.color.content.secondary};
  font-size: ${fontSize.small};
`;

const Review = ({ next, goBack, toAccountNewerTransactionCount, ...formProps }: Props) => {
  const [transferAccountData] = useMutation<
    Web_TransferAccountDataMutation,
    Web_TransferAccountDataMutationVariables
  >(TRANSFER_ACCOUNT_DATA_MUTATION);
  return (
    <StyledForm
      initialValues={{
        cleanupFromAccount: true,
        ...formProps,
      }}
      isInitialValid
      mutation={transferAccountData}
      onSubmitSuccess={(response) => {
        next({
          fromAccountId: formProps.fromAccountId,
          toAccountId: formProps.toAccountId,
        });
      }}
    >
      <TransferAccountDataStep
        overrideTitle="Review"
        progress={4}
        onClickBack={goBack}
        footer={
          <FormSubmitButton size="large" disableWhenValuesUnchanged={false} disabled={false}>
            Permanently transfer data
          </FormSubmitButton>
        }
      >
        <FlexContainer center column marginTop="xxlarge" gap="large">
          <Text>Review</Text>
          {formProps.transferTransactions && (
            <>
              <InformationList title="Transactions">
                <InformationItem iconName="trash">
                  <Text weight="medium">
                    Delete {formProps.toAccountExpectedAffectedTransactionCount} overlapping
                    transactions
                  </Text>
                  <DescriptionText>
                    Transactions on and before{' '}
                    {isoDateToAbbreviatedMonthDayAndYear(formProps.endDateTransactions)} will be
                    deleted on your new account
                  </DescriptionText>
                </InformationItem>
                <InformationItem iconName="merge">
                  <Text weight="medium">
                    Move {formProps.fromAccountExpectedAffectedTransactionCount} transactions
                  </Text>
                  <DescriptionText>
                    Transactions (including updated categories, notes, attachments, and splits) on
                    and before {isoDateToAbbreviatedMonthDayAndYear(formProps.endDateTransactions)}{' '}
                    will be moved from your old account to your new account
                  </DescriptionText>
                </InformationItem>
                <InformationItem iconName="check">
                  <Text weight="medium">
                    Keep {toAccountNewerTransactionCount} recent transactions
                  </Text>
                  <DescriptionText>
                    Transactions after{' '}
                    {isoDateToAbbreviatedMonthDayAndYear(formProps.endDateTransactions)} will remain
                    on your new account
                  </DescriptionText>
                </InformationItem>
              </InformationList>
            </>
          )}
          {formProps.transferBalanceSnapshots && (
            <>
              <InformationList title="Balances">
                <InformationItem iconName="trash">
                  <Text weight="medium">Delete overlapping balance history</Text>
                  <DescriptionText>
                    Balances on and before{' '}
                    {isoDateToAbbreviatedMonthDayAndYear(formProps.endDateBalanceSnapshots)} will be
                    deleted on your new account
                  </DescriptionText>
                </InformationItem>
                <InformationItem iconName="merge">
                  <Text weight="medium">Move balances</Text>
                  <DescriptionText>
                    Balances on and before{' '}
                    {isoDateToAbbreviatedMonthDayAndYear(formProps.endDateBalanceSnapshots)} will be
                    copied from your old account to your new account
                  </DescriptionText>
                </InformationItem>
                <InformationItem iconName="check">
                  <Text weight="medium">Keep recent balances</Text>
                  <DescriptionText>
                    Balances after{' '}
                    {isoDateToAbbreviatedMonthDayAndYear(formProps.endDateBalanceSnapshots)} will
                    remain on your new account
                  </DescriptionText>
                </InformationItem>
              </InformationList>
            </>
          )}
        </FlexContainer>
      </TransferAccountDataStep>
    </StyledForm>
  );
};

export default Review;

const TRANSFER_ACCOUNT_DATA_MUTATION = gql(/* GraphQL */ `
  mutation Web_TransferAccountData($input: TransferAccountDataMutationInput!) {
    transferAccountData(input: $input) {
      fromAccount {
        id
      }
      toAccount {
        id
      }
      numTransactionsAffected
      numBalanceSnapshotsAffected
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
