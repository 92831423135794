import { APIError, FieldValidationAPIError, DetailedAPIError } from 'common/errors';

import Api from 'common/lib/api';
import isDetailedAPIError from 'common/lib/errors/isDetailedAPIError';
import isFieldValidationAPIError from 'common/lib/errors/isFieldValidationAPIError';
import getApiUrl from 'lib/getApiUrl';
import { getDeviceUUID } from 'selectors';
import { getActAsUser } from 'state/multiHousehold/selectors';
import { store } from 'state/store';
import { getUserToken } from 'state/user/selectors';

import type { RawAPIError } from 'common/types';

import clientPlatform from './analytics/clientPlatform';

export default new Api({
  baseUrlProvider: getApiUrl,
  clientPlatform,
  onError: (error: RawAPIError) => {
    if (isFieldValidationAPIError(error)) {
      throw new FieldValidationAPIError(error);
    } else if (isDetailedAPIError(error)) {
      throw new DetailedAPIError(error);
    }
    throw new APIError(error);
  },
  userTokenProvider: () => {
    const state = store.getState();
    const token = getUserToken(state);
    return token;
  },
  actAsUserProvider: () => {
    const state = store.getState();
    return getActAsUser(state);
  },
  deviceUUIDProvider: () => getDeviceUUID(store.getState()),
});
